<template>
  <div class="EventBoxComponent">
    <v-container>
      <v-row>
        <v-col class="pa-0">
          <v-img
            :class="{ imageEvent: true, imageEventCompleted: event.state === 'completed' }"
            :src="event.banner"
            aspect-ratio="1"
            width="250px"
            @mouseenter="buy = true"
            @mouseleave="buy = false"
            content-class="insideBanner"
          >
            <transition name="fade">
              <v-btn
                :to="getLink(event.code)"
                elevation="0"
                v-show="false"
                color="customColor buttonBuy"
                dark
                class="py-5 no-invert"
                block
                small
                tile
                v-if="event.state !== 'completed'"
                >Canjear Tickets</v-btn
              >
                            <v-btn
                :to="getLink(event.code)"
                elevation="0"
                v-show="buy"
                color="green buttonBuy"
                dark
                class="py-5 no-invert"
                block
                small
                tile
                v-if="event.state === 'completed'"
                >Finalizado</v-btn
              >
            </transition>
          </v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container>
            <v-row>
              <v-col class="titleEvent">
                {{ event.name }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="subtitleEvent">
                <v-icon small>mdi-calendar</v-icon>
                {{ getStringDateFormat(event.date) }}
              </v-col>
            </v-row>
            <v-row dense class="overlineEvent">
              <v-col class="subtitleEvent">
                <v-icon small>mdi-ticket</v-icon> Desde
                {{ event.points }} Puntos
              </v-col>
            </v-row>
            <v-row dense class="overlineEvent">
              <v-col class="subtitleEvent">
                <v-icon small>mdi-map</v-icon> {{ event.place }}
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_EventBoxComponent.scss'
import moment from 'moment/moment'
export default {
  name: 'EventBoxComponent',
  props: {
    event: Object
  },
  data () {
    return {
      buy: true
    }
  },
  mounted () {
    console.log(this.event)
  },
  methods: {
    getStringDateFormat (date) {
      if (date === undefined) {
        return
      }
      return moment(date).format('DD/MM/YYYY HH:mm')
    },
    getLink (code) {
      if (this.$route.meta.country === 'CL') {
        return '/chile/event/' + code
      }
      if (this.$route.meta.country === 'PE') {
        return '/peru/event/' + code
      }
    }
  }
}
</script>
