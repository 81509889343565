<template>
    <div class="HomeControlView SectionControl">
        <v-container fluid class="fill-height pa-10">
          <v-row>
            <v-col>
              <div class="mx-5 mx-sm-15 subtitleControlSection">Comercio</div>
              <div class="mx-5 mx-sm-15 titleControlSection">Bienvenido.</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <v-img :src="$store.state.merchant.logo" max-width="300px" width="auto" max-height="300px" contain />
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
  name: 'HomeControlView'
}
</script>
