export default {
  methods: {
    verifyAuth () {
      if (!this.$store.state.useriD) {
        return false
      }
      return true
    }
  }
}
