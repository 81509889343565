<template>
  <div class="MarketStoreComponent">
    <v-container>
      <v-row>
        <v-col cols="12" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
          <v-row class="message" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="message" v-html="message"></div>
          </v-row>
          <v-row class="message" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <v-col>
              <div class="d-inline-flex" align-self="center">
                <a class="elevation-0 my-1 mx-1 rounded-lg" href="https://play.google.com/store/apps/details?id=com.PassPoint.passpoint" target="_blank"><v-img contain :src="require('@/assets/logos/google.play.svg')" alt="" max-width="120px" /></a>
                <a class="elevation-0 my-1 mx-2 rounded-lg" href="https://apps.apple.com/cl/app/passpoint/id6465455230" target="_blank"><v-img contain :src="require('@/assets/logos/apple.store.svg')" alt="" max-width="120px" /></a>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_MarketStoreComponent.scss'
export default {
  name: 'MarketStoreComponent',
  props: {
    message: String
  }
}
</script>
