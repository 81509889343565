<template>
    <div class="PaymentRecordsComponent">
        <v-container class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="titleSection">Ventas</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div class="recordsContainer">
                <div v-for="(record, index) in $store.state.logs.payment" :key="index" align="left" class="my-2">
                  <div class="d-flex justify-space-between align-end">
                    <div class="caption">{{ getDateFormat(record.date) }}</div>
                    <div class="caption">
                     ...{{ record.iD.slice(-16) }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                      <div>
                        <div>
                          <span class="font-weight-bold">Total:</span> <span class="font-weight-bold">{{ record.total }}</span> <span class="caption">Puntos</span>
                        </div>
                        <div style="font-size: 70% !important;">
                          <span class="font-weight-bold">Monto:</span> <span class="font-weight-bold">{{ record.amount }}</span> <span class="caption" style="font-size: 70% !important;">Puntos</span><span> + </span>
                          <span class="font-weight-bold">Propina:</span> <span class="font-weight-bold">{{ record.tips }}</span> <span class="caption" style="font-size: 70% !important;">Puntos</span>
                        </div>
                      </div>
                      <div>{{ record.shortTx }}</div>
                  </div>
                  <hr style="opacity: 0.5" />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div>
                <div class="font-weight-bold">Total</div>
                <div class="display-2">{{ total | numFormat('0,000.[00]') }} <span class="caption">Puntos</span></div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div class="scale-8">
                <div class="font-weight-bold">Ventas</div>
                <div class="display-2">{{ amount | numFormat('0,000.[00]') }} <span class="caption">Puntos</span></div>
              </div>
            </v-col>
            <v-col align-self="center" align="center">
              <div class="scale-8">
                <div class="font-weight-bold">Propinas</div>
                <div class="display-2">{{ tips | numFormat('0,000.[00]') }} <span class="caption">Puntos</span></div>
              </div>
            </v-col>
            <v-col align-self="center" align="center">
              <div class="scale-8">
                <div class="font-weight-bold">N°</div>
                <div class="display-2">{{ $store.state.logs.payment.length }} <span class="caption">Ventas</span></div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <v-btn elevation="0" color="customColor" dark outlined @click="$store.commit('closeComponent')">Cerrar</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_PaymentRecordsComponent.scss'
import moment from 'moment'
export default {
  name: 'PaymentRecordsComponent',
  computed: {
    total () {
      let total = 0
      for (const payment of this.$store.state.logs.payment) {
        total += parseFloat(payment.total)
      }
      return total
    },
    amount () {
      let amount = 0
      for (const payment of this.$store.state.logs.payment) {
        amount += parseFloat(payment.amount)
      }
      return amount
    },
    tips () {
      let tips = 0
      for (const payment of this.$store.state.logs.payment) {
        tips += parseFloat(payment.tips)
      }
      return tips
    }
  },
  methods: {
    getDateFormat (timestamp) {
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    }
  }
}
</script>
