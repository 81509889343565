<template>
    <div class="SalesView SectionControl">
      <v-container fluid class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Negocio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Ventas.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <apex-chart v-if="series.length > 0" width="60%" type="area" :options="options" :series="series"></apex-chart>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <v-data-table class="mx-15"
              :headers="sales.headers"
              :items="Object.entries(sales.dataMonth).map(item => item[1])"
            >
              <template v-slot:[`item.key`]="{ item }">
                <span class="text-capitalize">{{ getDateMonthFormat(item.key/1000) }}</span>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <strong>{{ item.total | numFormat('0,000.[00]') }}</strong> <span class="caption">Puntos</span>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                {{ item.amount | numFormat('0,000.[00]') }} <span class="caption">Puntos</span>
              </template>
              <template v-slot:[`item.tips`]="{ item }">
                {{ item.tips | numFormat('0,000.[00]') }} <span class="caption">Puntos</span>
              </template>
              <template v-slot:foot>
                <tfoot>
                  <tr class="font-weight-bold">
                    <td class="text-end"><strong>Total</strong></td>
                    <td>
                      {{ getSum(Object.entries(sales.data).map(item => item[1]), 'total') }} <span class="caption">Puntos</span>
                    </td>
                    <td>
                      {{ getSum(Object.entries(sales.data).map(item => item[1]), 'amount') }} <span class="caption">Puntos</span>
                    </td>
                    <td>
                      {{ getSum(Object.entries(sales.data).map(item => item[1]), 'tips') }} <span class="caption">Puntos</span>
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_SalesView.scss'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'
import { firestore } from '@/plugins/firebase'
import moment from 'moment'
export default {
  name: 'SalesView',
  data () {
    return {
      series: [],
      options: {
        colors: ['#36f4a5', '#1eb3e9', '#2770b0'],
        chart: {
          stacked: false,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        markers: {
          size: 0,
          hover: {
            size: 5
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0.1,
            stops: [0, 60, 100]
          }
        },
        yaxis: {
          title: {
            text: 'Puntos'
          },
          labels: {
            formatter: function (val) {
              return val + ' Puntos'
            }
          }
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          min: moment().subtract(90, 'days').startOf('day').unix() * 1000,
          max: moment().endOf('day').unix() * 1000
        }
      },
      sales: {
        headers: [
          { text: 'Fecha', value: 'key' },
          { text: 'Total', value: 'total' },
          { text: 'Monto', value: 'amount' },
          { text: 'Propina', value: 'tips' }
        ],
        data: {},
        dataMonth: {},
        series: []
      }
    }
  },
  mounted () {
    this.getSales()
  },
  methods: {
    getSum (items, key) {
      let total = 0
      for (const item of items) {
        total += parseFloat(item[key])
      }
      return total
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD')
    },
    getDateMonthFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).locale('es').format('MMMM')
    },
    async getSales () {
      this.loading = true
      const code = this.$store.state.merchant.code
      const limitTime = moment().subtract(90, 'days').startOf('month').startOf('day').unix()
      const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), where('type', '==', 'buy_service'), where('state', '==', 'completed'), orderBy('created', 'desc'), where('created', '>', limitTime))
      const querySnapshot = await getDocs(ref)
      querySnapshot.forEach(doc => {
        const data = doc.data()
        const key = moment.unix(data.created).startOf('day').unix() * 1000
        const keyMonth = moment.unix(data.created).startOf('month').unix() * 1000
        if (key in this.sales.data) {
          this.sales.data[key].total += parseFloat(data.amount) + parseFloat(data.tips)
          this.sales.data[key].amount += parseFloat(data.amount)
          this.sales.data[key].tips += parseFloat(data.tips)
        } else {
          this.sales.data[key] = { key: key, total: parseFloat(data.amount) + parseFloat(data.tips), amount: parseFloat(data.amount), tips: parseFloat(data.tips) }
        }
        if (keyMonth in this.sales.dataMonth) {
          this.sales.dataMonth[keyMonth].total += parseFloat(data.amount) + parseFloat(data.tips)
          this.sales.dataMonth[keyMonth].amount += parseFloat(data.amount)
          this.sales.dataMonth[keyMonth].tips += parseFloat(data.tips)
        } else {
          this.sales.dataMonth[keyMonth] = { key: keyMonth, total: parseFloat(data.amount) + parseFloat(data.tips), amount: parseFloat(data.amount), tips: parseFloat(data.tips) }
        }
      })
      console.log(this.sales.data)
      const series = {
        total: [],
        amount: [],
        tips: []
      }
      for (const index in this.sales.data) {
        series.total.push([parseFloat(index), parseFloat(this.sales.data[index].total)])
        series.amount.push([parseFloat(index), parseFloat(this.sales.data[index].amount)])
        series.tips.push([parseFloat(index), parseFloat(this.sales.data[index].tips)])
      }
      this.series = [
        {
          name: 'Total',
          data: series.total
        },
        {
          name: 'Venta',
          data: series.amount
        },
        {
          name: 'Propina',
          data: series.tips
        }
      ]

      this.loading = false
    }
  }
}
</script>
