<template>
    <div class="SingleButtonParallaxComponent">
      <v-parallax :src="background" height="300">
        <div class="overlay"></div>
        <v-container style="z-index: 1">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="message">{{ message }}</div>
            </v-col>
            <v-col align-self="center" align="center" cols="12" sm="4">
              <v-btn v-if="link" elevation="0" color="customColor" dark class="px-5 py-5" x-small :to=link>{{ button }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </div>
</template>

<script>
import './_SingleButtonParallaxComponent.scss'
export default {
  name: 'SingleButtonParallaxComponent',
  props: {
    message: String,
    button: String,
    link: String,
    background: String
  }
}
</script>
