import Vue from 'vue'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyADBWz5y_jRr_IqpkqKHEypKc4m7G-qb3Y',
    libraries: 'places'
  }
})

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyADBWz5y_jRr_IqpkqKHEypKc4m7G-qb3Y',
  vueGoogleMapsCompatibility: true
})
