<template>
    <div class="TransactionsView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Negocio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Transacciones.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <v-data-table
              v-if="sales.total > 0"
              :loading="loading"
              :items-per-page="limit"
              :headers="sales.headers"
              :items="sales.data.filter(item => sales.filter ? item.type === sales.filter : item).concat(Array(sales.total - sales.data.length).fill({}))"
              item-key="key"
              :options.sync="sales.options"
              @update:items-per-page="getUpdateLimit"
              @update:expanded="processExpanded"
              @update:page="updatePage"
              disable-sort
              :single-expand="true"
              :expanded.sync="sales.expanded"
              show-expand
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-select hide-details style="max-width: 200px" :items="filters" item-value="value" item-text="name" label="Filtro por Tipo" v-model="sales.filter" clearable />
                  <v-spacer></v-spacer>
                  <v-text-field @keydown.enter.prevent="searchCode" hide-details v-model="search" dense label="Buscar Transacción" outlined rounded :append-icon="search.length > 0 ? 'mdi-close' : ''" @click:append="clearSearch" style="max-width: 400px" />
                  <v-btn color="customColor" rounded elevation="0" class="mx-2" @click="searchCode">Buscar</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-spacer />
                <v-btn v-if="false" :disabled="sales.total === sales.data.length" x-small color="customColor" rounded elevation="0" class="mx-2" @click="loadMore">Cargar Más</v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-10" style="background-color: #fafaff">
                  <v-container fluid>
                    <v-row>
                      <v-col style="font-size: 200%" align-self="center" align="left">
                        <div v-if="item.type === 'buy_points'">
                          <v-icon large color="green">mdi-cash</v-icon> <span class="">Compra Puntos</span>
                        </div>
                        <div v-if="item.type === 'buy_service'">
                          <v-icon large color="customColor">mdi-account-credit-card</v-icon> <span class="">Canje</span>
                        </div>
                        <div v-if="item.type === 'buy_giftcard'">
                          <v-icon large color="orange">mdi-swap-horizontal</v-icon> <span class="">Canje de Puntos</span>
                        </div>
                        <div v-if="item.type === 'control_access'">
                          <v-icon large color="cyan">mdi-ticket-confirmation</v-icon> <span class="">Acceso</span>
                        </div>
                        <div v-if="item.type === 'sell_points'">
                          <v-icon large color="success">mdi-credit-card-plus-outline</v-icon> <span class="">Recarga</span>
                        </div>
                        <div v-if="item.type === 'get_card'">
                          <v-icon large color="blue">mdi-card-account-details-star-outline</v-icon> <span class="">Tarjeta</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="item.iD">
                      <v-col>
                        <v-text-field label="iD" v-model="item.iD" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.iD)" />
                      </v-col>
                    </v-row>
                    <v-row v-if="item.type !== 'get_card' && item.client">
                      <v-col>
                        <v-text-field label="Nombre" v-model="item.client.name" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field label="Correo" v-model="item.client.email" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-text-field label="Transacción" v-model="item.track" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.track)" /></v-col>
                      <v-col>
                        <div v-if="item.state === 'completed'">
                          <v-text-field prepend-inner-icon="mdi-check-circle-outline" label="Estado" value="Completado" readonly outlined rounded color="success" hide-details />
                        </div>
                        <div v-if="item.state === 'paid'">
                          <v-text-field prepend-inner-icon="mdi-check-circle-outline" label="Estado" value="Pagado" readonly outlined rounded color="success" hide-details />
                        </div>
                        <div v-if="item.state === 'processing'">
                          <v-text-field prepend-inner-icon="mdi-dots-horizontal-circle-outline" label="Estado" value="En proceso" readonly outlined rounded color="cyan" hide-details />
                        </div>
                        <div v-if="item.state === 'created'">
                          <v-text-field prepend-inner-icon="mdi-circle-outline" label="Estado" value="Creado" readonly outlined rounded color="cyan" hide-details />
                        </div>
                        <div v-if="item.state === 'failed'">
                          <v-text-field prepend-inner-icon="mdi-stop-circle-outline" label="Estado" value="Fallido" readonly outlined rounded color="red" hide-details />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-text-field label="Creado" :value="getDateFormat(item.created)" readonly outlined rounded color="customColor" hide-details /></v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-row v-if="item.type  === 'buy_service'">
                      <v-col><v-text-field label="Total" :value="parseFloat(item.amount) + parseFloat(item.tips)" suffix="Puntos" readonly outlined rounded color="customColor" hide-details /></v-col>
                      <v-col><v-text-field label="Monto" :value="item.amount" suffix="Puntos" readonly outlined rounded color="customColor" hide-details /></v-col>
                      <v-col><v-text-field label="Propina" :value="item.tips" suffix="Puntos" readonly outlined rounded color="customColor" hide-details /></v-col>
                    </v-row>
                    <v-row v-if="item.type  === 'sell_points'">
                      <v-col><v-text-field label="Monto" :value="item.amount" suffix="Puntos" readonly outlined rounded color="customColor" hide-details /></v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-row v-if="item.type === 'get_card' && item.client">
                      <v-col>
                        <v-text-field label="Nombre" v-model="item.client.name" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field label="Correo" v-model="item.client.email" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row v-if="item.type === 'get_card' && item.client">
                      <v-col>
                        <v-text-field label="DNI" v-model="item.client.dni" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field label="Cumpleaños" type="date" v-model="item.client.birthdate" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row v-if="item.type === 'get_card' && item.client">
                      <v-col>
                        <v-text-field type="phone" label="Teléfono" v-model="item.client.phone" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                    <v-row v-if="item.type === 'get_card' && item.client">
                      <v-col>
                        <v-text-field label="Miembro N°" :value="'0000' + item.processor" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                    <v-row v-if="item.employee">
                      <v-col>
                        <v-text-field label="iD Empleado" :value="item.employee" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row v-if="item.employee">
                      <v-col>
                        <v-text-field label="Nombre Empleado" :value="sales.employee" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
              <template v-slot:[`item.created`]="{ item }">
                {{ getDateFormat(item.created) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <div v-if="item.amount > 0">
                {{ item.amount | numFormat('0,000.[00]') }} <span class="caption">Puntos</span>
                </div>
                <div v-if="item.amount === 0">
                  -
                </div>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <div v-if="item.type === 'buy_points'">
                  <v-icon small color="green">mdi-cash</v-icon> <span class="caption">Compra Puntos</span>
                </div>
                <div v-if="item.type === 'buy_service'">
                  <v-icon small color="customColor">mdi-account-credit-card</v-icon> <span class="caption">Canje</span>
                </div>
                <div v-if="item.type === 'buy_giftcard'">
                  <v-icon small color="orange">mdi-swap-horizontal</v-icon> <span class="caption">Canje de Puntos</span>
                </div>
                <div v-if="item.type === 'control_access'">
                  <v-icon small color="cyan">mdi-ticket-confirmation</v-icon> <span class="caption">Acceso</span>
                </div>
                <div v-if="item.type === 'sell_points'">
                  <v-icon small color="success">mdi-credit-card-plus-outline</v-icon> <span class="caption">Recarga</span>
                </div>
                <div v-if="item.type === 'get_card'">
                  <v-icon small color="blue">mdi-card-account-details-star-outline</v-icon> <span class="caption">Tarjeta</span>
                </div>
              </template>
              <template v-slot:[`item.state`]="{ item }">
                <div v-if="item.state === 'completed'">
                    <v-icon small color="success">mdi-check-circle-outline</v-icon> <span class="caption">Completado</span>
                </div>
                <div v-if="item.state === 'paid'">
                  <v-icon small color="success">mdi-check-circle-outline</v-icon> <span class="caption">Pagado</span>
                </div>
                <div v-if="item.state === 'processing'">
                  <v-icon small color="blue">mdi-dots-horizontal-circle-outline</v-icon> <span class="caption">En proceso</span>
                </div>
                <div v-if="item.state === 'created'">
                  <v-icon small color="cyan">mdi-circle-outline</v-icon> <span class="caption">Creado</span>
                </div>
                <div v-if="item.state === 'failed'">
                  <v-icon small color="red">mdi-stop-circle-outline</v-icon> <span class="caption">Fallido</span>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="copySnackbar" top centered>
        Copiado
      </v-snackbar>
      <v-snackbar v-model="notFoundSnackbar" top centered>
        Sin resultados
      </v-snackbar>
    </div>
</template>

<script>
import './_TransactionsView.scss'
import { collection, getCountFromServer, getDocs, limit, query, startAfter, where, orderBy } from 'firebase/firestore'
import { firestore } from '@/plugins/firebase'
import moment from 'moment'
export default {
  name: 'TransactionsView',
  data () {
    return {
      copySnackbar: false,
      notFoundSnackbar: false,
      client: false,
      limit: 5,
      loading: false,
      search: '',
      filters: [
        { value: 'buy_points', name: 'Compra Puntos' },
        { value: 'sell_points', name: 'Recarga' },
        { value: 'control_access', name: 'Acceso' },
        { value: 'buy_giftcard', name: 'Canje de Puntos' },
        { value: 'buy_service', name: 'Canje' },
        { value: 'get_card', name: 'Tarjeta' }
      ],
      sales: {
        filter: false,
        employee: '',
        expanded: [],
        data: [],
        last: null,
        total: 0,
        options: {},
        headers: [
          { text: 'Transacción', value: 'track' },
          { text: 'Creado', value: 'created' },
          { text: 'Tipo', value: 'type' },
          { text: 'Monto', value: 'amount' },
          { text: 'Estado', value: 'state' }
        ]
      }
    }
  },
  async mounted () {
    await this.getCount()
    this.getSales()
  },
  methods: {
    copy (iD) {
      this.copySnackbar = true
      navigator.clipboard.writeText(iD)
    },
    processExpanded (items) {
      this.getClient(items)
      this.getClientByiD(items)
      this.getEmployee(items)
    },
    async getClientByiD (items) {
      if (items.length === 0) {
        return
      }
      const iD = items[0].iD
      if (!iD) {
        return
      }
      const type = this.$store.state.merchant.type
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), where('iD', '==', iD), limit(1))
      const querySnapshot = await getDocs(ref)
      if (querySnapshot.docs.length === 0) {
        return
      }
      const index = this.sales.data.findIndex(item => item.track === items[0].track)
      this.sales.data[index].client = querySnapshot.docs.pop().data().client
      this.$forceUpdate()
    },
    async getClient (items) {
      if (items.length === 0) {
        return
      }
      if (items[0].type !== 'get_card') {
        return
      }
      const member = items[0].processor
      if (!member) {
        return
      }
      console.log(member)
      const type = this.$store.state.merchant.type
      console.log('0000' + member)
      console.log(type)
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), where('data.member', '==', '0000' + member), limit(1))
      const querySnapshot = await getDocs(ref)
      console.log(querySnapshot)
      if (querySnapshot.docs.length === 0) {
        return
      }
      const index = this.sales.data.findIndex(item => item.track === items[0].track)
      this.sales.data[index].client = querySnapshot.docs.pop().data().client
      this.$forceUpdate()
    },
    async getEmployee (items) {
      this.sales.employee = ''
      if (items.length === 0) {
        return
      }
      const iD = items[0].employee
      if (!iD) {
        return
      }
      console.log(iD)
      const type = this.$store.state.merchant.type
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), where('owner', '==', iD), limit(1))
      const querySnapshot = await getDocs(ref)
      if (querySnapshot.docs.length === 0) {
        return
      }
      this.sales.employee = querySnapshot.docs.pop().data().client
    },
    async searchCode () {
      this.loading = true
      const code = this.$store.state.merchant.code
      const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), where('track', '>=', this.search), where('track', '<=', this.search + '\uf8ff'), orderBy('track', 'desc'), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      if (querySnapshot.docs.length === 0) {
        this.notFoundSnackbar = true
        this.loading = false
        return
      }
      this.sales.data = []
      this.sales.total = querySnapshot.docs.length
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.sales.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          amount: data.amount,
          tips: data.tips,
          type: data.type,
          state: data.state,
          processor: data.processor,
          track: data.track ? data.track : doc.id,
          employee: data.processor.employee ? data.processor.employee : false
        })
      })
      this.loading = false
    },
    async clearSearch () {
      this.search = ''
      this.sales.data = []
      await this.getCount()
      this.getSales()
    },
    getUpdateLimit (limit) {
      this.limit = limit
      if (limit === -1) {
        this.limit = 1000
      }
      this.sales.data = []
      this.getSales()
    },
    async updatePage (page) {
      console.log(this.limit)
      console.log(this.sales.options)
      if (this.search.length > 0) {
        return
      }
      this.loading = true
      if (this.sales.data.length < page * this.limit) {
        const code = this.$store.state.merchant.code
        const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), orderBy('created', 'desc'), startAfter(this.sales.last), limit(this.limit))
        const querySnapshot = await getDocs(ref)
        this.sales.last = querySnapshot.docs[querySnapshot.docs.length - 1]
        querySnapshot.forEach(doc => {
          const data = doc.data()
          this.sales.data.push({
            key: doc.id,
            iD: data.iD,
            created: data.created,
            amount: data.amount,
            tips: data.tips,
            type: data.type,
            state: data.state,
            processor: data.processor,
            track: data.track ? data.track : doc.id,
            employee: data.processor.employee ? data.processor.employee : false
          })
        })
      }
      this.loading = false
    },
    async loadMore () {
      console.log(this.limit)
      console.log(this.sales.options)
      if (this.search.length > 0) {
        return
      }
      if (this.sales.data.length === this.sales.total) {
        return
      }
      this.loading = true
      const code = this.$store.state.merchant.code
      const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), orderBy('created', 'desc'), startAfter(this.sales.last), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      this.sales.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.sales.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          amount: data.amount,
          tips: data.tips,
          type: data.type,
          state: data.state,
          processor: data.processor,
          track: data.track ? data.track : doc.id,
          employee: data.processor.employee ? data.processor.employee : false
        })
      })
      this.sales.options.page++
      this.loading = false
    },
    async getCount () {
      const code = this.$store.state.merchant.code
      console.log(code)
      const querySnapshot = await getCountFromServer(query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString())))
      this.sales.total = querySnapshot.data().count
    },
    async getSales () {
      this.loading = true
      const code = this.$store.state.merchant.code
      const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), orderBy('created', 'desc'), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      this.sales.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.sales.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          amount: data.amount,
          tips: data.tips,
          type: data.type,
          state: data.state,
          track: data.track ? data.track : doc.id,
          processor: data.processor,
          employee: data.processor.employee ? data.processor.employee : false
        })
      })
      this.loading = false
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    }
  }
}
</script>
