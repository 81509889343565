<template>
    <div class="HeaderControlComponent">
      <v-app-bar
        app
        color="#f5f5fa"
        height="150px"
        elevation="0"
        style="border-bottom: 1px solid rgba(255, 255, 255, 0.12)"
      >
        <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <router-link :to="{ name: 'apps' }">
          <v-img :src="require('@/assets/logos/logo.enterprices.normal.svg')" v-if="!$vuetify.breakpoint.smAndDown" max-width="200px" width="auto" max-height="60px" contain/>
          <v-img :src="require('@/assets/logos/logo.icon.svg')" v-if="$vuetify.breakpoint.smAndDown" max-width="200px" width="auto" max-height="40px" contain/>
        </router-link>
        <v-spacer />
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-text-field flat placeholder="Ingrese una palabra del elemento a Buscar" rounded outlined hide-details append-icon="mdi-magnify" style="width: 50vw" />
        </div>
        <v-spacer />
        <v-btn elevation="0" to="/apps" icon><v-icon>mdi-apps</v-icon></v-btn>
        <v-btn elevation="0" @click="showNotification" icon>
          <v-badge offset-x="12" offset-y="10" content="3">
            <v-icon>mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
        <v-btn class="mr-2" elevation="0" to="/p/profile/edit" icon><v-icon>mdi-cog</v-icon></v-btn>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        color="#f5f5fa"
        :permanent="!$vuetify.breakpoint.smAndDown"
        fixed
        style="margin-top: 150px"
        clipped
      >
        <v-list
          nav
          class="px-0"
        >
          <v-list-item-group
          >
            <v-list-item-subtitle v-if="$store.state.merchant.type !== 101">
              Comercio
            </v-list-item-subtitle>
            <v-list-item to="/c/profile/edit" v-if="$store.state.merchant.type !== 101">
              <v-list-item-icon class="mx-2">
                <v-icon>mdi-cog-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Configuración
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/c/permissions/list" v-if="$store.state.merchant.type !== 101">
              <v-list-item-icon class="mx-2">
                <v-icon>mdi-security</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Permisos
              </v-list-item-title>
            </v-list-item>
            <v-list-item-subtitle>
              Negocio
            </v-list-item-subtitle>
            <v-list-item to="/c/clients/list" v-if="$store.state.merchant.type !== 101">
              <v-list-item-icon class="mx-2">
                <v-icon>mdi-account-group</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Clientes
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/c/memberships/list">
              <v-list-item-icon class="mx-2">
                <v-icon>mdi-smart-card-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Membresías
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/c/business/sales" v-if="$store.state.merchant.type !== 101">
              <v-list-item-icon class="mx-2">
                <v-icon>mdi-chart-pie</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Ventas
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/c/business/income" v-if="$store.state.merchant.type !== 101">
              <v-list-item-icon class="mx-2">
                <v-icon>mdi-chart-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Ingresos
              </v-list-item-title>
            </v-list-item>
            <v-list-item to="/c/business/transactions">
              <v-list-item-icon class="mx-2">
                <v-icon>mdi-credit-card-settings-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                Transacciones
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <div class="logout">
          <v-btn class="text-capitalize" color="#1e9dd9" x-large elevation="0" @click="$store.commit('closeSession')" dark plain>Salir</v-btn>
          </div>
        </v-list>
      </v-navigation-drawer>
    </div>
</template>

<script>
import './_HeaderControlComponent.scss'
import { auth } from '@/plugins/firebase'
import { signInWithCustomToken } from 'firebase/auth'

export default {
  name: 'HeaderControlComponent',
  data () {
    return {
      drawer: false
    }
  },
  async mounted () {
    if (!this.$store.state.merchant) {
      this.$router.push({ name: 'apps' })
    }
    if (this.$store.state.token) {
      if (!await signInWithCustomToken(auth, this.$store.state.token)) {
        this.$router.push({ name: 'apps' })
      }
      auth.currentUser.getIdTokenResult().then((idTokenResult) => {
        console.log(idTokenResult)
      })
    }
  },
  updated () {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.drawer = false
    }
    if (!this.$store.state.merchant) {
      this.$router.push({ name: 'apps' })
    }
  },
  computed: {
    iD () {
      return this.$store.state.iD
    }
  },
  methods: {
    showLogin () {
      this.$store.commit('setComponent', { component: 'login' })
    },
    showNotification () {
      if (this.$store.state.component === 'notification') {
        this.$store.commit('setComponent', { component: false })
        return
      }
      this.$store.commit('setComponent', { component: 'notification' })
    }
  }
}
</script>
