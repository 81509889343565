<template>
    <div class="FooterControlComponent">
        <v-footer dark color="transparent">
          <v-container fluid>
            <v-row>
              <v-col align-self="center" align="right">
                <v-img :src="require('@/assets/logos/logo.enterprices.normal.alt.svg')" max-width="100px" contain class="py-20" />
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      <v-btn fixed bottom right small icon @click="setThemeMode(!$vuetify.theme.dark)"><v-icon>mdi-brightness-6</v-icon></v-btn>
    </div>
</template>

<script>
import './_FooterControlComponent.scss'
export default {
  name: 'FooterControlComponent',
  methods: {
    setThemeMode (style) {
      this.$vuetify.theme.dark = style
    }
  }
}
</script>
