import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import yapeIcon from '@/icons/yapeIcon/yapeIcon.vue'
import plinIcon from '@/icons/plinIcon/plinIcon.vue'
import onepayIcon from '@/icons/onepayIcon/onepayIcon.vue'
import checkIcon from '@/icons/checkIcon/checkIcon.vue'
import machIcon from '@/icons/machIcon/machIcon.vue'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    values: {
      yape: {
        component: yapeIcon
      },
      plin: {
        component: plinIcon
      },
      onepay: {
        component: onepayIcon
      },
      check: {
        component: checkIcon
      },
      mach: {
        component: machIcon
      }
    }
  },
  theme: {
    themes: {
      light: {
        customColor: '#1e9dd9'
      }
    }
  }
})
