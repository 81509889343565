<template>
    <div class="EditProfileView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Comercio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Configuración.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15">
            <div class="form ml-1">
              <v-text-field class="my-3" label="Nombre del Comercio (Legal)" outlined rounded hide-details />
              <div class="logoForm my-10 mb-15">
                <v-img :src="require('@/assets/logos/logo.icon.svg')" max-width="100%" width="auto" max-height="100%" contain/>
              </div>
              <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark>Actualizar</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_EditProfileView.scss'
export default {
  name: 'EditProfileView'
}
</script>
