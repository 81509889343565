<template>
    <div class="GiftCardsView">
      <v-container fluid>
        <v-row>
          <v-col class="pa-0">
            <BannerTextComponent :image="require('@/assets/banners/banner00.png')" height="40vh" message="Eventos" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <StepComponent title="¿Cómo usar tu Ticket?" :steps="steps" />
          </v-col>
        </v-row>
        <v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div align="center" class="titleSection py-20">
                Gift Cards
              </div>
              <ShowcaseComponent :items="giftcards" />
              <div class="py-15">
                <v-btn elevation="0" color="customColor" outlined dark class="px-5 py-5" x-small to="link">Ver más Gift Cards</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-row>
        <v-row>
          <v-col class="pa-0">
            <SingleButtonParallaxComponent message="Compra tus puntos P'Arriba y canjea tus Gift Cards" button="Comprar Puntos" link="/" :background="require('@/assets/backgrounds/dominga.png')" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_GiftCardsView.scss'
import BannerTextComponent from '@/components/BannerTextComponent/BannerTextComponent.vue'
import StepComponent from '@/components/StepComponent/StepComponent.vue'
import SingleButtonParallaxComponent from '@/components/SingleButtonParallaxComponent/SingleButtonParallaxComponent.vue'
import ShowcaseComponent from '@/components/ShowcaseComponent/ShowcaseComponent'

export default {
  name: 'GiftCardsView',
  components: { StepComponent, ShowcaseComponent, SingleButtonParallaxComponent, BannerTextComponent },
  data () {
    return {
      giftcards: [
        {
          banner: require('@/assets/giftcards/200d.png'),
          name: 'Gift Card 200 Puntos',
          description: 'Hasta un 20% extra de puntos'
        },
        {
          banner: require('@/assets/giftcards/150d.png'),
          name: 'Gift Card 150 Puntos',
          description: 'Hasta un 15% extra de puntos'
        },
        {
          banner: require('@/assets/giftcards/100d.png'),
          name: 'Gift Card 100 Puntos',
          description: 'Hasta un 10% extra de puntos'
        },
        {
          banner: require('@/assets/giftcards/50d.png'),
          name: 'Gift Card 50 Puntos',
          description: 'Hasta un 5% extra de puntos'
        }
      ],
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  }
}
</script>
