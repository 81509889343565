<template>
    <div class="PasspointView">
      <v-container class="fill-height" fluid>
        <v-btn
            v-if="showScrollButton"
            fab
            bottom
            right
            fixed
            color="white"
            @click="scrollToTop"
          >
          <v-icon color="primary">
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              PASSPOINT
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">PassPoint</div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Programa de Lealtad
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Ofrecemos tickets online de eventos, plataforma de difusión, totems de auto atención, tarjetas de membresía, sistemas de recompensas, Gift Cards, promociones y softwares de auto atención. Este programa se asocia con diversos comercios, como clubes, retailers y otros negocios, lo que permite a los usuarios acceder a una amplia gama de productos y servicios.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Compra de Puntos
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint ofrece flexibilidad a sus usuarios al permitirles comprar puntos utilizando diversos medios de pago, como tarjetas de crédito, débito, transferencias bancarias, criptomonedas, entre otros. Una vez que los puntos han sido adquiridos son agregados directamente a su membresía PassPoint. Esto facilita la administración y seguimiento de los puntos acumulados.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Canje de Puntos PassPoint
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Los Puntos PassPoint pueden ser canjeados por una amplia gama de productos y beneficios en los comercios asociados. Algunos ejemplos de recompensas incluyen descuentos en productos o servicios, tarjetas de regalo para usar en tiendas específicas, acceso a eventos exclusivos, promociones especiales y más.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Oportunidades PassPoint
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Al unirse a PassPoint, los usuarios tienen la oportunidad de maximizar el valor de sus canjes y disfrutar de experiencias y recompensas únicas en los comercios asociados. Esto les permite ahorrar dinero y acceder a beneficios exclusivos que pueden mejorar su estilo de vida. </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Responsabilidad Legal
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Una vez que los puntos PassPoint han sido canjeados por productos, beneficios o servicios en un comercio asociado, PassPoint no tiene responsabilidad legal en relación con esos puntos y la transacción en sí. La relación se establece entre el comercio asociado y el cliente final. PassPoint no es responsable de la calidad, garantía o cualquier otro aspecto relacionado con los productos y servicios ofrecidos por los comercios asociados.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Consulta relacionada con el producto
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Cualquier inconveniente, disputa o consulta relacionada con el producto, beneficio o servicio obtenido a través del canje de puntos, deberá ser abordada directamente con el comercio asociado. PassPoint no es responsable de la calidad, garantía o cualquier otro aspecto relacionado con los productos y servicios ofrecidos por los comercios asociados.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Sin embargo, es importante tener en cuenta que PassPoint tiene un interés en mantener una buena reputación y una relación sólida con sus usuarios y comercios asociados. Por lo tanto, aunque no tenga responsabilidad legal directa en estas situaciones, es posible que PassPoint desee recibir comentarios de los usuarios y ayudar a facilitar la comunicación entre las partes si es necesario. Esto les permitirá mejorar continuamente su programa y garantizar la satisfacción de los miembros.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
             </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_PasspointView.scss'

export default {
  name: 'PasspointView',
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
