<template>
    <div class="EventListView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Eventos</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Lista de Eventos</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <v-data-table
              :loading="loading"
              :items-per-page="limit"
              :headers="events.headers"
              :items="events.data"
              :search="search "
              item-key="hash"
              :options.sync="events.options"
              @update:expanded="getBalance"
              :single-expand="true"
              :expanded.sync="events.expanded"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-btn width="150" color="customColor" rounded elevation="0" class="mx-2" to="/p/events/create">Crear Evento</v-btn>
                  <v-combobox
                    v-model="filterState"
                    :items="stateOptions"
                    label="Estado"
                    solo
                    rounded
                    dense
                    hide-details
                    outlined
                    multiple
                    @change="getEvents"
                    class="my-2 mx-2"
                    :append-icon="filterState.length > 0 ? 'mdi-close' : ''"
                    @click:append="clearFilterState"
                    style="max-width: 400px"
                    item-text="text"
                    item-value="value"
                    return-object
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" small label outlined @remove="remove(item)">
                            <span v-if="filterState.length > 0">
                          {{ filterState.length }} de {{ stateOptions.length }} Filtros Seleccionados
                           </span>
                        <span v-else>
                        0 de {{ stateOptions.length }} Filtros Seleccionados
                        </span>
                      </v-chip>
                    </template>
                  </v-combobox>
                  <v-text-field hide-details v-model="search" dense label="Buscar" outlined rounded :append-icon="search.length > 0 ? 'mdi-close' : ''" @click:append="clearSearch" style="max-width: 400px" />
                </v-toolbar>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-10" style="background-color: #fafaff">
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-text-field label="iD" v-model="item.hash" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.hash)" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field label="Nombre" v-model="item.event.name" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field label="Correo" v-model="item.event.description" outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select label="Categoría" clearable v-model="item.event.categories" item-text="name" item-value="level" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
              <template v-slot:[`item.event.primaryImage`]="{ item }">
                <v-img :src="item.event.primaryImage" class="my-2" contain width="100px"></v-img>
              </template>
              <template v-slot:[`item.event.name`]="{ item }">
                <strong>{{ item.event.name }}</strong>
              </template>
              <template v-slot:[`item.event.start`]="{ item }">
                {{ getStringDateFormat(item.event.start) }}
              </template>
              <template v-slot:[`item.event.sellTicket`]="{ item }">
                0 de {{ item.tickets }} Tickets
              </template>
              <template v-slot:[`item.state`]="{ item }">
                <v-chip v-if="item.state === 'created'" color="yellow">Creado</v-chip>
                <v-chip v-if="item.state === 'review'" color="orange">En revisión</v-chip>
                <v-chip v-if="item.state === 'active'" color="green">Activo</v-chip>
                <v-chip v-if="item.state === 'inactive'" color="grey">Inactivo</v-chip>
                <v-chip v-if="item.state === 'completed'" color="cyan">Finalizado</v-chip>
                <v-chip v-if="item.state === 'expired'" color="red">Expirado</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon :to="{ name: 'membershipSales', params: { iD: item.iD } }"><v-icon>mdi-eye</v-icon></v-btn>
                  </template>
                  <span>Ventas</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon :to="{ name: 'panelEditEvent', params: { hash: item.hash } }"><v-icon>mdi-note-edit</v-icon></v-btn>
                  </template>
                  <span>Editar evento</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon :to="{name: 'panelListTicketsEvent', params: { hash: item.hash }}"><v-icon>mdi-ticket</v-icon></v-btn>
                  </template>
                  <span>Ver tickets</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon :to="{ name: 'accessControlEvent', params: { event: item.hash } }"><v-icon>mdi-paper-cut-vertical</v-icon></v-btn>
                  </template>
                  <span>Control de Acceso para el Evento</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon :to="{ name: 'reportEvent', params: { event: item.hash } }"><v-icon>mdi-file-document-outline</v-icon></v-btn>
                  </template>
                  <span>Reporte Asistencia</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon :href="'/chile/event/' + item.hash" target="_blank"><v-icon>mdi-open-in-new</v-icon></v-btn>
                  </template>
                  <span>Página del evento</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="copySnackbar" top centered>
        Copiado
      </v-snackbar>
      <v-snackbar v-model="saveSnackbar" top centered>
        Guardado
      </v-snackbar>
    </div>
</template>

<script>
import './_EventListView.scss'
import moment from 'moment'
export default {
  name: 'EventListView',
  data () {
    return {
      copySnackbar: false,
      saveSnackbar: false,
      search: '',
      limit: 5,
      filterState: [
        { text: 'Creado', value: 'created' },
        { text: 'En Revisión', value: 'review' },
        { text: 'Activo', value: 'active' }
      ],
      fetchedEvents: [],
      loading: false,
      events: {
        expanded: [],
        data: [],
        headers: [
          { text: '', value: 'event.primaryImage' },
          { text: 'Nombre', value: 'event.name' },
          { text: 'Pais', value: 'event.country' },
          { text: 'Fecha del Evento', value: 'event.start' },
          { text: 'Lugar', value: 'event.place' },
          { text: 'Estado', value: 'state' },
          { text: '', value: 'actions' }
        ],
        options: {
          page: 1,
          itemsPerPage: 5,
          sortBy: ['event.start'],
          sortDesc: [true]
        }
      }
    }
  },
  mounted () {
    this.getEvents()
  },
  computed: {
    // State options for the combobox, with Spanish labels
    stateOptions () {
      return [
        { text: 'Creado', value: 'created' },
        { text: 'En revisión', value: 'review' },
        { text: 'Activo', value: 'active' },
        { text: 'Inactivo', value: 'inactive' },
        { text: 'Finalizado', value: 'completed' },
        { text: 'Expirado', value: 'expired' }
      ]
    }
  },
  methods: {
    copy (iD) {
      this.copySnackbar = true
      navigator.clipboard.writeText(iD)
    },
    clearFilterState () {
      // Reset to show all items when no specific filter is selected
      this.filterState = []
      this.getEvents() // Refresh the events list
    },
    remove (item) {
      this.filterState = this.filterState.filter(state => state.value !== item.value)
      if (this.filterState.length === 0) {
        // If no filters are selected, reset to show all
        this.filterState.push(...this.stateOptions)
      }
      this.getEvents() // Refresh the events list
    },
    clearSearch () {
      this.search = ''
    },
    async searchName () {
      this.loading = true
    },
    getStringDateFormat (date) {
      if (date === undefined) {
        return
      }
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    },
    getDiffDay (date) {
      const days = moment(date, 'YYYY-MM-DDTHH:mm').diff(moment(), 'days')
      if (days === 0) {
        return 'Expira Hoy'
      }
      if (days > 0) {
        return 'Expira en ' + days.toString() + ' días'
      }
      if (days < 0) {
        return 'Expiró hace ' + Math.abs(days).toString() + ' días'
      }
    },
    async getBalance (items) {
      if (items.length === 0) {
        return
      }
      const iD = items[0].iD
      console.log(iD)
    },
    async getEvents () {
      this.loading = true
      try {
        const response = await this.$axios.get('/thirdparty/passpoint/events', {
          params: {
            iD: this.$store.state.iD,
            useriD: this.$store.state.useriD,
            session: this.$store.state.session
          }
        })

        // Convert event start to Date object for sorting
        const eventsWithValidDates = response.data.map(event => ({
          ...event,
          start: new Date(event.event.start)
        }))

        // Sort events by start date
        eventsWithValidDates.sort((a, b) => a.start - b.start)

        // Filter events if filterState is not empty and does not contain 'Todos'
        this.events.data = (!this.filterState.length || this.filterState.includes('Todos'))
          ? eventsWithValidDates
          : eventsWithValidDates.filter(event =>
            this.filterState.map(f => f.value).includes(event.state)
          )

        // Update the fetchedEvents with the new sorted and filtered list
        this.fetchedEvents = this.events.data
      } catch (error) {
        console.error('Error fetching events:', error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
