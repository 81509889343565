<template>
  <div class="PermissionsView SectionControl">
    <v-container class="fill-height pa-10">
      <v-row>
        <v-col>
          <div class="mx-5 mx-sm-15 subtitleControlSection">Comercio</div>
          <div class="mx-5 mx-sm-15 titleControlSection">Permisos.</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
          <v-data-table
            v-model="selected"
            :loading="loading"
            :items-per-page="limit"
            :headers="permissions.headers"
            :items="permissions.data"
            item-key="iD"
            :options.sync="permissions.options"
            :single-expand="true"
            :expanded.sync="permissions.expanded"
            show-expand
            show-select
            return-object
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn width="150" color="customColor" rounded elevation="0" class="mx-2" to="/c/permissions/create">Crear</v-btn>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="pa-10" style="background-color: #fafaff">
                <v-container fluid>
                  <v-row>
                    <v-col>
                      <v-text-field label="iD" v-model="item.iD" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.iD)" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete multiple label="Permisos" v-model="item.permissions" :items="permissionsList" item-value="value" item-text="name" outlined rounded />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="save(item)">Guardar</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
            <template v-slot:[`item.iD`]="{ item }">
              <strong>...{{ item.iD.slice(-16) }}</strong>
            </template>
            <template v-slot:[`item.permissions`]="{ item }">
              <div v-if="item.permissions.includes('dashboard')">Dashboard</div>
              <div v-if="item.permissions.includes('recharge')">Recarga</div>
              <div v-if="item.permissions.includes('access')">Control de Acceso</div>
              <div v-if="item.permissions.includes('payment')">Pagos</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn icon :to="{ name: 'membershipSales', params: { iD: item.iD } }"><v-icon>mdi-sale</v-icon></v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="copySnackbar" top centered>
      Copiado
    </v-snackbar>
    <v-snackbar v-model="saveSnackbar" top centered>
      Guardado
    </v-snackbar>
  </div>
</template>

<script>
import './_PermissionsView.scss'
import { firestore } from '@/plugins/firebase'
import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  doc
} from 'firebase/firestore'
import moment from 'moment/moment'

export default {
  name: 'PermissionsView',
  data () {
    return {
      permissionsList: [
        { name: 'Dashboard', value: 'dashboard' },
        { name: 'Recarga', value: 'recharge' },
        { name: 'Control de Acceso', value: 'access' },
        { name: 'Pagos', value: 'payment' }
      ],
      category: false,
      copySnackbar: false,
      saveSnackbar: false,
      limit: 5,
      selected: [],
      loading: false,
      search: '',
      permissions: {
        balance: '',
        expanded: [],
        data: [],
        last: null,
        total: 0,
        options: {},
        headers: [
          { text: 'iD', value: 'iD' },
          { text: 'Permisos', value: 'permissions' }
        ]
      }
    }
  },
  mounted () {
    this.getPermissions()
  },
  methods: {
    processCategory () {
      this.$store.commit('activeLoading')
      this.selected.forEach(item => {
        item.category.level = this.category.level
        item.category.expire = this.category.expire
        this.save(item)
      })
      this.$store.commit('closeLoading')
      this.category = false
    },
    showCategory () {
      this.category = { level: 0, expire: 0 }
    },
    save (item) {
      const merchant = this.$store.state.merchant.code
      const data = {
      }
      data['apps.' + merchant] = item.permissions
      const docRef = doc(firestore, 'Accesses', item.key)
      updateDoc(docRef, data).then(() => {
        this.saveSnackbar = true
      })
    },
    copy (iD) {
      this.copySnackbar = true
      navigator.clipboard.writeText(iD)
    },
    getUpdateLimit (limit) {
      this.limit = limit
      if (limit === -1) {
        this.limit = 1000
      }
      this.cards.data = []
      this.getCards()
    },
    clearSearch () {
      this.search = ''
      this.cards.data = []
      this.getCards()
    },
    async getPermissions () {
      this.loading = true
      const merchant = this.$store.state.merchant.code
      const ref = await query(collection(firestore, 'Accesses'), where('merchants', 'array-contains', merchant.toString()))
      const querySnapshot = await getDocs(ref)
      querySnapshot.forEach(doc => {
        const data = doc.data()
        if (data.merchants.includes(merchant)) {
          this.permissions.data.push({
            key: doc.id,
            iD: doc.id,
            permissions: data.apps[merchant]
          })
        }
      })
      this.loading = false
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    },
    getDiffDay (date) {
      const days = moment(date, 'YYYY-MM-DDTHH:mm').diff(moment(), 'days')
      if (days === 0) {
        return 'Expira Hoy'
      }
      if (days > 0) {
        return 'Expira en ' + days.toString() + ' días'
      }
      if (days < 0) {
        return 'Expiró hace ' + Math.abs(days).toString() + ' días'
      }
    }
  }
}
</script>
