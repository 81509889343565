<template>
    <div class="SliderBannerComponent">
      <v-carousel hide-delimiters  height="auto" cycle>
        <v-carousel-item v-for="(image, key) in images" :key="key" :src="image">
        </v-carousel-item>
      </v-carousel>
    </div>
</template>

<script>
import './_SliderBannerComponent.scss'
export default {
  name: 'SliderBannerComponent',
  props: {
    images: Array,
    size: String
  }
}
</script>
