<template>
    <div class="FAQView">
      <v-container class="fill-height" fluid>
        <v-btn
            v-if="showScrollButton"
            fab
            bottom
            right
            fixed
            color="white"
            @click="scrollToTop"
          >
          <v-icon color="primary">
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              Preguntas Frecuentes
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">Preguntas Frecuentes</div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
              ¿Cómo puedo descargar e instalar PassPoint?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
            Descargar la APP es muy simple, solo debes contar con un teléfono móvil iOS o Android y acceder a los links de
            descarga presentes en nuestra página web.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Cuáles son las funciones principales de PassPoint?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Passpoint ofrece confianza y comodidad incomparables para los clientes, brindándoles un lugar de autocustodia para
                administrar todas las claves y datos protegidos, con la garantía de control total sobre qué información está específicamente
                autorizada y la libertad de ser suprimida por el propietario de la aplicación en el momento que quiera.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Cómo me registro en PassPoint?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Registrarse en Passpoint es sencillo, tan solo necesitas descargar la aplicación y ya puedes crear tu identidad digital Passpoint.
                La magia detrás de Passpoint es hacerlo lo más fácil posible para el cliente. Simplemente instálelo, cree su identificación y estará listo para comenzar.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Se protege mi información?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Seguridad, privacidad y control son las principales características de Passpoint. Con Passpoint obtienes un pase seguro post-cuántico a nuestra plataforma web
                y a muchas funciones en todo nuestro ecosistema, con la confianza de que no se compartirán datos sin tu consentimiento expreso.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Qué hacer si tengo problemas de Acceso?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Si llegases a presentar inconvenientes en tu acceso a Passpoint, puedes ponerte en contacto con nuestros canales de soporte.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Cómo borro la cuenta?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Eliminar tu cuenta es tan simple como eliminar la App Passpoint de tu dispositivo. Para eliminar tus datos de nuestra plataforma, sigue estos pasos:
                Abra la tienda de aplicaciones en su dispositivo.
                Busca nuestra app e identifícala.
                Seleccione la opción de desinstalar o eliminar.
                Confirme la acción cuando se le solicite.
                Una vez que haya eliminado la aplicación de su dispositivo, se eliminarán sus datos asociados con la aplicación.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Dónde se almacena la información?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Todo se almacena localmente con el cifrado más seguro que puede existir y nuestra empresa no obtiene ninguna información ni datos de seguimiento de usted ni de su dispositivo.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Qué pasa si la cámara de mi teléfono falla?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Al instalar la aplicación esta te preguntará si deseas activar los permisos de la cámara. En caso de que durante tu instalación hayas escogido no activar
                los permisos debes dirigirte a la sección de las APP / Permisos / Activar los permisos.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Puedo respaldar mi cuenta?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                La aplicación en sí ofrece una forma segura de crear y cargar copias de seguridad completamente cifradas para evitar la pérdida de datos; una vez que un usuario crea su cuenta Passpoint;
                puede desbloquear su estilo de vida digital todo el tiempo que quiera, sin importar si necesita cambiar de dispositivo.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                ¿Qué hago si cambio mi teléfono móvil?
             </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                En caso que desees cambiar tu dispositivo sin perder tu información, solo debes descargar nuevamente la aplicación e importar tu respaldo,
                con ello ya tendrás tu registro de datos nuevamente en tu nuevo teléfono móvil.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
                Política de Devoluciones
              </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                PassPoint expresamente declara que no se realizarán cambios o devoluciones cuando la compra de puntos haya sido correctamente efectuada, recordando además que los puntos adquiridos no caducan y pueden ser ocupados en equivalencia a su monto en dinero en cualquiera de las ofertas y eventos que comercios asociados a PassPoint emitan.
              </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Todos los canjes hechos con puntos PassPoint; ya sea por tickets digitales u otros bienes o servicios; son asociados a la cuenta PassPoint del cliente con máximas medidas de seguridad, por lo anterior el cliente es el responsable de verificar bien su pedido de puntos y de mantener su cuenta y dispositivos a resguardo.
              </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                La adquisición de tickets digitales mediante puntos PassPoint inmediatamente delega toda responsabilidad hacia el productor que emitió tales tickets. Los productores y comercios son responsables de los eventos, productos y/o servicios que ofrezcan y comercialicen a través de la plataforma PassPoint u otros medios a través del canje de puntos PassPoint. Dado los contratos entre PassPoint, productores y comercios, PassPoint se exime de la responsabilidad de devoluciones, la cual recae en el productor o comercio que comercializó el producto independiente de su naturaleza.
              </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Sólo en casos de fuerza mayor, ya sea que un Evento cambie de fecha, horario, lugar del mismo, artistas u otro que alteren la naturaleza del espectáculo PassPoint efectuará devolución de montos pagados sólo a aquellos clientes que efectivamente hayan canjeado puntos por tickets digitales del evento, con al menos 12 horas de anticipación al evento soliciten tal devolución y que hayan sido autorizados y validados por el organizador del evento en cuestión.
              </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Si por fuerza mayor también el evento se suspendiese o cancelase, aquellos clientes que efectivamente hayan canjeado puntos PassPoint por tickets para el evento en cuestión podrán optar a la devolución de los montos trancados en moneda local, por transferencia bancaria directa e individual.
              </div>
            </v-row>
            <v-row>
              <div class="FAQText">
                Las condiciones de devolución incluyen descuento del 10% más IVA correspondiente a la comisión de PassPoint, y los montos deben ser liberados por el productor o comercio responsable. En todos los casos mencionados anteriormente la devolución se hará efectiva en un tiempo máximo de treinta (30) días hábiles.
              </div>
            </v-row>
            <v-row>
              <div class="FAQTitle py-5">
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_FAQView.scss'

export default {
  name: 'FAQView',
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
