<template>
    <div class="SectionsView">
      <HeaderComponent />
      <router-view/>
      <FooterComponent />
    </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent/FooterComponent.vue'

export default {
  name: 'SectionsView',
  components: {
    FooterComponent,
    HeaderComponent
  }
}
</script>
