<template>
    <div class="EditEventView SectionControl">
      <v-container class="fill-height pa-5">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-5 subtitleControlSection">Eventos</div>
            <div class="mx-5 mx-sm-5 titleControlSection">Editar Evento</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-5">
              <v-btn width="150" color="customColor" rounded elevation="0" class="mx-2" :to="{name: 'accessControlEvent', params: { event: hash }}">Acceso</v-btn>
              <v-btn width="150" color="customColor" rounded elevation="0" class="mx-2" :to="{name: 'panelListTicketsEvent', params: { hash: hash }}">Tickets</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-5">
            <div class="form ml-1">
              <v-form class="pa-3" v-if="event" ref="form">
                <v-row v-if="this.$store.state.producer.type==='root'">
                  <v-select v-model="item.state"
                  :items="['created','review','active','inactive','completed','expired']"
                  :rules="[rules.required]"
                  class="my-3 mx-2" outlined rounded label="Estado de Evento" />
                  <v-btn  width="150" height="56" dark rounded elevation="0" class="mx-2 my-3" @click="processStateChange">Cambiar Estado</v-btn>
                </v-row>
                <div class="py-5" align="right">
                  <v-chip v-if="item.state === 'created'" color="yellow">Creado</v-chip>
                  <v-chip v-if="item.state === 'review'" color="orange">En revisión</v-chip>
                  <v-chip v-if="item.state === 'active'" color="green">Activo</v-chip>
                  <v-chip v-if="item.state === 'inactive'" color="grey">Inactivo</v-chip>
                  <v-chip v-if="item.state === 'completed'" color="cyan">Finalizado</v-chip>
                  <v-chip v-if="item.state === 'expired'" color="red">Expirado</v-chip>
                </div>
                <v-text-field readonly v-model="event.name" class="my-3" outlined rounded label="Nombre del Evento" />
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="event.type" :items="['Publico', 'Privado']" :rules="[rules.required]" class="my-3 mx-1" outlined rounded label="Tipo de Evento" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="event.country" :items="['Chile', 'Peru']" :rules="[rules.required]" class="my-3 mx-1" outlined rounded label="Pais del Evento" />
                  </v-col>
                </v-row>
                <v-textarea v-model="event.description" counter class="my-3" :rules="[rules.required]" outlined rounded label="Descripción del Evento" />
                <v-text-field v-model="event.points" counter class="my-3"  type="number" suffix="puntos" outlined rounded label="Valor mínimo de Tickets" />
                <v-checkbox v-model="event.adults" class="my-3" label="Evento para mayores de edad" />
                <v-combobox v-model="event.categories" class="my-3" outlined rounded label="Categorías del Evento" messages="Hasta 3 categorías" chips small-chips multiple  />
                <v-combobox v-model="event.artists" class="my-3" outlined rounded label="Artistas participantes" chips small-chips multiple  />
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="event.startDate" :rules="[rules.fechaHora]" type="date" class="my-3 mx-1" outlined rounded label="Fecha Inicio del Evento" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="event.startTime" :rules="[rules.fechaHora]" type="time" class="my-3 mx-1" outlined rounded label="Hora Inicio del Evento" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="event.endDate" :rules="[rules.fechaHora]" type="date" class="my-3 mx-1" outlined rounded label="Fecha Termino del Evento" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="event.endTime" :rules="[rules.fechaHora]" type="time" class="my-3 mx-1" outlined rounded label="Hora Termino del Evento" />
                  </v-col>
                </v-row>
                <div class="d-flex">
                  <!--v-text-field v-model="event.open" type="datetime-local" class="my-3 mx-1" outlined rounded label="Hora de Apertura" /-->
                  <v-text-field v-model="event.place" :rules="[rules.required]" class="my-3 mx-1" outlined rounded label="Lugar del Evento" />
                </div>
                <vuetify-google-autocomplete
                  v-model="event.address"
                  :rules="[rules.required]"
                  id="map"
                  class="my-3" outlined rounded label="Dirección del Evento" prepend-icon="mdi-map"
                  country="cl"
                  v-on:placechanged="setAddressData"
                />
                <v-text-field v-model="event.video" class="my-3" outlined rounded label="Video Promocional" messages="URL de youtube" prepend-icon="mdi-youtube" />
                <v-file-input accept="image/*" v-model="primaryImage" class="my-3" outlined rounded label="Imagen Principal del Evento" messages="Formato soportado de 900x900 pixeles" @change="(e) => previewImage(e, 'primaryImage')"/>
                <div align="center">
                <v-img :src="event.primaryImage" contain width="80%" max-width="500px"></v-img>
                </div>
                <v-alert v-if="!validForm" :value="true" type="error">
                  Debes llenar todos los campos solicitados
                </v-alert>
                <v-alert v-if="startAfterEnd" :value="true" type="error">
                  La fecha de inicio debe ser anterior a la fecha de término de tu evento
                </v-alert>
                <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="processRequest">Editar Evento</v-btn>
                <v-btn elevation="0" class="my-3 text-capitalize" outlined rounded block x-large color="red" dark @click="processRequest">Eliminar Evento</v-btn>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="editor" v-if="editPreview">
        <v-container fluid class="fill-height editor-background">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="editor-box">
                <PinturaEditor
                  v-bind="editorDefaults"
                  :src="editImage"
                  :imageCropAspectRatio="radio"
                  @pintura:process="finishPreview"
                ></PinturaEditor>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</template>

<script>
import './_EditEventView.scss'
import auth from '@/mixins/auth'
import { createDefaultImageWriter, getEditorDefaults } from '@pqina/pintura'
import { PinturaEditor } from '@pqina/vue-pintura/vue-2'
import { storage } from '@/plugins/firebase'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import moment from 'moment/moment'

export default {
  name: 'EditEventView',
  components: { PinturaEditor },
  data () {
    return {
      validForm: true,
      startAfterEnd: false,
      radio: 1,
      errors: {},
      primaryImage: null,
      editItem: false,
      editImage: false,
      hash: false,
      item: {},
      event: false,
      editorDefaults: getEditorDefaults(),
      editPreview: false,
      modalEndTime: false,
      modalStartTime: false,
      time: null,
      rules: {
        required: value => !!value || 'Campo obligatorio.',
        fechaHora: value => !!value || 'Presiona el icono para llenar este campo',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-Mail inválido.'
        },
        rut: value => {
          const pattern = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/
          console.log(pattern.test(value))
          return pattern.test(value) || 'Rut inválido. Ingresa puntos y guión (12.345.678-9)'
        },
        telefono: value => {

        }
      }
    }
  },
  mounted () {
    this.hash = this.$route.params.hash
    this.editorDefaults.imageWriter = createDefaultImageWriter({
      targetSize: {
        width: 900,
        height: 900,
        upscale: true
      }
    })
    this.loadingEvent()
  },
  mixins: [auth],
  methods: {
    setAddressData: function (addressData, placeResultData, id) {
      this.event.addressData = addressData
    },
    async finishPreview (result) {
      console.log(result)
      this.$store.commit('activeLoading')
      const storageRef = ref(storage, '/events/' + result.dest.name)
      uploadBytes(storageRef, result.dest).then(async (snapshot) => {
        this.event[this.editItem] = await getDownloadURL(snapshot.ref)
        this.$store.commit('closeLoading')
        this.editPreview = false
        this.editItem = false
        this.editPreview = false
      })
    },
    async previewImage (e, item) {
      this.errors[item] = null
      if (item === 'primaryImage') {
        this.radio = 1
        this.editorDefaults.imageWriter = createDefaultImageWriter({
          targetSize: {
            width: 900,
            height: 900,
            upscale: true
          }
        })
      }

      const file = e
      if (file.size > 10 * 1024 * 1024) {
        this.errors[item] = 'La imagen tiene mas de 10 Mbytes'
        return
      }
      this.editImage = URL.createObjectURL(file)
      this.editItem = item
      this.editPreview = true
    },
    loadingEvent () {
      this.$store.commit('activeLoading')
      if (!this.verifyAuth()) return
      console.log(this.event)
      this.$axios.get('/thirdparty/passpoint/events/' + this.hash, {
        iD: this.$store.state.useriD,
        session: this.$store.state.session
      }).then(response => {
        console.log(response)
        this.item = response.data
        this.event = this.item.event
        this.event.startDate = this.item.event.start.split('T')[0]
        this.event.startTime = this.item.event.start.split('T')[1]
        this.event.endDate = this.item.event.end.split('T')[0]
        this.event.endTime = this.item.event.end.split('T')[1]

        this.$store.commit('closeLoading')
      })
    },
    async advanceValidate () {
      const inicio = moment(this.event.startDate + 'T' + this.event.startTime).unix()
      const fin = moment(this.event.endDate + 'T' + this.event.endTime).unix()

      if ((!isNaN(inicio)) && (!isNaN(fin))) {
        this.startAfterEnd = (inicio > fin)
      }
      return !this.startAfterEnd
    },
    async processStateChange () {
      if (this.$store.state.producer.type !== 'root') return

      this.$axios.put('/thirdparty/passpoint/events/' + this.hash + '/state', {
        iD: this.$store.state.iD,
        state: this.item.state,
        session: this.$store.state.session
      }).then(response => {
        console.log(response.data)
        this.$store.commit('closeLoading')
        this.$router.push({ name: 'panelListEvent' })
      })
    },
    async processRequest () {
      this.$store.commit('activeLoading')
      if (!this.verifyAuth()) return
      this.validForm = await this.$refs.form.validate()
      this.validForm = await this.advanceValidate() && this.validForm
      if (!this.validForm) {
        this.$store.commit('closeLoading')
        return
      }

      this.event.start = this.event.startDate + 'T' + this.event.startTime
      this.event.end = this.event.endDate + 'T' + this.event.endTime
      this.event.startTimestamp = moment(this.event.start).unix()
      this.event.endTimestamp = moment(this.event.end).unix()
      this.$axios.put('/thirdparty/passpoint/events/' + this.hash, {
        iD: this.$store.state.iD,
        event: this.event,
        session: this.$store.state.session
      }).then(response => {
        console.log(response.data)
        this.$store.commit('closeLoading')
        this.$router.push({ name: 'panelListEvent' })
      })
    }
  }
}
</script>
