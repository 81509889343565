<template>
    <div class="PaymentView">
      <v-app-bar app v-if="!summary">
        <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.normal.svg')" max-width="200px" width="auto" max-height="100px" contain/>
        <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.dark.svg')" max-width="200px" width="auto" max-height="100px" contain/>
        <v-spacer />
        <v-btn color="customColor" elevation="0" to="/apps" icon plain tile small><v-icon small>mdi-apps</v-icon></v-btn>
        <v-btn dark outlined color="customColor" @click="$store.commit('setComponent', { component: 'paymentRecords' })">Ventas</v-btn>
      </v-app-bar>
      <v-container class="fill-height">
        <v-row>
          <v-col align-self="center" align="center">
            <v-img :src="$store.state.merchant.logo" max-width="200px" width="auto" max-height="100px" contain />
          </v-col>
        </v-row>
        <v-row v-if="!summary">
          <v-col align-self="center" align="center">
            <div class="titleSection">Monto</div>
            <v-text-field type="number" class="boxField" outlined flat color="customColor" v-model="amount" suffix="Puntos" style="max-width: 300px" />
            <div class="titleSection">Propina</div>
            <v-text-field type="number" class="boxField" outlined flat color="customColor" v-model="tips" suffix="Puntos" style="max-width: 300px" />
          </v-col>
        </v-row>
        <v-row v-if="summary && !success && !failed">
          <v-col align-self="center" align="center" class="py-15">
            <v-img class="qr" v-if="qr" :src="qr" width="250px" height="250px" max-width="250px" max-height="250px" aspect-ratio="1" style="border-radius: 16px" contain />
          </v-col>
        </v-row>
        <v-row v-if="summary && success">
          <v-col align-self="center" align="center">
            <div>
              <v-icon size="128" color="success">mdi-check-circle-outline</v-icon>
              <div class="display-1 text-uppercase my-3">
                Aceptado
              </div>
              <div class="scale-8">
                <div class="font-weight-bold">Código de Transacción</div>
                <div class="display-2">{{ receipt.shortTx }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="summary && failed">
          <v-col align-self="center" align="center" class="py-15">
            <div>
              <v-icon size="128" color="red">mdi-stop-circle-outline</v-icon>
              <div class="display-1 text-uppercase my-3">
                Rechazado
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-container>
              <v-row v-if="summary" style="opacity: 0.7" dense>
                <v-col align-self="center" align="center">
                  <div class="scale-7">
                    <div class="font-weight-bold">Monto</div>
                    <div class="display-2">{{ amount | numFormat('0,000.[00]') }} <span class="caption">Puntos</span></div>
                  </div>
                </v-col>
                <v-col align-self="center" align="center">
                  <div class="scale-7">
                    <div class="font-weight-bold">Propina</div>
                    <div class="display-2">{{ tips | numFormat('0,000.[00]') }} <span class="caption">Puntos</span></div>
                  </div>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col align-self="center" align="center">
                  <div class="font-weight-bold">Total</div>
                  <div class="display-2">{{ total | numFormat('0,000.[00]') }} <span class="caption">Puntos</span></div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-btn v-if="!summary" @click="generatePayment" dark x-large class="mx-2" elevation="0" color="customColor">Generar Pago</v-btn>
            <div v-if="failed">
              <v-btn @click="retry" dark class="mx-2 my-10" elevation="0" large color="customColor">Reintentar</v-btn>
            </div>
            <v-btn v-if="summary" @click="close" dark class="mx-2 my-10" elevation="0" color="customColor">Volver</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.normal.alt.svg')" max-width="200px" width="auto" max-height="40px" contain/>
            <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.dark.alt.svg')" max-width="200px" width="auto" max-height="40px" contain/>
          </v-col>
        </v-row>
      </v-container>
      <v-btn fixed bottom right small icon @click="setThemeMode(!$vuetify.theme.dark)"><v-icon>mdi-brightness-6</v-icon></v-btn>
    </div>
</template>

<script>
import './_PaymentView.scss'
import qr from '@/mixins/qr'
import moment from 'moment/moment'
export default {
  name: 'PaymentView',
  data () {
    return {
      amount: null,
      tips: 0,
      qr: false,
      summary: false,
      success: false,
      failed: false,
      receipt: false
    }
  },
  mixins: [qr],
  computed: {
    total () {
      const total = parseFloat(this.amount) + parseFloat(this.tips)
      if (isNaN(total)) {
        return 0
      }
      return total
    }
  },
  methods: {
    retry () {
      this.failed = false
      this.qr = false
      this.generatePayment()
    },
    close () {
      this.amount = null
      this.tips = 0
      this.qr = false
      this.summary = false
      this.success = false
      this.failed = false
    },
    setThemeMode (style) {
      this.$vuetify.theme.dark = style
    },
    async generatePayment () {
      this.summary = true
      this.$store.commit('activeLoading')
      const payment = (await this.axios.post('/thirdparty/passpoint/sales', {
        amount: this.amount,
        tips: this.tips,
        session: this.$store.state.session,
        merchant: {
          type: this.$store.state.merchant.type,
          code: this.$store.state.merchant.code,
          name: this.$store.state.merchant.name,
          description: this.$store.state.merchant.description
        },
        employee: this.$store.state.useriD
      })).data
      this.qr = await this.generateQuickResponseCode(payment.code)
      this.$store.commit('closeLoading')
      try {
        let wait = await this.axios.get('/identification/signatures/' + payment.hash + '/wait')
        if (!this.summary) return
        if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
          wait = await this.axios.get('/identification/signatures/' + payment.hash + '/wait')
          if (!this.summary) return
        }
        if (wait.data.state === 'success') {
          const receipt = (await this.axios.post('/thirdparty/passpoint/sales/processing', {
            hash: payment.hash
          })).data
          if (receipt.state === 'paid') {
            this.success = true
            this.receipt = receipt
            this.$store.commit('addPaymentRecord',
              {
                date: moment().unix(),
                total: this.total,
                amount: this.amount,
                tips: this.tips,
                shortTx: receipt.shortTx,
                iD: wait.data.data.iD
              })
            return
          }
          this.failed = true
        }
      } catch (e) {
        this.failed = true
      } finally {
        this.$store.commit('closeLoading')
      }
    }
  }
}
</script>
