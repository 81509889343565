<template>
    <div class="ReportEventView SectionControl">
      <v-container class="fill-height pa-5">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Eventos</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Reporte de asistencia</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            {{ report.eventName }} / {{ report.eventDate }} {{ report.eventTime }}
            <v-data-table
              :loading="loading"
              :items-per-page="limit"
              :items="report.customers"
              :headers="report.headers"
              show-group-by
              dense
              :footer-props="{'items-per-page-options': [10, 50 , 100, 500, -1]}"
            >
            <template v-slot:item.created="{ item }">
                {{ getDateFormat(item.created) }}
            </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_ReportEventView.scss'
import moment from 'moment/moment'
export default {
  name: 'ReportEventView',
  data () {
    return {
      limit: 50,
      report: [],
      loading: false
    }
  },
  mounted () {
    this.hash = this.$route.params.event
    this.getReport()
  },
  watch: {
  },
  computed: {
  },
  methods: {
    getStringDateFormat (date) {
      if (date === undefined) {
        return
      }
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    },
    getReport () {
      this.$store.commit('activeLoading')
      // this.$axios.get('/thirdparty/passpoint/events/' + this.hash, {
      this.$axios.post('/thirdparty/passpoint/reports/events/' + this.hash + '/attendees', {
        iD: this.$store.state.useriD,
        session: this.$store.state.session
      }).then(response => {
        const headers = [
          { text: 'Nombre', value: 'name' },
          { text: 'Correo', value: 'email' },
          { text: 'Estado', value: 'state' },
          { text: 'Tipo', value: 'contract' },
          { text: 'Fecha Compra', value: 'created' },
          { text: 'Precio', value: 'price' }
        ]

        this.report = response.data
        this.report.headers = headers
        console.log(this.report)
        this.$store.commit('closeLoading')
      })
    }
  }
}
</script>
