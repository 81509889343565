<template>
    <div class="LoadingView">
      <v-container class="fill-height">
        <v-row>
          <v-col align-self="center" align="center">
              <v-img :src="require('@/assets/logos/logo.icon.svg')" contain width="300px" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_LoadingView.scss'
export default {
  name: 'LoadingView',
  mounted () {
    this.$store.commit('activeLoading')
    if (this.country) {
      this.route(this.country)
    }
  },
  beforeDestroy () {
    this.$store.commit('closeLoading')
  },
  watch: {
    country: function (value) {
      this.route(value)
    }
  },
  computed: {
    country () {
      return this.$store.state.country
    }
  },
  methods: {
    route (country) {
      if (country === 'CL') {
        this.$router.push({ name: 'homeChile' })
        return
      }
      if (country === 'PE') {
        this.$router.push({ name: 'homePeru' })
        return
      }
      this.$router.push({ name: 'landing' })
    }
  }
}
</script>
