<template>
    <div class="SoporteView">
      <v-container class="fill-height" fluid>
        <v-btn
            v-if="showScrollButton"
            fab
            bottom
            right
            fixed
            color="white"
            @click="scrollToTop"
          >
          <v-icon color="primary">
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              SOPORTE
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">Soporte Passpoint</div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Cómo accedo a PassPoint?
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Es muy fácil acceder a PassPoint y puedes hacerlo de dos formas: puedes descargar directamente la aplicación en la tienda Android o iOS según tu dispositivo o también puedes inscribirte para una membresía en el sitio web de PassPoint para descargar la aplicación y agregar tarjetas desde el primer momento.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint es extremadamente intuitivo de usar, básicamente se siente como una billetera donde alojas y seleccionas tus membresías (SmartTickets, Gift Cards o Promociones), una vez seleccionada escaneas el QR que se te presente en tienda (virtual o presencial) para hacer valer beneficios o usar puntos.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Los puntos se obtienen por compra directa en la web, en comercios asociados o también como parte de promociones y bonificaciones preparados por el comercio adherido.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                En qué consisten los beneficios PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint puede ofrecer una gran variedad de beneficios que son creados por el comercio asociado en forma exclusiva para sus clientes con membresía PassPoint. Estos beneficios se aplican al canje de puntos o a la preferencia de productos y servicios de nuestros afiliados.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Los beneficios pueden ir desde trato preferencial por ejemplo ubicaciones privilegiadas en eventos, preventas preferenciales, avant premiere, descuentos por canje de puntos, bonificaciones por uso de puntos, productos o servicios exclusivos para clientes PassPoint, etc.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Qué es PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint es una plataforma de fidelidad comercial desarrollada sobre la más moderna tecnología Blockchain de cuarta generación. PassPoint entrega seguridad a toda prueba y máxima versatilidad por encima de cualquier plataforma ya que sus puntos NO CADUCAN, son equivalentes a la moneda nacional y se canjean en forma virtualmente instantánea. Así es sencillo entregar más y mejores beneficios a los adherentes.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint cuenta con una Aplicación gratuita para Android e iOS, una plataforma web local con contenidos de comercio adherido como promociones, eventos y espectáculos, descuentos, etc. donde es posible obtener membresías para los distintos operadores. PassPoint cuenta además con su propio Dashboard y herramientas de gestión comercial profesionales dedicadas al comercio afiliado para programar eventos, crear tickets, registrar promociones, usuarios entre otros.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Qué es un evento PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Los productores de eventos o comerciantes asociados pueden crear eventos programados exclusivos o con privilegios para sus clientes PassPoint. La validación presencial de la membresía PassPoint puede ser necesaria para poder ser parte del evento.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                En qué me distingue una membresía PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint entrega una identificación digital única a cada cliente. Para ello la membresía por defecto sólo pide un nombre y correo electrónico al cual hará llegar comunicaciones y ofertas especiales. Esta membresía permite también la adquisición de puntos y el acceso a beneficios exclusivos PassPoint.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Comercios asociados pueden además crear una membresía propia que otorgará aún mas ventajas que el comercio creará exclusivamente para sus miembros.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint permite también la validación digital presencial para entrada a espectáculos y eventos sin contacto y con biometría del dispositivo del usuario.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint permite al usuario liberarse de estrés, pudiendo acceder a beneficios o a un evento simplemente portando su dispositivo móvil y su membresía con puntos previamente cargados o solamente su SmartTicket para validar acceso.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Qué es una membresía PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Una membresía es un identificador único otorgado por un comercio a su cliente que le da acceso a servicios exclusivos. El comercio puede pedir información adicional para la creación de la membresía a fin de otorgar una mejor y más personalizada atención.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Poseer una membresía PassPoint significa que la persona es cliente VIP del comercio y recibirá trato preferente.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Qué es un SmartTicket PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                SmartTickets son entradas inteligentes creadas en la red blockchain de PassPoint, equivalen a un NFT asignadas al usuario. Estas SmartTicket pueden ser directas y redimirse completamente en la validación de acceso al evento, seleccionando la entrada y escaneando el QR de control, o pueden dar derecho a servicios y/o artículos complementarios por lo que no se consumen completamente al acceso y permiten al interior del evento escanear nuevamente otros QR específicos a los adicionales, redimiendo puntos gradualmente a medida que el propietario consume en el evento, liberándose completamente de necesitar mas medios de pago que el SmartTicket en si mismo.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                SmartTicket PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Nunca ha sido más sencillo e inteligente de crear, ofrecer (comercio) y canjear (cliente) tus tickets para eventos y espectáculos. Como productor creas los tickets desde tu dashboard personal y los pones en circulación directamente desde el sitio de PassPoint, en tu sitio, por redes sociales, puedes segmentar y ubicar, ofrecer adicionales con la entrada para redimir puntos al interior del evento. El SmartTicket está asociado a una cuenta PassPoint y es seguro e imposible de duplicar.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Como asistente al evento compra tu SmartTicket con total seguridad, jamás podrá ser falsificado y validará de forma eficaz tu identidad al interior del evento. Según establezca el productor un SmartTicket podría aparejar beneficios adicionales para ti, además no requiere impresión y basta con la validación biométrica de tu dispositivo para poder escanear y validar en los puntos de control.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Universalidad de PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint sirve para todo tipo de comercio porque se transforma en un sistema de débito prepago no bancario en que los puntos no caducan y siempre son equivalentes a la moneda local. Puedes canjear con libertad en todo el comercio asociado y tener seguridad de que los pagos efectuados en puntos son instantáneamente recibidos como pago por el vendedor.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Puntos PassPoint
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                La magia de los puntos PassPoint radica en su facilidad de uso. No caducan y son equivalentes a la moneda local, por lo cual tanto cliente como proveedor se ven inmediatamente liberados de tener que hacer conversiones para verificar; por ejemplo; la conveniencia de un precio. A diferencia de los puntos de fidelidad comunes, el dueño de los puntos PassPoint podrá usarlos en cualquier momento, sin temor o apuro por los puntos que están por vencer, además puede transferirlos entre membresías y de quererlo también puede transferirlos entre usuarios sin pérdida.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Política de Devoluciones
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint expresamente declara que no se realizarán cambios o devoluciones cuando la compra de puntos haya sido correctamente efectuada, recordando además que los puntos adquiridos no caducan y pueden ser ocupados en equivalencia a su monto en dinero en cualquiera de las ofertas y eventos que comercios asociados a PassPoint emitan.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Todos los canjes hechos con puntos PassPoint; ya sea por tickets digitales u otros bienes o servicios; son asociados a la cuenta PassPoint del cliente con máximas medidas de seguridad, por lo anterior el cliente es el responsable de verificar bien su pedido de puntos y de mantener su cuenta y dispositivos a resguardo.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                La adquisición de tickets digitales mediante puntos PassPoint inmediatamente delega toda responsabilidad hacia el productor que emitió tales tickets. Los productores y comercios son responsables de los eventos, productos y/o servicios que ofrezcan y comercialicen a través de la plataforma PassPoint u otros medios a través del canje de puntos PassPoint. Dado los contratos entre PassPoint, productores y comercios, PassPoint se exime de la responsabilidad de devoluciones, la cual recae en el productor o comercio que comercializó el producto independiente de su naturaleza.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Sólo en casos de fuerza mayor, ya sea que un Evento cambie de fecha, horario, lugar del mismo, artistas u otro que alteren la naturaleza del espectáculo PassPoint efectuará devolución de montos pagados sólo a aquellos clientes que efectivamente hayan canjeado puntos por tickets digitales del evento, con al menos 12 horas de anticipación al evento soliciten tal devolución y que hayan sido autorizados y validados por el organizador del evento en cuestión.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Si por fuerza mayor también el evento se suspendiese o cancelase, aquellos clientes que efectivamente hayan canjeado puntos PassPoint por tickets para el evento en cuestión podrán optar a la devolución de los montos trancados en moneda local, por transferencia bancaria directa e individual.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Las condiciones de devolución incluyen descuento del 10% más IVA correspondiente a la comisión de PassPoint, y los montos deben ser liberados por el productor o comercio responsable. En todos los casos mencionados anteriormente la devolución se hará efectiva en un tiempo máximo de treinta (30) días hábiles.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
             </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_SoporteView.scss'

export default {
  name: 'SoporteView',
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
