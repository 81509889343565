<template>
    <div class="EventsView">
      <v-container fluid>
        <v-row>
          <v-col class="pa-0">
            <BannerTextComponent :image="require('@/assets/banners/banner00.png')" height="40vh" />
          </v-col>
        </v-row>
        <v-row>
        <v-col align-self="center" align="center">
          <div align="center" class="titleSection py-15">
            Eventos Destacados
          </div>
        </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <EventsListComponent :events="$store.state.events.filter(item => item.country === $route.meta.country && item.state === 'active')" :max="50" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-0">
            <SingleButtonParallaxComponent message="Compra tus puntos Passpoint" button="Comprar Puntos" link="/" :background="require('@/assets/backgrounds/07.png')" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_EventsView.scss'
import BannerTextComponent from '@/components/BannerTextComponent/BannerTextComponent.vue'
import EventsListComponent from '@/components/EventsListComponent/EventsListComponent.vue'
import SingleButtonParallaxComponent from '@/components/SingleButtonParallaxComponent/SingleButtonParallaxComponent.vue'

export default {
  name: 'EventsView',
  components: { SingleButtonParallaxComponent, EventsListComponent, BannerTextComponent },
  data () {
    return {
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  }
}
</script>
