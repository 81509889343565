<template>
  <div class="MembershipsView SectionControl">
    <v-container class="fill-height pa-10">
      <v-row>
        <v-col>
          <div class="mx-5 mx-sm-15 subtitleControlSection">Cliente</div>
          <div class="mx-5 mx-sm-15 titleControlSection">Membresías</div>
        </v-col>
      </v-row>
      <v-row class="section">
        <v-col align-self="center" align="center" cols="12" lg="6">
          <div class="cards">
            <carousel :perPage="1" v-if="cards.length > 0" @pageChange="cardChange">
              <slide v-for="(card, key) in cards" :index="key" v-bind:key="key" class="pa-5">
                <v-img :src="getLayout(card)" width="70vw" max-width="500px" class="cardLayout">
                  <div class="card-name" v-if="card.client">{{ card.client.name }}</div>
                  <div class="card-member" v-if="card.data">{{ card.data.member }}</div>
                </v-img>
              </slide>
            </carousel>
          </div>
        </v-col>
        <v-col align-self="center" align="center">
          <div style="max-width: 800px" v-bind:key="index">
            <CardBalanceComponent v-if="currentCard" :card="currentCard" :type="types[currentCard.type]" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <v-text-field class="ma-2 pa-5" outlined rounded label="iD" :value="$store.state.useriD" readonly append-icon="mdi-content-copy" @click:append="copy" />
        </v-col>
      </v-row>
    </v-container>
    <div v-if="false" class="MainView">
      <v-container>
        <v-row>
          <v-col>
            <div align="center" class="titleSection py-10">
              Tus Membresías
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col v-for="(card, index) in cards" :key="index" cols="12" align-self="center" align="center">
            <div style="max-width: 800px">
              <CardBalanceComponent :card="card" :type="types[card.type]" />
            </div>
          </v-col>
        </v-row>
        <v-row v-if="loading">
          <v-col align-self="center" align="center">
            <v-progress-circular width="20" color="customColor" size="300" indeterminate class="my-10 py-10">
              <v-img :src="require('@/assets/logos/logo.icon.svg')" contain width="100px" />
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-snackbar v-model="copySnackbar" top centered>
      Copiado
    </v-snackbar>
  </div>
</template>

<script>
import './_MembershipsView.scss'
import CardBalanceComponent from '@/components/CardBalanceComponent/CardBalanceComponent.vue'
import moment from 'moment/moment'
export default {
  name: 'MembershipsView',
  components: { CardBalanceComponent },
  data () {
    return {
      copySnackbar: false,
      currentCard: false,
      index: 0,
      cards: [],
      types: false,
      loading: false
    }
  },
  mounted () {
    this.loadCards()
  },
  watch: {
    useriD: function (value) {
      this.loadCards()
    }
  },
  computed: {
    useriD () {
      return this.$store.state.useriD
    }
  },
  methods: {
    copy () {
      this.copySnackbar = true
      navigator.clipboard.writeText(this.$store.state.useriD)
    },
    getLayout (card) {
      let layout = this.types[card.type].layout
      if (card.data.category) {
        if (card.data.category.expire > moment().unix() && card.data.category.level > 0) {
          layout = layout.replace('.png', '.' + card.data.category.level + '.png')
        }
      }
      return layout
    },
    getDateFormat (timestamp) {
      return moment.unix(timestamp).format('DD/MM/YYYY')
    },
    cardChange (index) {
      this.index = index
      this.currentCard = this.cards[index]
    },
    async loadCards () {
      this.loading = true
      this.$store.commit('activeLoading')
      const iD = this.$store.state.useriD
      const cards = (await this.$axios.get('/thirdparty/passpoint/cards/' + iD + '/list')).data
      if (cards.length > 0) {
        this.types = (await this.$axios.get('/sandbox')).data
      }
      this.cards = cards.reverse()
      this.loading = false
      this.$store.commit('closeLoading')
      this.currentCard = this.cards[0]
      this.$forceUpdate()
    }
  }
}
</script>
