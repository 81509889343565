import Vue from 'vue'
import Vuex from 'vuex'
import { utils } from 'ethers'
import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // iD: 'iD4G<0187a5c98537bd8ad4e0be061dd80f2df2d5bb8f531a1490d533790b280dc60a6497f506d3c40cc9a899d6d5bc4816d3',
    iD: false,
    // useriD: 'iD4G<0187a5c98537bd8ad4e0be061dd80f2df2d5bb8f531a1490d533790b280dc60a6497f506d3c40cc9a899d6d5bc4816d3',
    useriD: false,
    session: false,
    expire: false,
    token: false,
    currentPayment: false,
    logs: {
      access: [],
      payment: [],
      recharge: []
    },
    countries: {
      CL: 'Chile',
      PE: 'Peru'
    },
    merchant: false,
    producer: false,
    balance: false,
    loading: false,
    component: false,
    country: false,
    countryName: false,
    saveSnackbar: false,
    contracts: [
      {
        code: 'p4g-c-99',
        name: 'P4GC099',
        unit: 'PassPoint Points',
        decimals: 0,
        country: 'CL',
        type: 99,
        iD: 'iD4G<8a9b15c5cc345c37ef3227d733ea360491cb922f1bf8537ffbc74e65b738c41e696c41c222a991e961bf796f939262a0'
      },
      {
        code: 'p4g-pen',
        name: 'P4G PEN',
        unit: 'Points',
        decimals: 2,
        country: 'PE',
        type: 101,
        iD: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        code: 'p4g-clp',
        name: 'P4G CLP',
        unit: 'Points',
        decimals: 0,
        country: 'CL',
        type: 101,
        iD: 'iD4G<9cc549ef79b880c23b3d53a278915bb6cb9fc95c5a4f2499594fd17f2dad8b9174f3f48d188fe915469d551fb6ec7a61'
      },
      {
        code: 'p4g-c-103',
        name: 'P4GC103',
        unit: 'Berlin Points',
        decimals: 0,
        country: 'CL',
        type: 103,
        iD: 'iD4G<14f13d05bca2c6abe71d47500300f7dccd4010d8bebc24475663ca717e4b584bdd194f4f2966fec03440c987b33e594c'
      },
      {
        code: 'p4g-c-104',
        name: 'P4GC104',
        unit: 'Dominga Bistro Points',
        type: 104,
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<c0272f9b2b110c863d41881b420195086628be847f3b509af5e6b189315fea3d4da6a60bfef4849a1548cf86365be3c4'
      },
      {
        code: 'p4g-c-105',
        name: 'P4GC105',
        unit: 'Neroli Points',
        type: 105,
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<8c9b306e4b42aaaecda2175fd474f91c706e107f4efb9ba0f7824dba37d249387906269927ad11360e598593862d17a2'
      },
      {
        code: 'p4g-c-106',
        name: 'P4GC106',
        unit: 'Patio Points',
        type: 106,
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<936474f9535e718f01118edd85ef5dee6f94f5788d6723dcf790e4bdb0d340ffdf88165307c75e7e46a70e246a7d0e3d'
      },
      {
        code: 'p4g-c-107',
        name: 'P4GC107',
        unit: 'Phoenix Points',
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<c6690f030f22eb89223cdadb2f2c36431b312340908a076c08f9d5f64a7153370c1eb6ddbf614dab0e8ba9d99805581e'
      },
      {
        code: 'p4g-c-108',
        name: 'P4GC108',
        unit: 'Padel Points',
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<71abc5cac10d8cd6a2e2e192becece79c02ef8be18957ccda2acfe0f6c8da0106083050dd7c152098cdaa1eb61155761'
      },
      {
        code: 'p4g-c-109',
        name: 'P4GC109',
        unit: 'BeneFit Points',
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<d4d0ac1a1540148b4e45ecb2fd528f1bd244aec5498c8b76e534cef516a8145b9fde834a4e56f4df51ada8822a8d9d53'
      },
      {
        code: 'p4g-c-110',
        name: 'P4GC110',
        unit: 'FitHouse Points',
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<37e63b7e723aa5a8f8d8e16c1560b0f31f3b8e92f4baeae002bb5bd31e1b2ee317763ca3355e5eb85144dda5157f7e3c'
      },
      {
        code: 'p4g-c-111',
        name: 'P4GC111',
        unit: 'PasspointUniversity Points',
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<fc5f14d6877aa23bb601ae7b880184e7149cbd29609988681aea931f41cc162c29ace8faafa703284762563e62ab37b5'
      },
      {
        code: 'p4g-c-112',
        name: 'P4GC112',
        unit: 'Redbull Points',
        decimals: 0,
        country: 'CL',
        iD: 'iD4G<41b098ee4b610df20381c9c7875265f67b30ed38fd16c5f79fff527974ecf4ca2f560ba822b39f44aba2dcc1bb86529b'
      }
    ],
    memberships: [
      {
        code: 'gf0001',
        banner: require('@/assets/memberships/gift-chile_01.png'),
        name: 'Nerolí Studio 21',
        description: '',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/m/neroli',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        code: 'gf0002',
        banner: require('@/assets/memberships/gift-chile_02.png'),
        name: 'Dominga Bistró',
        description: '',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/m/dominga',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        code: 'gf0003',
        banner: require('@/assets/memberships/gift-chile_03.png'),
        name: 'Patio Bar',
        description: '',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/m/patiobar',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        code: 'gf0004',
        banner: require('@/assets/memberships/IMG_6554.jpg'),
        name: 'Berlin Club',
        description: '',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/m/berlin-club',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        code: 'gf0005',
        banner: require('@/assets/memberships/gift-chile_04.png'),
        name: 'The Club',
        description: '',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/m/theclub',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        code: 'gf0006',
        banner: require('@/assets/memberships/gift-chile_05.png'),
        name: 'Bar 264',
        description: '',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/m/bar264',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      }
    ],
    giftcards: [
      {
        banner: require('@/assets/promos/nerolipromo01.png'),
        name: 'Nerolí Studio 21',
        description: 'Cuatro aplicaciones Criolipolisis',
        points: 100000,
        category: 'belleza',
        tag: 'neroli',
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/mp/nerolione',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/promos/domingapromo02.png'),
        name: 'Dominga Bistró',
        description: 'Gran Fetuccini Di Mare',
        points: 7500,
        category: 'gourmet',
        tag: 'dominga',
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      }
    ],
    shops: [
      {
        banner: require('@/assets/promos/nerolipromo01.png'),
        name: 'Nerolí Studio 21',
        description: 'Cuatro aplicaciones Criolipolisis',
        points: 100000,
        category: 'belleza',
        tag: 'neroli',
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/mp/nerolione',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/promos/neroli-promo02.png'),
        name: 'Nerolí Studio 21',
        description: 'Microneedling + Vitamina C',
        points: 55000,
        category: 'belleza',
        tag: 'neroli',
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/mp/nerolione',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/promos/neroli-promo03.png'),
        name: 'Nerolí Studio 21',
        description: 'Hidra Facial',
        points: 35000,
        category: 'belleza',
        tag: 'neroli',
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/mp/nerolione',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/promos/neroli-promo04.png'),
        name: 'Nerolí Studio 21',
        description: 'Radio Frecuencia',
        points: 35000,
        category: 'belleza',
        tag: 'neroli',
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/mp/nerolione',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/promos/nerolipromo07.png'),
        name: 'Nerolí Studio 21',
        description: 'Ultra Cavitacion',
        points: 55000,
        category: 'belleza',
        tag: 'neroli',
        city: [],
        priority: false,
        country: 'CL',
        url: '/chile/mp/nerolione',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/promos/promo_patio.jpg'),
        name: 'Patio Bar',
        description: '2 x Ramazzoti, Mistral & Mojitos hasta las 22 h.',
        points: 6000,
        category: 'gourmet',
        tag: 'patio',
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/promos/domingapromo01.png'),
        name: 'Dominga Bistró',
        description: 'Pizza Camarones al Ajillo',
        points: 12000,
        category: 'gourmet',
        tag: 'dominga',
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/promos/domingapromo02.png'),
        name: 'Dominga Bistró',
        description: 'Gran Fetuccini Di Mare',
        points: 7500,
        category: 'gourmet',
        tag: 'dominga',
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      }
    ],
    sports: [],
    travel: [
      {
        banner: require('@/assets/travel/dreams.png'),
        name: 'Hotel Dreams',
        description: 'Descripción',
        points: [],
        category: 'alojamientos',
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/travel/ditor.png'),
        name: 'Hotel Di Torlaschi',
        description: '',
        points: [],
        category: 'alojamientos',
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/travel/ps.png'),
        name: 'Hotel Puertas del Sur',
        description: '',
        points: [],
        category: 'alojamientos',
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/travel/villa.png'),
        name: 'Hotel Villa del Río',
        description: '',
        points: [],
        category: 'alojamientos',
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/travel/montepiedra.png'),
        name: 'Hotelera Monte Piedra',
        description: '',
        points: [],
        category: 'alojamientos',
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/travel/melillanca.jpg'),
        name: 'Hotel Melillanca',
        description: '',
        points: [],
        category: 'alojamientos',
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      },
      {
        banner: require('@/assets/travel/coique.png'),
        name: 'Bahía Coique',
        description: '',
        points: [],
        category: 'alojamientos',
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        url: '',
        owner: 'iD4G<24e527286607d70f8041c96790da9a43fdeb0b467b6db4fd341e1dcb7cf0e20c9879e8ea99c21c12b0a9f7647ad0a07b'
      }
    ],
    events: [
      {
        code: 'ev0000',
        banner: require('@/assets/events/12_10.png'),
        name: 'Belen Escobar',
        date: new Date('2024-10-12T00:00:00'),
        state: 'active',
        points: 0,
        place: 'Berlin',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        owner: 'hash_del_owner_1'
      },
      {
        code: 'ev0001',
        banner: require('@/assets/events/17_10.png'),
        name: 'Fiesta Universitaria',
        date: new Date('2024-10-17T00:00:00'),
        state: 'active',
        points: 0,
        place: 'Berlin',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        owner: 'hash_del_owner_2'
      },
      {
        code: 'ev0002',
        banner: require('@/assets/events/18_10.png'),
        name: 'Villablanca',
        date: new Date('2024-10-18T00:00:00'),
        state: 'active',
        points: 0,
        place: 'Berlin',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        owner: 'hash_del_owner_3'
      },
      {
        code: 'ev0003',
        banner: require('@/assets/events/19_10.png'),
        name: 'Lukilimanjaro',
        date: new Date('2024-10-19T00:00:00'),
        state: 'active',
        points: 0,
        place: 'Berlin',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        owner: 'hash_del_owner_4'
      },
      {
        code: 'ev0004',
        banner: require('@/assets/events/24_10.png'),
        name: 'Flo Veloso',
        date: new Date('2024-10-24T00:00:00'),
        state: 'active',
        points: 0,
        place: 'Berlin',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        owner: 'hash_del_owner_5'
      },
      {
        code: 'ev0005',
        banner: require('@/assets/events/25_10.png'),
        name: 'Fiesta Funk',
        date: new Date('2024-10-25T00:00:00'),
        state: 'active',
        points: 0,
        place: 'Berlin',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        owner: 'hash_del_owner_6'
      },
      {
        code: 'ev0006',
        banner: require('@/assets/events/26_10.png'),
        name: 'Dot + Nax',
        date: new Date('2024-10-26T00:00:00'),
        state: 'active',
        points: 0,
        place: 'Berlin',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        owner: 'hash_del_owner_7'
      },
      {
        code: 'ev0007',
        banner: require('@/assets/events/31_10.png'),
        name: 'Club del Terror',
        date: new Date('2024-10-31T00:00:00'),
        state: 'active',
        points: 0,
        place: 'Berlin',
        category: [],
        tag: [],
        city: [],
        priority: false,
        country: 'CL',
        owner: 'hash_del_owner_8'
      }
    ]
  },
  getters: {},
  mutations: {
    loadData (state, payload) {
      window.axios.get('/thirdparty/passpoint/events/all').then(response => {
        const data = response.data
        state.events = []
        data.forEach(item => {
          state.events.push({
            code: item.hash,
            banner: item.event.primaryImage,
            name: item.event.name,
            date: new Date(item.event.start),
            points: item.event.points,
            place: item.event.place,
            state: item.state,
            category: [],
            tag: [],
            city: [],
            priority: false,
            country: Object.keys(state.countries).find(key => state.countries[key] === item.event.country),
            owner: item.event.iD
          })
        })
        console.log(state.events)
      })
    },
    addAccessRecord (state, payload) {
      state.logs.access.push(payload)
    },
    addPaymentRecord (state, payload) {
      state.logs.payment.push(payload)
    },
    addRechargeRecord (state, payload) {
      state.logs.recharge.push(payload)
    },
    async updateBalance (state, payload) {
      const contracts = state.contracts

      if (!state.country) {
        state.country = (await window.axios.get('https://api.country.is')).data.country
      }
      console.log(state.country)

      const contract = contracts.find(item => item.country === state.country && item.type === 101)
      console.log(contract)
      if (!contract) {
        return
      }
      window.axios.get('/network/addresses/' + state.useriD + '/balance/' + contract.iD).then(response => {
        const data = response.data
        state.balance = utils.formatUnits(data, contract.decimals)
      })
    },
    activeLoading (state) {
      state.loading = true
    },
    closeLoading (state) {
      setTimeout(() => {
        state.loading = false
      }, 1000)
    },
    setComponent (state, payload) {
      state.component = payload.component
    },
    setProducer (state, payload) {
      state.producer = payload.producer
    },
    setCountry (state, payload) {
      state.country = payload.country
      if (state.country === 'CL') {
        state.countryName = 'chile'
      }
      if (state.country === 'PE') {
        state.countryName = 'peru'
      }
    },
    closeComponent (state, payload) {
      state.component = false
    },
    loadSession (state, payload) {
      const data = sessionStorage.getItem('expire')
      if (data) {
        const expire = JSON.parse(data)
        if (expire > moment().unix()) {
          state.iD = JSON.parse(sessionStorage.getItem('iD'))
          state.useriD = JSON.parse(sessionStorage.getItem('useriD'))
          state.expire = JSON.parse(sessionStorage.getItem('expire'))
          state.session = JSON.parse(sessionStorage.getItem('session'))
          state.currentPayment = JSON.parse(sessionStorage.getItem('currentPayment'))
        }
      }
    },
    saveSession (state, payload) {
      state.expire = moment().add(48, 'hours').unix()
      sessionStorage.setItem('iD', JSON.stringify(state.iD))
      sessionStorage.setItem('useriD', JSON.stringify(state.useriD))
      sessionStorage.setItem('expire', JSON.stringify(state.expire))
      sessionStorage.setItem('session', JSON.stringify(state.session))
      sessionStorage.setItem('currentPayment', JSON.stringify(state.currentPayment))
    },
    closeSession (state, payload) {
      sessionStorage.removeItem('iD')
      sessionStorage.removeItem('useriD')
      sessionStorage.removeItem('expire')
      sessionStorage.removeItem('session')
      sessionStorage.removeItem('currentPayment')
      state.iD = false
      state.useriD = false
      state.session = false
      state.balance = false
      payload.router.push('/')
    }
  },
  actions: {},
  modules: {}
})
