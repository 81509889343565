<template>
    <div class="CardComponent">
      <div>
        <v-img :src="image" max-width="360px" contain />
      </div>
        <!--div class="amount">Costo: {{ amount }} puntos</div>
        <div class="revenue" :style="{backgroundColor: bgColor}">Ganancia {{ revenue }} puntos</div-->
      <div class="name">
        {{ name }}
      </div>
    </div>
</template>

<script>
import './_CardComponent.scss'
export default {
  name: 'CardComponent',
  props: {
    image: String,
    name: String,
    amount: String,
    revenue: String,
    bgColor: String('#1e9dd9')
  }
}
</script>
