<template>
    <div class="EditProfileView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Cliente</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Perfil.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15">
            <div class="form ml-1">
              <v-text-field v-model="profile.name" class="my-3" label="Nombre" outlined rounded />
              <v-text-field readonly v-model="profile.email" class="my-3" label="Correo Electrónico" type="email" outlined rounded />
              <v-text-field v-model="profile.dni" class="my-3" label="DNI" outlined rounded />
              <v-select v-model="profile.gender" :items="gender" item-text="name" item-value="value" class="my-3" label="Género" outlined rounded />
              <v-text-field v-model="profile.birthdate" class="my-3" label="Fecha de Nacimiento" type="date" outlined rounded />
              <v-btn @click="save" elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark>Actualizar</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_EditProfileView.scss'
import auth from '@/mixins/auth'
export default {
  name: 'EditProfileView',
  data () {
    return {
      profile: false,
      gender: [
        { name: 'Femenino', value: 'female' },
        { name: 'Masculino', value: 'male' },
        { name: 'No Especifica', value: 'none' }
      ]
    }
  },
  mounted () {
    this.loadProfile()
  },
  mixins: [auth],
  methods: {
    save () {
      this.$store.commit('activeLoading')
      if (!this.verifyAuth()) return
      this.$axios.put('/thirdparty/passpoint/cards/' + this.$store.state.useriD + '/data', {
        session: this.$store.state.session,
        client: this.profile
      }).then(response => {
        this.$store.state.saveSnackbar = true
        this.$store.commit('closeLoading')
      })
    },
    loadProfile () {
      this.$store.commit('activeLoading')
      if (!this.verifyAuth()) return
      this.$axios.get('/thirdparty/passpoint/cards/' + this.$store.state.useriD + '/data').then(response => {
        this.profile = response.data.client
        this.$store.commit('closeLoading')
      })
    }
  }
}
</script>
