<template>
    <div class="MerchantControlView">
      <HeaderControlComponent />
      <router-view />
      <FooterControlComponent />
    </div>
</template>

<script>
import HeaderControlComponent from '@/components/HeaderControlComponent/HeaderControlComponent.vue'
import FooterControlComponent from '@/components/FooterControlComponent/FooterControlComponent.vue'

export default {
  name: 'MerchantControlView',
  components: { FooterControlComponent, HeaderControlComponent }
}
</script>
