import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'

const firebaseConfig = {
  apiKey: 'AIzaSyADBWz5y_jRr_IqpkqKHEypKc4m7G-qb3Y',
  authDomain: 'fourgoodnetwork.firebaseapp.com',
  projectId: 'fourgoodnetwork',
  storageBucket: 'fourgoodnetwork.appspot.com',
  messagingSenderId: '257571429478',
  appId: '1:257571429478:web:6c0749db4ebf1eb310e1a7',
  measurementId: 'G-93SZ137TM4'
}

const firebaseApp = initializeApp(firebaseConfig)
const analytics = getAnalytics(firebaseApp)
const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)
const storage = getStorage(firebaseApp, 'gs://passpoint')

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider('6LcjQ3MoAAAAABBHVWZ9TFTsQwHDhP7jUUhE61u_'),
  isTokenAutoRefreshEnabled: true
})

export { firestore, auth, storage, analytics, appCheck }
