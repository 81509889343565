import QRCodeStyling from 'qr-code-styling'

export default {
  methods: {
    async generateQuickResponseCode (code) {
      const qrcode = new QRCodeStyling({
        width: 300,
        height: 300,
        margin: 4,
        data: code,
        image: require('@/assets/logos/logo.icon.svg'),
        dotsOptions: {
          color: '#0E2A5A',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#0E2A5A'
        },
        backgroundOptions: {
          color: '#FFFFFF'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })
      const blob = await qrcode.getRawData('png')
      return URL.createObjectURL(blob)
    }
  }
}
