<template>
    <div class="EventView">
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15">
              Evento
            </div>
          </v-col>
        </v-row>
        <v-row v-if="!event">
          <v-col align-self="center" align="center">
            <v-progress-circular width="20" color="customColor" size="300" indeterminate>
              <v-img :src="require('@/assets/logos/logo.icon.svg')" contain width="100px" />
            </v-progress-circular>
          </v-col>
        </v-row>
        <v-row v-if="event">
          <v-col align-self="center" align="center" class="px-0">
            <div class="eventBox">
              <div class="banner">
                <v-img :src="event.banner" max-width="500px" />
              </div>
              <div class="content">
                <div class="up">
                  <div align="right">
                    <v-btn outlined dark x-small>Compartir</v-btn>
                  </div>
                  <div class="firstLine">
                    <div class="name">
                      {{ event.name }}
                    </div>
                    <div class="date">
                      <div class="day">{{ getDay(event.date) }}</div>
                      <div class="month">{{ getMonth(event.date) }}</div>
                    </div>
                  </div>
                  <div class="secondLine">
                    <div align="left">
                      <div class="caption">Lugar</div>
                      <v-icon color="white">mdi-map</v-icon> {{ event.place }}
                    </div>
                    <div align="left" v-if="type">
                      <div class="caption">Valor</div>
                      <v-icon color="white">mdi-ticket</v-icon> {{ type.amount }} <span class="caption">Puntos</span>
                    </div>
                  </div>
                </div>
                <div class="down">
                  <div>
                    <flip-countdown :deadline="event.date.toString()" countdownSize="25px" labelSize="10px" :labels="labels"></flip-countdown>
                  </div>
                  <div class="caption" v-if="true">
                    {{ getFormatDate(event.date) }}
                  </div>
                  <div class="floatLabel" v-if="type">ticket</div>
                  <div class="my-2 category" v-if="type">
                    <span class="mx-10">{{ type.name }}</span>
                    <v-btn elevation="0" color="customColor" outlined dark small tile @click="editCategory = !editCategory">Cambiar</v-btn>
                  </div>
                  <v-btn elevation="0" color="customColor buttonBuy" dark class="py-5 mt-5 no-invert" block tile v-if="type" @click="buyTicket">
                    <div class="amountInButton">{{ type.amount }} <span class="caption">Puntos</span></div>
                    <div>Canjear</div>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" class="fill-height pa-15">
            <div class="font-weight-bold">Descripción</div>
            <div>{{ item.event.description }} </div>
          </v-col>
        </v-row>

        <v-row>
            <v-col align-self="center" align="center" class="fill-height pa-15">
              <v-divider></v-divider>
            </v-col>
        </v-row>

        <v-row>
          <v-col align-self="center" align="center" class="px-0">
            <div class="font-weight-bold mt-5">Inicio del Evento</div>
            <div>
              <div>{{ getFormatDate(item.event.start) }}</div>
            </div>
            <div class="font-weight-bold mt-5">Termino del Evento</div>
            <div>
              <div>{{ getFormatDate(item.event.end) }}</div>
            </div>
            <!--div class="font-weight-bold mt-5">Horario de Apertura</div>
            <div>
              <div>{{ getFormatDate(item.event.open) }}</div>
            </div-->
          </v-col>
        </v-row>
        <v-col align-self="center" align="center" class="fill-height pa-15">
            <v-divider></v-divider>
        </v-col>
        <v-row v-if="item">
          <v-col align-self="center" align="center">
            <v-container class="fill-height pa-10">
              <v-row>
                <v-col align-self="center" align="center" class="px-0">
                  <div class="font-weight-bold mt-5">Ubicación</div>
                  <div class="my-5 mapEvent" v-if="item.event.addressData">
                    <GmapMap
                      :center="{ lat: item.event.addressData.latitude, lng: item.event.addressData.longitude}"
                      :zoom="17"
                      style="max-width: 1000px; width: 80vw; height: 300px"
                      v-bind:options="mapStyle"
                    >
                      <GmapMarker
                        class="objectMarker"
                        :position="google && new google.maps.LatLng(item.event.addressData.latitude, item.event.addressData.longitude)"
                      />
                    </GmapMap>
                  </div>
                  <div>
                    <div>{{ item.event.place }}</div>
                    <div>{{ item.event.address }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row v-if="event">
          <v-col align-self="center" align="center">
            <div class="caption my-15">
              <div>Evento realizado por</div>
              <div class="font-weight-bold">{{ event.place }}</div>
              <!--div>XX.XXX.XXX</div>
              <div>XXXXXXXXXXX, XXXXXXX, Chile</div-->
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" class="fill-height pa-15">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-10 mx-sm-15 disclamer">
            Conforme a la legislación vigente tanto la producción como la organización del evento son responsabilidad única del Productor y/u Organizador del mismo.
            Todos los contenidos ingresados (declaraciones, comentarios, textos, otros) son responsabilidad exclusiva del  Productor y/u Organizador del evento.
            PassPoint no asume responsabilidad alguna por declaraciones y contenidos emitido por Productores y Organizadores de eventos.
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-10 mx-sm-15 disclamer">
            Importante: PassPoint es un servicio de promoción y venta de entradas en línea.
            Al usar los servicios de PassPoint usted acepta los Términos y Condiciones de uso establecidos para la aplicación y su portal.
            PassPoint no asume responsabilidad alguna respecto a la calidad o nivel de satisfacción de los eventos que se publiquen.
          </v-col>
        </v-row>
      </v-container>

      <v-dialog fullscreen v-model="insufficientBalance" content-class="EventView">
        <div class="categoryDialog">
          <v-container class="fill-height" fluid>
            <v-row>
              <v-col align-self="center" align="center">
                <v-card max-width="1000px" color="#ffffff88" class="pa-5" elevation="15">
                  <v-container>
                    <v-row>
                      <div class="titleSection mb-5">
                        Saldo Insuficiente
                      </div>
                    </v-row>
                    <v-row>
                      <v-col align-self="center" align="center" class="py-5">
                        Necesitas {{ type.amount - $store.state.balance }} puntos para completar este canje.
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col align-self="center" align="center" class="py-5">
                        <v-btn elevation="0" color="customColor" dark class="px-5 py-5" x-small :to="buyPointsPath()">Comprar Puntos</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                  <div class="mt-15">
                      <v-btn elevation="0" color="customColor" outlined dark class="px-5 py-4" x-small @click="closeBuyView">Cerrar</v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-dialog>

      <v-dialog fullscreen v-model="buyTicketView" content-class="EventView">
        <div class="categoryDialog">
          <v-container class="fill-height" fluid>
            <v-row>
              <v-col align-self="center" align="center">
                <v-card max-width="1000px" color="#ffffff88" class="pa-5" elevation="15">
                  <v-container>
                    <v-row>
                      <div class="titleSection mb-5">
                          Canje de Ticket
                      </div>
                    </v-row>
                    <v-row v-if="(buyTicketView && !qr)">
                      <v-col align-self="center" align="center" class="py-5">
                        Estamos generando tu ticket......
                      </v-col>
                    </v-row>
                    <v-row v-if="(buyTicketView && qr && clientResponse=='')">
                      <v-col align-self="center" align="center" class="py-5">
                        <div v-if="($device.mobile)" align="center" style="max-width: 300px;">
                          Confirma con tu aplicación PassPoint para completar tu canje
                        </div>
                        <div v-if="(!$device.mobile)" align="center" style="max-width: 300px;">
                          Para completar tu canje, escanea el código QR con tu aplicacion PassPoint.
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="(buyTicketView && clientResponse=='')">
                      <v-col align-self="center" align="center" class="py-15">
                        <v-img class="qr" v-if="(!$device.mobile && qr)" :src="qr" width="250px" height="250px" max-width="250px" max-height="250px" aspect-ratio="1" style="border-radius: 16px" contain />
                        <v-btn v-if="(qr && $device.mobile)" elevation="0" color="customColor" @click="openApp" class="my-5" dark rounded>Canjear</v-btn>
                      </v-col>
                    </v-row>
                    <v-row v-if="(clientResponse==='success')">
                      <v-col align-self="center" align="center" class="py-15">
                        <div>
                          <v-icon size="128" color="success">mdi-check-circle-outline</v-icon>
                          <div class="display-1 text-uppercase my-3">
                            Gracias por tu compra.
                          </div>
                          <div class="mensaje text-uppercase my-3">
                            En unos minutos verás tu ticket en tu aplicación.
                          </div>
                          <div class="scale-8">
                            <div class="font-weight-bold">Código de Transacción</div>
                            <div class="codigo_tx">{{ redeem.hash }}</div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row v-if="(clientResponse==='reject')">
                      <v-col align-self="center" align="center" class="py-15">
                        <div>
                          <v-icon size="128" color="red">mdi-stop-circle-outline</v-icon>
                          <div class="display-1 text-uppercase my-3">
                            Rechazado
                          </div>
                        </div>
                      </v-col>
                    </v-row>

                    <div class="mt-10">
                      <v-btn elevation="0" color="black" dark class="px-5 py-4" x-small @click="closeBuyView">Cerrar</v-btn>
                    </div>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-dialog>

      <v-dialog fullscreen v-model="editCategory" content-class="EventView">
        <div class="categoryDialog">
          <v-container class="fill-height" fluid>
            <v-row>
              <v-col align-self="center" align="center">
                <v-card max-width="1000px" color="#ffffff88" class="pa-5" elevation="15">
                  <v-container>
                    <v-row>
                      <v-col v-if="false">
                        <div class="titleSection mb-5">
                          Asiento
                        </div>
                        <div class="seats">
                          <div class="stage">
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                            <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="trasparent" size="20" class="mx-1">
                            </v-avatar>
                            <v-avatar color="trasparent" size="20" class="mx-1">
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                          <div>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="trasparent" size="20" class="mx-1">
                            </v-avatar>
                            <v-avatar color="trasparent" size="20" class="mx-1">
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                            <v-avatar color="grey" size="20" class="mx-1">
                              <v-icon small>mdi-account</v-icon>
                            </v-avatar>
                          </div>
                        </div>
                      </v-col>
                      <v-col>
                        <div class="titleSection mb-5">
                          Opciones
                        </div>
                        <div class="option" v-for="(option, index) in options" :key="index">
                          <div>
                            <v-avatar :color="option.color" size="20" class="mx-1">
                              <v-icon small color="white">mdi-account</v-icon>
                            </v-avatar>
                            {{ option.name }}
                          </div>
                          <div>
                            <span>{{ option.amount }} <span class="caption">Puntos</span></span>
                            <v-btn class="mx-3" elevation="0" x-small dark tile color="customColor" @click="setOption(option)">Seleccionar</v-btn>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                  <div class="mt-15">
                    <v-btn elevation="0" color="black" dark class="px-5 py-4" x-small @click="editCategory = false">Cerrar</v-btn>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-dialog>
    </div>
</template>

<script>
import './_EventView.scss'
import qr from '@/mixins/qr'
import moment from 'moment'
import FlipCountdown from 'vue2-flip-countdown'
import { gmapApi } from 'vue2-google-maps'

export default {
  name: 'EventView',
  components: { FlipCountdown },
  data () {
    return {
      hash: false,
      item: false,
      qr: false,
      clientResponse: '',
      buyTicketPressed: false,
      redeem: false,
      event: false,
      links: {},
      editCategory: false,
      buyTicketView: false,
      insufficientBalance: false,
      mapStyle: {
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#212121'
              }
            ]
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#212121'
              }
            ]
          },
          {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            featureType: 'administrative.country',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e'
              }
            ]
          },
          {
            featureType: 'administrative.land_parcel',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#181818'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#1b1b1b'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#2c2c2c'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#8a8a8a'
              }
            ]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry',
            stylers: [
              {
                color: '#373737'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#3c3c3c'
              }
            ]
          },
          {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry',
            stylers: [
              {
                color: '#4e4e4e'
              }
            ]
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161'
              }
            ]
          },
          {
            featureType: 'transit',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#000000'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#3d3d3d'
              }
            ]
          }
        ]
      },
      type: {
        name: 'General',
        amount: 10000
      },
      options: [
        { color: '#0077b5', name: 'General', amount: 10000 },
        { color: '#FFD700', name: 'VIP', amount: 100000 }
      ],
      labels: {
        days: 'Dias',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos'
      }
    }
  },
  mounted () {
    this.hash = this.$route.params.code
    this.loadingEvent()
  },
  watch: {
    session: function (newValue, oldValue) {
      if (!this.buyTicketPressed) {
        return
      }
      this.buyTicket()
    },
    component: function (newValue, oldValue) {
      if (newValue === 'login') {
        return
      }
      if (this.$store.state.useriD === false) {
        this.buyTicketPressed = false
      }
    }
  },
  computed: {
    google: gmapApi,
    session () {
      return this.$store.state.session
    },
    component () {
      return this.$store.state.component
    }
  },
  mixins: [qr],
  methods: {
    loadingEvent () {
      this.$store.commit('activeLoading')
      this.$axios.get('/thirdparty/passpoint/events/' + this.hash).then(response => {
        this.item = response.data
        this.event = {
          code: this.item.hash,
          banner: this.item.event.primaryImage,
          name: this.item.event.name,
          date: new Date(this.item.event.start),
          points: this.item.event.points,
          place: this.item.event.place,
          category: [],
          tag: [],
          city: [],
          priority: false,
          country: Object.keys(this.$store.state.countries).find(key => this.$store.state.countries[key] === this.item.event.country),
          owner: this.item.event.iD
        }
        this.options = []
        this.item.tickets.forEach(item => {
          this.options.push({ color: item.color, name: item.name, amount: item.price, hash: item.hash })
        })
        this.options.sort((a, b) => (a.price > b.price ? 1 : -1))
        this.type = this.options[0]
        this.$store.commit('closeLoading')
      })
    },
    openApp () {
      if (this.$device.ios || window.navigator.userAgent.indexOf('Mac') > 0 || window.navigator.userAgent.indexOf('OS X') > 0) {
        window.location.href = this.links.ios
        return
      }
      if (this.$device.android || window.navigator.userAgent.indexOf('Android') > 0) {
        window.location.href = this.links.android
        return
      }
      window.location.href = this.links.android
    },
    buyPointsPath () {
      return '/chile/buy-points/' + (parseFloat(this.type.amount) - parseFloat(this.$store.state.balance))
    },
    setOption (option) {
      this.type = option
      this.editCategory = false
      this.clientResponse = ''
    },
    getFormatDate (date) {
      return moment(date).lang('es').format('DD/MM/YYYY HH:MM')
    },
    getDay (date) {
      return moment(date).lang('es').format('DD')
    },
    getMonth (date) {
      return moment(date).lang('es').format('MMMM')
    },
    closeBuyView () {
      this.buyTicketView = false
      this.qr = false
      this.clientResponse = ''
      this.insufficientBalance = false
    },
    async buyTicket () {
      this.buyTicketPressed = true
      if (parseFloat(this.type.amount) > parseFloat(this.$store.state.balance)) {
        console.log('Faltan: ' + (this.type.amount - this.$store.state.balance))
        this.buyTicketPressed = false
        this.insufficientBalance = true
        return
      }

      if (!this.$store.state.useriD || !this.$store.state.session) {
        this.$store.state.component = 'login'
        return
      }

      this.buyTicketView = true
      this.$store.commit('activeLoading')
      const resp = await this.$axios.post('/thirdparty/passpoint/tickets/' + this.hash + '/' + this.type.hash, {
        iD: this.$store.state.iD,
        to: this.$store.state.useriD,
        session: this.$store.state.session
      })
      const redeem = resp.data
      this.redeem = resp.data
      this.links = redeem.links
      this.links.android = this.links.android.replace('com.PassPoint.id4good', 'com.PassPoint.passpoint')
      this.links.ios = this.links.ios.replace('scan', 'passpoint/scan')
      this.links.ios = this.links.ios.replace('id4good.com', 'app.passpoint.global') + '&app_links_app_id=6465455230'
      this.qr = await this.generateQuickResponseCode(redeem.code)
      this.$store.commit('closeLoading')

      try {
        let signResp = await this.$axios.get('/identification/signatures/' + redeem.hash + '/wait')
        if (!this.buyTicketView) return
        if (signResp.data.state === 'scanning' || resp.data.state === 'waiting') {
          signResp = await this.axios.get('/identification/signatures/' + redeem.hash + '/wait')
          if (!this.buyTicketView) return
        }
        console.log(signResp)
        this.clientResponse = signResp.data.state
      } catch (e) {
        this.failed = true
      } finally {
        this.$store.commit('closeLoading')
      }
    }
  }
}
</script>
