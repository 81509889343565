<template>
    <div class="AccommodationComponent">
      <VueSlickCarousel v-bind="settings" >
        <div v-for="(image, key) in items" :key="key" class="px-5 item">
          <div class="itemContainer my-2">
            <v-img :src="image.banner" aspect-ratio="1">
              <div class="overlay"><v-icon @click="$router.push({path: image.url})" class="overlay icon">mdi-arrow-right-circle</v-icon></div>
            </v-img>            <div class="pointsBoxContainer" align="right">
              <div class="pointsContainer">
                {{ image.points }} PUNTOS
              </div>
            </div>
            <div class="pt-0 px-10 pb-10">
              <div class="name">{{ image.name }}</div>
              <div class="message">{{ image.description }}</div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
</template>

<script>
import './_AccommodationComponent.scss'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'AccommodationComponent',
  components: { VueSlickCarousel },
  props: {
    items: Array
  },
  data () {
    return {
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: this.$vuetify.breakpoint.lgAndUp ? 4 : this.$vuetify.breakpoint.mdAndUp ? 3 : this.$vuetify.breakpoint.smAndUp ? 2 : this.$vuetify.breakpoint.xs ? 1 : 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true
      }
    }
  },
  updated () {
    this.settings.slidesToShow = this.$vuetify.breakpoint.lgAndUp ? 4 : this.$vuetify.breakpoint.mdAndUp ? 3 : this.$vuetify.breakpoint.smAndUp ? 2 : this.$vuetify.breakpoint.xs ? 1 : 1
  }
}
</script>
