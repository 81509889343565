<template>
    <div class="FailedView">
      <v-container fluid>
        <v-row>
          <v-col class="pa-0">
            <BannerTextComponent :image="require('@/assets/banners/banner06.png')" height="40vh" message="Comprar Puntos" />
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15">
              Se ha producido un Error
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_FailedView.scss'
import BannerTextComponent from '@/components/BannerTextComponent/BannerTextComponent.vue'
import { kebabCase } from 'eslint-plugin-vue/lib/utils/casing'

export default {
  name: 'FailedView',
  methods: { kebabCase },
  components: { BannerTextComponent },
  data () {
    return {
      step: 1,
      amount: 0,
      amounts: [1000, 5000, 10000, 50000, 100000],
      enabledMethods: [],
      currency: '',
      methods: [
      ]
    }
  },
  mounted () {
    console.log(this.$route)
    if (this.$route.meta.country === 'CL') {
      this.amounts = [1000, 5000, 10000, 50000, 100000]
      this.currency = 'Pesos'
      this.enabledMethods = ['cc', 'tb', 'ef', 'btc', 'eth']
    }
    if (this.$route.meta.country === 'PE') {
      this.amounts = [10, 50, 100, 500, 1000, 5000]
      this.currency = 'Soles'
      this.enabledMethods = ['cc', 'tb', 'ef', 'yape', 'plin', 'btc', 'eth']
    }
  },
  computed: {
    cols () {
      const width = document.documentElement.clientWidth
      const widthVuetify = this.$vuetify.breakpoint.width
      console.log(width)
      console.log(widthVuetify)
      if (width < 700 || widthVuetify < 700) {
        return 12
      }
      if (width < 960 || widthVuetify < 960) {
        return 6
      }
      if (width < 1280 || widthVuetify < 1280) {
        return 4
      }
      if (width < 1920 || widthVuetify < 1920) {
        return 3
      }
      return 2
    }
  }
}
</script>
