<template>
  <div class="CreateClientView SectionControl">
    <v-container class="fill-height pa-10">
      <v-row>
        <v-col>
          <div class="mx-5 mx-sm-15 subtitleControlSection">Eventos</div>
          <div class="mx-5 mx-sm-15 titleControlSection">Crear Cliente.</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mx-5 mx-sm-15">
          <div class="form ml-1">
            <v-text-field class="my-3" label="Nombre" v-model="name" outlined rounded hide-details />
            <v-text-field class="my-3" label="Correo" v-model="email" type="email" outlined rounded hide-details />
            <v-select v-model="country" :items="['Chile', 'Peru']" item label="Pais" outlined rounded hide-details />
            <v-text-field class="my-3" label="DNI" v-model="dni" outlined rounded hide-details />
            <v-text-field class="my-3" label="Cumpleaños" v-model="birthdate" type="date" outlined rounded hide-details />
            <v-text-field class="my-3" label="Teléfono" v-model="phone" type="phone" outlined rounded hide-details />
            <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="create">Crear</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_CreateClientView.scss'

export default {
  name: 'CreateClientView',
  data () {
    return {
      name: '',
      email: '',
      country: '',
      dni: '',
      birthdate: '',
      phone: ''
    }
  },
  methods: {
    async create () {
      this.$store.commit('activeLoading')
      this.axios.post('/thirdparty/passpoint/producers/' + this.$store.state.useriD + '/clients', {
        iD: this.$store.state.iD,
        session: this.$store.state.session,
        client: {
          name: this.name,
          email: this.email,
          country: this.country,
          dni: this.dni,
          phone: this.phone,
          birthdate: this.birthdate
        }
      }).then((response) => {
        this.$store.commit('closeLoading')
        this.$router.push({ name: 'panelListClients' })
      })
    }
  }
}
</script>
