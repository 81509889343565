<template>
    <div class="SliderBannerTextComponent">
      <v-carousel hide-delimiters  height="auto" cycle>
        <v-carousel-item v-for="(image, key) in images" :key="key" :src="image.image" max-height="500px">
          <div class="bannerContainer">
            <div class="banner">
              <div style="width: 100%"><a :href="image.url">
                <span class="text-uppercase caption name">{{ image.name }}</span>
                <span class="px-3 separator">|</span> <span class="message">{{ image.text }}</span></a>
              </div>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
</template>

<script>
import './_SliderBannerTextComponent.scss'
export default {
  name: 'SliderBannerTextComponent',
  props: {
    images: Array,
    size: String
  }
}
</script>
