<template>
    <div class="AccessControlView">
      <audio ref="alertSound" src="@/assets/beep-warning.mp3" preload="auto" style="display: none;"></audio>
      <QrcodeStream @decode="onDecode" v-if="scanning" />
      <div class="scanning" v-if="scanning">
        <div class="box"></div>
        <div class="close">
          <v-btn elevation="0" dark @click="scanning = false" class="mx-2 my-5" color="customColor">Volver</v-btn>
        </div>
      </div>
      <v-app-bar app v-if="!expose && !scanning">
        <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.normal.svg')" max-width="200px" width="auto" max-height="100px" contain/>
        <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.dark.svg')" max-width="200px" width="auto" max-height="100px" contain/>
        <v-spacer />
        <!--<v-btn color="customColor" elevation="0" to="/apps" icon plain tile small><v-icon small>mdi-apps</v-icon></v-btn>-->
        <!--<v-btn dark outlined color="customColor" @click="$store.commit('setComponent', { component: 'accessControlRecords' })">Accesos</v-btn>-->
      </v-app-bar>
      <v-container class="" v-if="!scanning">
        <v-row>
          <v-col align-self="center" align="center" v-if="$store.state.merchant">
            <v-img :src="$store.state.merchant.logo" max-width="200px" width="auto" max-height="100px" contain my-2/>
          </v-col>
          <v-col cols="12" align-self="center" align="center" v-if="event" v-show="!currentTicket">
            <h2 class="my-10">{{ event.event.name }}</h2>
            <v-img :src="event.event.primaryImage" max-width="200px" width="auto" max-height="200px" contain my-2/>
          </v-col>
        </v-row>
        <v-row v-if="!currentTicket && !expose && !result">
          <v-col align-self="center" align="center" >
            <v-btn v-if="$store.state.merchant || event" @click="show" dark x-large class="mx-2 my-10" elevation="0" color="customColor">Mostrar QR</v-btn>
             <v-btn v-if="$store.state.merchant || event" @click="scan" dark x-large class="mx-2 my-10" elevation="0" color="customColor">Escanear QR</v-btn>
            <!-- <div>
               <v-btn v-if="!event && !inputEvent" @click="inputEvent = true" dark class="mx-2 my-10" elevation="0" color="customColor">Activar Evento</v-btn>
               <v-btn v-if="event && $store.state.merchant" @click="event = false" dark class="mx-2 my-10" elevation="0">Cerrar Evento</v-btn>
               <div class="fade-in" v-if="inputEvent && !event">
                 <v-text-field style="max-width: 300px" class="boxField" label="Evento" dense v-model="eventId" outlined />
                 <v-btn @click="loadEvent(eventId)" dark class="mx-2 my-10" elevation="0" color="customColor">Configurar</v-btn>
                 <v-btn @click="inputEvent = false" dark class="mx-2 my-10" elevation="0">Cancelar</v-btn>
               </div>
             </div>-->
           </v-col>
         </v-row>
         <v-row v-if="expose && !result">
           <v-col align-self="center" align="center">
            <v-img class="qr" v-if="qr" :src="qr" width="250px" height="250px" max-width="250px" max-height="250px" aspect-ratio="1" style="border-radius: 16px" contain />
             <v-btn @click="close" dark class="mx-2 my-5" elevation="0" color="customColor">Volver</v-btn>
           </v-col>
         </v-row>
         <v-row v-if="expose && result">
           <v-col align-self="center" align="center" v-if="result.state">
             <div class="py-5">
               <v-img :src="getLayout(result)" max-width="300px" style="border-radius: 16px">
                 <div style="height: 100%; display: grid; place-content: center">
                     <v-icon size="128" color="success">mdi-check-circle-outline</v-icon>
                 </div>
               </v-img>
             </div>
             <div style="max-width: 300px">
               <v-text-field class="boxField" label="Nombre" dense v-model="result.data.data.clientName" readonly outlined />
               <v-text-field class="boxField" label="Miembro N°" dense v-model="result.data.data.member" readonly outlined />
               <v-text-field class="boxField" label="Categoría" dense v-model="result.category" readonly outlined />
               <v-text-field class="boxField" label="Saldo" dense v-model="result.balance" readonly suffix="puntos" outlined />
             </div>
             <v-btn @click="close" dark class="mx-2 my-10" elevation="0" color="customColor">Volver</v-btn>
           </v-col>
           <v-col align-self="center" align="center" v-if="!result.state">
             <div>
               <v-icon size="128" color="red">mdi-stop-circle-outline</v-icon>
             </div>
             <div class="display-1 text-uppercase my-3">
               Tarjeta invalida
             </div>
             <v-btn @click="close" dark class="mx-2 my-5" elevation="0" color="customColor">Volver</v-btn>
           </v-col>
         </v-row>
         <v-row v-if="currentTicket">
           <v-col align-self="center" align="center" v-if="currentTicket.cut">
             <div class="my-15 py-15">
             <v-icon size="128" color="success">mdi-check-circle-outline</v-icon>
             </div>
             <v-btn x-large @click="close" dark class="mx-2 my-10" elevation="0" color="customColor">Volver</v-btn>
           </v-col>
           <v-col align-self="center" align="center" v-if="!currentTicket.cut">
             <div class="py-5">
               <v-img :src="currentTicket.ticket.primaryImage" max-width="300px" style="border-radius: 16px">
                 <div style="height: 100%; display: grid; place-content: center">
                   <v-icon v-if="currentTicket.state === 'active'" size="128" color="success">mdi-check-circle-outline</v-icon>
                   <v-icon v-if="currentTicket.state !== 'active'" size="128" color="red">mdi-stop-circle-outline</v-icon>
                 </div>
               </v-img>
             </div>
             <v-text-field hide-details readonly v-model="currentTicket.accessCode" color="#1e9dd9" block class="accessCodeField my-2 px-10" rounded elevation="0" outlined dense />
             <div class="my-5">
               <v-chip v-if="currentTicket.state === 'created'" color="yellow">Creado</v-chip>
               <v-chip v-if="currentTicket.state === 'pending'" color="cyan">Pendiente Pago</v-chip>
               <v-chip v-if="currentTicket.state === 'active'" color="green">Activo</v-chip>
               <v-chip v-if="currentTicket.state === 'expire'" color="grey">Expirado</v-chip>
               <v-chip v-if="currentTicket.state === 'used'" color="orange">Usado</v-chip>
             </div>
             <div>
               <v-btn v-if="currentTicket.state === 'active'" @click="cutTicket" dark class="mx-2 my-10" elevation="0" color="green" x-large>Cortar Ticket</v-btn>
             </div>
             <div style="max-width: 300px">
               <!-- v-text-field v-if="currentTicket.contract === 'direct'" class="boxField" label="Tipo" dense value="Ticket de Cortesía" readonly outlined / -->
              <v-text-field class="boxField" label="Nombre del Ticket" dense v-model="currentTicket.ticket.name" readonly outlined />
              <v-text-field class="boxField" label="Nombre del Cliente" dense v-model="currentTicket.card.client.name" readonly outlined />
              <v-text-field class="boxField" label="DNI del Cliente" dense v-model="currentTicket.card.client.dni" readonly outlined />
              <v-text-field class="boxField" label="Edad del Cliente" dense :value="getAge(currentTicket.card.client.birthdate)" suffix="Años" readonly outlined />
              <v-text-field class="boxField" label="Miembro N°" dense v-model="currentTicket.card.data.member" readonly outlined />
            </div>
            <v-btn @click="close" dark class="mx-2 my-10" elevation="0" color="customColor">Volver</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.normal.alt.svg')" max-width="200px" width="auto" max-height="40px" contain/>
            <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.dark.alt.svg')" max-width="200px" width="auto" max-height="40px" contain/>
          </v-col>
        </v-row>
      </v-container>
      <v-btn fixed bottom right small icon @click="setThemeMode(!$vuetify.theme.dark)"><v-icon>mdi-brightness-6</v-icon></v-btn>
    </div>
</template>

<script>
import './_AccessControlView.scss'
import { QrcodeStream } from 'vue-qrcode-reader'
import qr from '@/mixins/qr'
import { utils } from 'ethers'
import moment from 'moment'

export default {
  name: 'AccessControlView',
  components: { QrcodeStream },
  data () {
    return {
      inputEvent: false,
      eventId: '',
      event: false,
      iD: false,
      ticket: false,
      currentTicket: false,
      ticketType: false,
      code: false,
      scanning: false,
      expose: false,
      qr: false,
      type: 0,
      result: false,
      assets: []
    }
  },
  mixins: [qr],
  async mounted () {
    if (this.$route.params.event) {
      this.loadEvent(this.$route.params.event)
      return
    }
    this.type = this.$store.state.merchant.type
    this.$axios.get('/sandbox').then(response => {
      this.assets = response.data
      console.log(this.assets)
    })
  },
  methods: {
    async loadEvent (id) {
      this.$store.commit('activeLoading')
      try {
        this.event = (await this.$axios.get('/thirdparty/passpoint/events/' + id)).data
      } catch (e) {
        this.event = false
      }
      this.inputEvent = false
      this.eventIde = ''
      this.$store.commit('closeLoading')
    },
    async cutTicket () {
      this.$store.commit('activeLoading')
      await this.$axios.put('/thirdparty/passpoint/tickets/' + this.$route.params.event + '/' + this.ticket, {
        iD: this.$store.state.iD,
        session: this.$store.state.session
      })
      this.currentTicket.cut = true
      this.$forceUpdate()
      this.$store.commit('closeLoading')
      setTimeout(() => {
        this.close()
        this.scan()
      }, 2000)
    },
    getLayout (result) {
      const category = this.$store.state.merchant.category.type.find(item => item.name === result.category)
      if (category) {
        return category.layout
      }
      return null
    },
    setThemeMode (style) {
      this.$vuetify.theme.dark = style
    },
    async onDecode (data) {
      if (this.event) {
        [this.ticketType, this.ticket, this.code] = data.split(':')
        if (this.ticketType === 'ticket') {
          this.processingTicket()
          this.scanning = false
          return
        }
      }
      this.iD = data
      this.scanning = false
      this.processing()
    },
    scan () {
      this.scanning = true
    },
    close () {
      this.expose = false
      this.$store.commit('closeLoading')
      this.qr = false
      this.iD = false
      this.ticket = false
      this.ticketType = false
      this.currentTicket = false
      this.code = false
      this.result = false
    },
    getAge (date) {
      return moment().diff(moment(date, 'YYYY-MM-DD'), 'years')
    },
    async show () {
      this.expose = true
      this.$store.commit('activeLoading')
      const result = await this.axios.post('/identification')
      const challenge = result.data
      if (challenge) {
        this.qr = await this.generateQuickResponseCode(challenge.code)
        try {
          this.$store.commit('closeLoading')
          let wait = await this.axios.get('/identification/' + challenge.hash + '/wait')
          if (!this.expose) return
          if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
            wait = await this.axios.get('/identification/' + challenge.hash + '/wait')
            if (!this.expose) return
          }
          if (wait.data.state === 'success') {
            this.iD = wait.data.data.iD
            this.processing()
          }
        } catch (e) {
          this.expose = false
        } finally {
          this.$store.commit('closeLoading')
        }
      }
    },
    async processingTicket () {
      this.$store.commit('activeLoading')
      this.currentTicket = (await this.$axios.get('/thirdparty/passpoint/tickets/' + this.$route.params.event + '/' + this.ticket)).data
      console.log(this.currentTicket)
      this.$store.commit('closeLoading')
      if (this.currentTicket.state !== 'active') {
        this.$refs.alertSound.play()
        setTimeout(() => {
          this.close()
          this.scan()
        }, 4000)
      }
    },
    async processing () {
      this.$store.commit('activeLoading')
      try {
        const card = (await this.axios.get('/thirdparty/passpoint/cards/' + this.iD + '/type/' + this.type)).data
        const data = (await this.axios.get('/thirdparty/passpoint/cards/' + card + '/data')).data
        await this.axios.post('/thirdparty/passpoint/cards/' + card + '/access', {
          session: this.$store.state.session,
          merchant: {
            type: this.$store.state.merchant.type,
            code: this.$store.state.merchant.code,
            name: this.$store.state.merchant.name,
            description: this.$store.state.merchant.description
          },
          employee: this.$store.state.useriD
        })
        const balance = (await this.axios.get('/network/addresses/' + card + '/balance/' + this.type + '/type')).data
        this.result = {
          date: moment().unix(),
          card: card,
          data: data,
          balance: parseFloat(utils.formatUnits(balance.balance, balance.decimals)),
          state: true,
          category: 'Sin Categoría'
        }
        if (data.type.toString() !== this.type.toString()) {
          this.result.state = false
        }
        if (data.data.category) {
          console.log('data category', data.data.category)
          if (data.data.category.expire > moment().unix()) {
            console.log('data category expire', data.data.category.expire)
            this.result.category = data.data.category.name
          }
        }
        this.$store.commit('addAccessRecord',
          {
            date: this.result.date,
            iD: this.result.card,
            name: this.result.data.data.clientName,
            member: this.result.data.data.member,
            state: this.result.state
          })
      } catch (e) {
        this.result = {
          date: moment().unix(),
          state: false
        }
        if (!this.result.state) {
          this.$refs.alertSound.play()
        }
      } finally {
        this.$store.commit('closeLoading')
        setTimeout(() => {
          this.close()
          this.show()
        }, 3000)
      }
    }
  }
}
</script>
