<template>
  <div class="TicketsView SectionControl">
    <v-container class="fill-height pa-10">
      <v-row>
        <v-col>
          <div class="mx-5 mx-sm-15 subtitleControlSection">Cliente</div>
          <div class="mx-5 mx-sm-15 titleControlSection">Tickets</div>
        </v-col>
      </v-row>
      <v-row v-if="tickets.length === 0">
        <v-col align-self="center" align="center">
            No tienes tickets disponibles
        </v-col>
      </v-row>
      <v-row v-if="tickets.length > 0">
        <v-col align-self="center" align="center">
          <v-simple-table class="ma-2 pa-10 rounded-5" style="filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.05)); max-width: 1600px">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Ticket
                </th>
                <th class="text-left">
                  Nombre del Evento
                </th>
                <th class="text-left">
                  Fecha del Evento
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(ticket, key) in tickets"
                :key="key"
              >
                <td>
                  <v-img :src="ticket.ticket.primaryImage" width="20vw" max-width="100px" :class="{ 'my-5': true, ticketUsed: ticket.state !== 'active' }" contain style="border-radius: 4px">
                  </v-img>
                </td>
                <td>{{ ticket.event.event.name }}</td>
                <td>{{ getDateFormat(ticket.event.event.startTimestamp)  }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-container>
    </div>
</template>

<script>

import './_TicketsView.scss'
import moment from 'moment'
export default {
  name: 'TicketsView',
  data () {
    return {
      tickets: []
    }
  },
  mounted () {
    this.loadTickets()
  },
  watch: {
    useriD: function (value) {
      this.loadTickets()
    }
  },
  computed: {
    useriD () {
      return this.$store.state.useriD
    }
  },
  methods: {
    getDateFormat (timestamp) {
      return moment.unix(timestamp).format('DD/MM/YYYY')
    },
    async loadTickets () {
      this.loading = true
      this.$store.commit('activeLoading')
      const iD = this.$store.state.useriD
      this.tickets = (await this.$axios.post('/thirdparty/passpoint/tickets/' + iD + '/actives', {
        session: this.$store.state.session
      })).data
      this.loading = false
      this.$store.commit('closeLoading')
      this.$forceUpdate()
    }
  }
}
</script>
