<template>
    <div class="FooterDefaultComponent">
        <v-footer dark color="#0C131D" class="pa-0">
          <v-container fluid class="pa-0">
            <v-row class="ma-0">
              <v-col align-self="center" align="center">
                <div class="message">
                  Selecciona tu pais y descubre la experiencia de nuestro ecosistema blockchain de eventos y fidelización de clientes
                </div>
              </v-col>
            </v-row>
            <v-row class="ma-0">
              <v-col align-self="center" align="center">
                <div class="powered">
                  © 2023 POWERED BY PassPoint
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
    </div>
</template>

<script>
import './_FooterDefaultComponent.scss'
export default {
  name: 'FooterDefaultComponent',
  methods: {
    scrollToTop () {
      setTimeout(() => { window.scrollTo({ top: 0, behavior: 'smooth' }) }, 1000)
    }
  }
}
</script>
