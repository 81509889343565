<template>
    <div class="HeaderComponent">
      <v-system-bar window color="customColor" dark style="padding-left: 5%; padding-right: 5%">
        <v-btn elevation="0" to="chile/soporte" plain x-small class="active"><v-icon small>mdi-headset</v-icon><span class="hidden-xs-only"> Soporte</span></v-btn>
        <v-spacer />
        <v-btn elevation="0" to="/p/events/create" plain x-small class="active"><v-icon small>mdi-calendar-outline</v-icon><span class="hidden-xs-only"> Crear Evento</span></v-btn>
        <v-btn elevation="0" to="/apps" plain x-small class="active"><v-icon small>mdi-apps</v-icon><span class="hidden-xs-only"> Apps</span></v-btn>
      </v-system-bar>
      <v-app-bar
        app
        style="background-color: #f5f5fa !important;"
      >
        <!--v-app-bar-nav-icon class="hidden-sm-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon-->
        <router-link :to="getLink()">
          <v-img :src="require('@/assets/logos/logo.normal.svg')" v-if="!$vuetify.breakpoint.smAndDown" max-width="200px" width="auto" max-height="20px" contain>
            <div class="flag" v-if="$route.meta.country">
              <flag style="height: 10px; width: 10px" :squared="false" :iso="$route.meta.country" />
            </div>
          </v-img>
          <v-img :src="require('@/assets/logos/logo.normal.svg')" v-if="$vuetify.breakpoint.smAndDown" max-width="120px" width="auto" max-height="12px" contain>
            <div class="flag sm" v-if="$route.meta.country">
              <flag style="height: 6px; width: 6px" :squared="false" :iso="$route.meta.country" />
            </div>
          </v-img>
        </router-link>
        <v-spacer />
        <div v-if="$vuetify.breakpoint.smAndUp">
          <v-btn v-if="true" elevation="0" plain x-small @click="$router.push({ name: 'passpoint', params: { country: $store.state.countryName }})">PassPoint</v-btn>
          <v-btn v-if="true" elevation="0" plain x-small @click="$router.push({ name: 'buyPoints', params: { country: $store.state.countryName }})">Comprar Puntos</v-btn>
          <v-btn v-if="true" elevation="0" plain x-small @click="$router.push({ name: 'usability', params: { country: $store.state.countryName }})">Usabilidad</v-btn>
          <v-btn v-if="true" elevation="0" plain x-small @click="$router.push({ name: 'services', params: { country: $store.state.countryName }})">Servicios</v-btn>
          <v-btn v-if="true" elevation="0" plain x-small @click="$router.push({ name: 'soporte', params: { country: $store.state.countryName }})">Soporte</v-btn>
          <v-btn v-if="false" elevation="0" plain x-small @click="$router.push({ name: 'events', params: { country: $store.state.countryName }})">Eventos</v-btn>
          <v-btn v-if="false" elevation="0" plain x-small @click="$router.push({ name: 'usuuarios', params: { country: $store.state.countryName }})">Usuarios</v-btn>
          <v-btn v-if="false" elevation="0" plain x-small @click="$router.push({ name: 'comercios', params: { country: $store.state.countryName }})">Comercios</v-btn>
          <v-btn v-if="false" elevation="0" plain x-small @click="$router.push({ name: 'empresas', params: { country: $store.state.countryName }})">Empresas</v-btn>
          <v-btn v-if="false" elevation="0" plain x-small>Promociones</v-btn>
          <v-btn v-if="false" elevation="0" plain x-small>Membresías</v-btn>
        </div>
        <v-spacer />
        <v-btn elevation="0" v-if="!iD" color="customColor" dark class="px-5 py-4" x-small @click="showLogin"><v-icon x-small>mdi-login</v-icon><span> Log In</span></v-btn>
        <div v-if="balance && iD" class="balanceText">
        <strong>{{ balance | numFormat('0,000.[00]') }}</strong>
        </div>
        <div v-if="balance  && iD" class="pointLabel">
          <div> <div>Puntos</div> <flag :squared="false" :iso="$store.state.country" /></div>
        </div>
        <v-btn elevation="0" to="/p/memberships/list" dark class="ml-2" v-if="iD" fab tile color="customColor" x-small><v-icon x-small>mdi-account</v-icon></v-btn>
        <v-btn elevation="0" @click="$store.commit('closeSession', { router: $router })" dark class="ml-1" v-if="iD" fab tile color="black" x-small><v-icon x-small>mdi-logout</v-icon></v-btn>
      </v-app-bar>

      <!--v-navigation-drawer
        v-model="drawer"
        app
        dark
        color="#374162"
      >
        <v-list
          nav
          dense
        >
          <v-list-item-title class="py-5">
            <v-img :src="require('@/assets/logos/logo.dark.svg')" max-width="200px" width="auto" max-height="20px" contain/>
          </v-list-item-title>
          <v-list-item-group
            active-class="white--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title>
                Comprar Puntos
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                Eventos
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                Promociones
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                Membresías
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                Servicios
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                PassPoint
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                Usabilidad
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer-->

    </div>
</template>

<script>
import './_HeaderComponent.scss'
export default {
  name: 'HeaderComponent',
  data () {
    return {
      drawer: false
    }
  },
  mounted () {
    if (this.$store.state.useriD) {
      this.$store.commit('updateBalance')
    }
  },
  updated () {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.drawer = false
    }
    if (this.$store.state.useriD) {
      this.$store.commit('updateBalance')
    }
  },
  computed: {
    iD () {
      return this.$store.state.iD
    },
    balance () {
      return this.$store.state.balance
    }
  },
  methods: {
    getLink () {
      if (!this.$route.meta.country) {
        return '/'
      }
      if (this.$route.meta.country === 'CL') {
        return '/chile'
      }
      if (this.$route.meta.country === 'PE') {
        return '/peru'
      }
      return '/'
    },
    showLogin () {
      this.$store.commit('setComponent', { component: 'login' })
    }
  }
}
</script>
