<template>
  <div class="LegalView">
    <v-container class="fill-height" fluid>
      <v-btn
        v-if="showScrollButton"
        fab
        bottom
        right
        fixed
        color="white"
        @click="scrollToTop"
        >
        <v-icon color="primary">
          mdi-arrow-up
        </v-icon>
      </v-btn>
      <v-row>
        <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
          <div class="titleSection py-15 title1">
            TERMINOS DE USO
          </div>
          <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleBig">Aceptación de los Términos de Uso</div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Generalidades
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Passpoint es una plataforma compuesta de Aplicación móvil y web para la colocación, promoción y compra venta comercial orientada a espectáculos y eventos de todo tipo. Se basa en el programa de lealtad y fidelidad que la misma plataforma pone a disposición de empresarios y sus clientes. Passpoint en ningún caso es propietario u organizador de dichos eventos y su responsabilidad es limitada al respecto.

              Passpoint es responsable de su contenido, software y propiedad intelectual.
              Passpoint no apoya abuso, fraude, conexión con el terrorismo o lavado de dinero en ninguna de sus formas.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Uso de la plataforma, app y web
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Los servicios entregados por Passpoint están dirigidos a personas mayores de edad según la legislación vigente de cada país; al usar estos servicios ya sea como cliente o proveedor, cada usuario acepta de manera tácita y definitiva los términos y condiciones de uso de la plataforma, comprometiéndose a respetarlos y hacer correcto uso. No utilice la plataforma si no está de acuerdo con estos términos y condiciones.
              Passpoint se reserva el derecho a tomar acciones contra infractores de este acuerdo.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Privacidad
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Passpoint mantiene reserva de la información personal de cada usuario mediante su tecnología. Passpoint no comparte información que el titular no haya proporcionado libremente, a su vez el titular mantiene completo control sobre sus datos personales mediante el sistema de autocustodia provisto por Passpoint.
              Análisis de datos generados por la actividad del usuario pueden ser realizados a fin de mejorar la experiencia (KYC, Know Your Client), no obstante esta información se mantiene anónima y no relacionada al usuario.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Acerca de los puntos
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Generalidades: Los puntos son tokens equivalentes en valor a la moneda local, no caducables ni susceptibles a especulación, intercambiables electrónicamente por bienes y servicios dentro de la plataforma y su ecosistema.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Adquisición: Los puntos se compran por parte del usuario en moneda local mediante alguna de las formas de pago electrónicas previstas por la aplicación. También podrían ser comprados en efectivo en tiendas físicas y tótems de autoservicio.
              Tiendas podrían abonar puntos sin costo de manera promocional cuando sus productos o servicios son preferidos.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Almacenamiento: Los puntos se acumulan electrónicamente en el perfil del usuario y quedan inmediatamente preparados para ser canjeados.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Canje: Los puntos son canjeables electrónicamente por bienes o servicios. en tiendas físicas y virtuales
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Reembolsos: Los puntos no son reembolsables, y sólo pueden ser canjeados por su equivalencia en moneda local en comercio adherido via presencial u online.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Propiedad intelectual
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Passpoint, el concepto Passpoint, la marca comercial Passpoint, el isologotipo Passpoint, su sitio web, contenidos, software y hardware, y banners promocionales son propiedad intelectual de Passpoint y quedan protegidos por tratados internacionales y la legislación vigente en los países donde Passpoint esté presente.
              La propiedad intelectual de Passpoint no incluye piezas de software u otros provistos por terceros debidamente indicados.
              Los banners promocionales son propiedad de los organizadores de eventos asociados.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Responsabilidad legal
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Passppoint adhiere a la legislación vigente en los países donde presenta su servicio, y deja en claro que no tiene responsabilidad sobre las relaciones entre proveedores comerciales y usuarios y la libertad con que cada uno de ellos pueda ofrecer o adquirir bienes o servicios.
              Passpoint no adhiere a transacciones ilegales, fraude, terrorismo o lavado de activos, en cuyos casos Passpoint se reserva el derecho a cancelar transacciones de caracter fraudulento y poner los antecedentes en conocimiento de las autoridades.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Política de Devoluciones
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              PassPoint expresamente declara que no se realizarán cambios o devoluciones cuando la compra de puntos haya sido correctamente efectuada, recordando además que los puntos adquiridos no caducan y pueden ser ocupados en equivalencia a su monto en dinero en cualquiera de las ofertas y eventos que comercios asociados a PassPoint emitan.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Todos los canjes hechos con puntos PassPoint; ya sea por tickets digitales u otros bienes o servicios; son asociados a la cuenta PassPoint del cliente con máximas medidas de seguridad, por lo anterior el cliente es el responsable de verificar bien su pedido de puntos y de mantener su cuenta y dispositivos a resguardo.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              La adquisición de tickets digitales mediante puntos PassPoint inmediatamente delega toda responsabilidad hacia el productor que emitió tales tickets. Los productores y comercios son responsables de los eventos, productos y/o servicios que ofrezcan y comercialicen a través de la plataforma PassPoint u otros medios a través del canje de puntos PassPoint. Dado los contratos entre PassPoint, productores y comercios, PassPoint se exime de la responsabilidad de devoluciones, la cual recae en el productor o comercio que comercializó el producto independiente de su naturaleza.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Sólo en casos de fuerza mayor, ya sea que un Evento cambie de fecha, horario, lugar del mismo, artistas u otro que alteren la naturaleza del espectáculo PassPoint efectuará devolución de montos pagados sólo a aquellos clientes que efectivamente hayan canjeado puntos por tickets digitales del evento, con al menos 12 horas de anticipación al evento soliciten tal devolución y que hayan sido autorizados y validados por el organizador del evento en cuestión.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Si por fuerza mayor también el evento se suspendiese o cancelase, aquellos clientes que efectivamente hayan canjeado puntos PassPoint por tickets para el evento en cuestión podrán optar a la devolución de los montos trancados en moneda local, por transferencia bancaria directa e individual.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Las condiciones de devolución incluyen descuento del 10% más IVA correspondiente a la comisión de PassPoint, y los montos deben ser liberados por el productor o comercio responsable. En todos los casos mencionados anteriormente la devolución se hará efectiva en un tiempo máximo de treinta (30) días hábiles.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Cambios en la Política
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Passpoint se reserva el derecho de hacer cambios que mejoren esta política sin aviso previo y promover su conocimiento por parte de los usuarios.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Fecha de la última actualización: 07 Noviembre 2023.
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_LegalView.scss'

export default {
  name: 'LegalView',
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
