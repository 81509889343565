<template>
    <div class="StepComponent" :style="{backgroundColor: bgColor}">
        <v-container class="py-10">
          <v-row>
            <v-col class="stepTitle" align-self="center" align="center" cols="12" md="3">
              {{ title }}
            </v-col>
            <template v-for="(step, index) in steps">
              <v-col align-self="center" align="center" class="step" :key="index">
                <div class="stepContainer slide-in-right" :style="{ animationDelay: (index + 1) + 's'  }">
                  <div><strong>{{ step.title }}</strong></div>
                  <div>{{ step.message }}</div>
                </div>
              </v-col>
              <v-col v-if="index !== steps.length - 1" align-self="center" align="center" :key="'hr' + index" cols="12" md="1">
                <hr style="max-width: 20px" />
              </v-col>
            </template>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_StepComponent.scss'
export default {
  name: 'StepComponent',
  props: {
    title: String,
    steps: Array,
    bgColor: String('#1e9dd9')
  }
}
</script>
