<template>
  <div class="CreateEventView SectionControl">
    <v-container class="fill-height pa-5">
      <v-row>
        <v-col>
          <div class="mx-5 mx-sm-15 subtitleControlSection">Eventos</div>
          <div class="mx-5 mx-sm-15 titleControlSection">Crear Evento</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mx-5 mx-sm-5">
            <div class="form ml-1">
              <v-form class="pa-3" ref="form">
                <v-text-field v-model="event.name" :rules="[rules.required]" class="my-3" outlined rounded label="Nombre del Evento" />
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="event.type" :items="['Publico', 'Privado']" :rules="[rules.required]" class="my-3 mx-1" outlined rounded label="Tipo de Evento" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete v-model="event.country" :items="['Chile', 'Peru']" :rules="[rules.required]" class="my-3 mx-1" outlined rounded label="Pais del Evento" />
                  </v-col>
                </v-row>
                <v-textarea v-model="event.description" counter class="my-3" :rules="[rules.required]" outlined rounded label="Descripción del Evento" />
                <v-text-field v-model="event.points" counter class="my-3"  type="number" suffix="puntos" outlined rounded label="Valor mínimo de Tickets" />
                <v-checkbox v-model="event.adults" class="my-3" label="Evento para mayores de edad" />
                <v-combobox v-model="event.categories" class="my-3" outlined rounded label="Categorías del Evento" messages="Hasta 3 categorías" chips small-chips multiple  />
                <v-combobox v-model="event.artists" class="my-3" outlined rounded label="Artistas participantes" chips small-chips multiple  />

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="event.startDate" :rules="[rules.fechaHora]" type="date" class="my-3 mx-1" outlined rounded label="Fecha Inicio del Evento" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="event.startTime" :rules="[rules.fechaHora]" type="time" class="my-3 mx-1" outlined rounded label="Hora Inicio del Evento" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="event.endDate" :rules="[rules.fechaHora]" type="date" class="my-3 mx-1" outlined rounded label="Fecha Termino del Evento" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="event.endTime" :rules="[rules.fechaHora]" type="time" class="my-3 mx-1" outlined rounded label="Hora Termino del Evento" />
                  </v-col>
                </v-row>
                <div class="d-flex">
                  <!--v-text-field v-model="event.open" type="datetime-local" class="my-3 mx-1" outlined rounded label="Hora de Apertura" /-->
                  <v-text-field v-model="event.place" :rules="[rules.required]" class="my-3 mx-1" outlined rounded label="Lugar del Evento" />
                </div>
                <vuetify-google-autocomplete
                  v-model="event.address"
                  :rules="[rules.required]"
                  id="map"
                  class="my-3" outlined rounded label="Dirección del Evento" prepend-icon="mdi-map"
                  country="cl"
                  v-on:placechanged="setAddressData"
                />
                <v-text-field v-model="event.video" class="my-3" outlined rounded label="Video Promocional" messages="URL de youtube" prepend-icon="mdi-youtube" />
                <v-file-input accept="image/*" :error-messages="errors.primaryImage" v-model="primaryImage" class="my-3" outlined rounded label="Imagen Principal del Evento" messages="Formato soportado de 900x900 pixeles" @change="(e) => previewImage(e, 'primaryImage')"/>
                <div align="center">
                  <v-img :src="event.primaryImage" contain width="80%" max-width="500px"></v-img>
                </div>
                <v-alert v-if="!validForm" :value="true" type="error">
                  Debes llenar todos los campos solicitados
                </v-alert>
                <v-alert v-if="!validPrimaryImage" :value="true" type="error">
                  Debes incluir una "Imagen Principal" para este evento
                </v-alert>
                <v-alert v-if="startAfterEnd" :value="true" type="error">
                  La fecha de inicio debe ser anterior a la fecha de término de tu evento
                </v-alert>
                <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="processRequest">Crear Evento</v-btn>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog
        v-model="dialog"
        max-width="400"
      >
        <v-card>
          <v-card-title>
            <div align="center" style="width: 100%" class="my-5">
            ¿Deseas crear Tickets?
            </div>
          </v-card-title>
          <v-card-actions>
            <v-btn
              @click="continueWithTicket"
              dark
              color="customColor"
              class="ma-5"
              width="100"
            >
              Si
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              @click="continueToEvents"
              outlined
              color="customColor"
              class="ma-5"
              width="100"
            >
              No
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="editor" v-if="editPreview">
        <v-container fluid class="fill-height editor-background">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="editor-box">
                <PinturaEditor
                  v-bind="editorDefaults"
                  :src="editImage"
                  :imageCropAspectRatio="radio"
                  @pintura:process="finishPreview"
                ></PinturaEditor>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</template>

<script>
import './_CreateEventView.scss'
import auth from '@/mixins/auth'
import { createDefaultImageWriter, getEditorDefaults } from '@pqina/pintura'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '@/plugins/firebase'
import moment from 'moment'
import { PinturaEditor } from '@pqina/vue-pintura/vue-2'
export default {
  name: 'CreateEventView',
  components: { PinturaEditor },
  data () {
    return {
      validForm: true,
      validPrimaryImage: true,
      startAfterEnd: false,
      hash: null,
      errors: {},
      dialog: false,
      radio: 1,
      primaryImage: null,
      editItem: false,
      editImage: false,
      modalEndTime: false,
      modalStartTime: false,
      event: {
        iD: this.$store.state.useriD,
        name: '',
        type: '',
        country: '',
        adults: false,
        points: 0,
        categories: [],
        artists: [],
        open: null,
        start: null,
        startDate: null,
        startTime: null,
        end: null,
        endDate: null,
        endTime: null,
        place: '',
        address: '',
        addressData: {},
        video: '',
        primaryImage: null
      },
      editorDefaults: getEditorDefaults(),
      editPreview: false,
      rules: {
        required: value => !!value || 'Campo obligatorio.',
        fechaHora: value => !!value || 'Presiona el icono para llenar este campo',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-Mail inválido.'
        },
        rut: value => {
          const pattern = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/
          console.log(pattern.test(value))
          return pattern.test(value) || 'Rut inválido. Ingresa puntos y guión (12.345.678-9)'
        },
        telefono: value => {

        }
      }
    }
  },
  mounted () {
    this.$axios.get('/thirdparty/passpoint/producers/' + this.$store.state.useriD, {
      params: {
        iD: this.$store.state.iD,
        session: this.$store.state.session
      }
    }).catch((response) => {
      this.$router.push('/')
    })
    this.editorDefaults.imageWriter = createDefaultImageWriter({
      targetSize: {
        width: 900,
        height: 900,
        upscale: true
      }
    })
  },
  mixins: [auth],
  methods: {
    setAddressData: function (addressData, placeResultData, id) {
      this.event.addressData = addressData
    },
    async finishPreview (result) {
      this.$store.commit('activeLoading')
      const storageRef = ref(storage, '/events/' + result.dest.name)
      uploadBytes(storageRef, result.dest).then(async (snapshot) => {
        this.event[this.editItem] = await getDownloadURL(snapshot.ref)
        this.$store.commit('closeLoading')
        this.editPreview = false
        this.editItem = false
        this.editPreview = false
      })
    },
    async previewImage (e, item) {
      this.errors[item] = null
      if (item === 'primaryImage') {
        this.radio = 1
        this.editorDefaults.imageWriter = createDefaultImageWriter({
          targetSize: {
            width: 900,
            height: 900,
            upscale: true
          }
        })
      }
      const file = e
      if (file.size > 10 * 1024 * 1024) {
        this.errors[item] = 'La imagen tiene mas de 10 Mbytes'
        return
      }
      this.editImage = URL.createObjectURL(file)
      this.editItem = item
      this.editPreview = true
    },
    async advanceValidate () {
      this.validPrimaryImage = this.event.primaryImage !== null
      const inicio = moment(this.event.startDate + 'T' + this.event.startTime).unix()
      const fin = moment(this.event.endDate + 'T' + this.event.endTime).unix()

      if ((!isNaN(inicio)) && (!isNaN(fin))) {
        this.startAfterEnd = (inicio > fin)
      }
      return this.validPrimaryImage && !this.startAfterEnd
    },
    async processRequest () {
      this.$store.commit('activeLoading')
      if (!this.verifyAuth()) return

      this.validForm = await this.$refs.form.validate()
      this.validForm = await this.advanceValidate() && this.validForm
      if (!this.validForm) {
        this.$store.commit('closeLoading')
        return
      }

      this.event.start = this.event.startDate + 'T' + this.event.startTime
      this.event.end = this.event.endDate + 'T' + this.event.endTime
      this.event.startTimestamp = moment(this.event.start).unix()
      this.event.endTimestamp = moment(this.event.end).unix()
      this.$axios.post('/thirdparty/passpoint/events', {
        iD: this.$store.state.iD,
        useriD: this.$store.state.useriD,
        event: this.event,
        session: this.$store.state.session
      }).then(response => {
        console.log(response.data)
        this.hash = response.data.hash
        this.$store.commit('closeLoading')
        this.dialog = true
      })
    },
    continueWithTicket () {
      this.dialog = false
      this.$router.push({ name: 'panelCreateTicketEvent', params: { hash: this.hash } })
    },
    continueToEvents () {
      this.dialog = false
      this.$router.push({ name: 'panelListEvent' })
    }
  }
}
</script>
