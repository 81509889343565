<template>
    <div class="RechargeRecordsComponent">
        <v-container class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="titleSection">Recargas</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div class="recordsContainer">
                <div v-for="(record, index) in $store.state.logs.recharge" :key="index" align="left" class="my-2">
                  <div class="d-flex justify-space-between align-end">
                    <div class="caption">{{ getDateFormat(record.date) }}</div>
                    <div class="caption">
                     ...{{ record.iD.slice(-16) }}
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                      <div>
                        <div>
                          <span class="font-weight-bold">Monto:</span> <span class="font-weight-bold">{{ record.amount }}</span> <span class="caption">Puntos</span>
                        </div>
                      </div>
                      <div>{{ record.shortTx }}</div>
                  </div>
                  <hr style="opacity: 0.5" />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div>
                <div class="font-weight-bold">Total</div>
                <div class="display-2">{{ total | numFormat('0,000.[00]') }} <span class="caption">Puntos</span></div>
              </div>
            </v-col>
            <v-col align-self="center" align="center">
              <div>
                <div class="font-weight-bold">N°</div>
                <div class="display-2">{{ $store.state.logs.recharge.length }} <span class="caption">Recargas</span></div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <v-btn elevation="0" color="customColor" dark outlined @click="$store.commit('closeComponent')">Cerrar</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_RechargeRecordsComponent.scss'
import moment from 'moment'
export default {
  name: 'RechargeRecordsComponent',
  computed: {
    total () {
      let total = 0
      for (const recharge of this.$store.state.logs.recharge) {
        total += parseFloat(recharge.amount)
      }
      return total
    }
  },
  methods: {
    getDateFormat (timestamp) {
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    }
  }
}
</script>
