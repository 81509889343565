<template>
    <div class="SingleBannerComponent">
      <v-container>
        <div>
          <v-img ml-0 px-0 :src="image" max-width= 800 px contain />
        </div>
      </v-container>
    </div>
</template>

<script>
import './_SingleBannerComponent.scss'
export default {
  name: 'SingleBannerComponent',
  props: {
    image: String
  }
}
</script>
