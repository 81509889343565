<template>
  <div class="UsabilityView">
    <v-container class="fill-height" fluid>
      <v-btn
        v-if="showScrollButton"
        fab
        bottom
        right
        fixed
        color="white"
        @click="scrollToTop"
      >
        <v-icon color="primary">
          mdi-arrow-up
        </v-icon>
      </v-btn>
      <v-row class="principal">
        <v-col cols="12"
        sm="10"
        md="8"
        lg="6"
        xl="4" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
          <div class="titleSection py-15 title1">
            PASSPOINT
          </div>
          <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleBig">Usabilidad</div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Descarga la APP
            </div>
          </v-row>
          <v-row>
            <div class="LegalText mt-5 mt-md-0" >
              Descarga la App Passpoint en AppStore o Google Play.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
             Ejecuta la APP y crea tu identidad.
            </div>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="pa-0">
              <!-- <VideoBrandComponent2 :video="require('@/assets/videos/registro.mp4')" /> -->
              <iframe width="370" height="657" src="https://www.youtube.com/embed/HCz4Ip-S41k" title="Registro APP PassPoint" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </v-col>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Ingreso/Control de Acceso
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Dirigete al Club asociado.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Selecciona la membresía Passpoint o tu tarjeta del Club asociado si la tuvieses.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Escanea el código QR que te presentarán en el control de acceso del Club.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Valida tu identidad en la APP.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Disfruta tus Beneficios.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Agregar Membresía a tu cuenta Passpoint
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Abre tu cuenta de email asociada a tu cuenta Passpoint.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Escanea el QR o Copia el código.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              En la APP haz click en el Botón Agregar Membresía con Código.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Pega el código.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Haz click el botón Activar Membresía.
            </div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Iniciar sesión en www.passpoint.global con tu APP
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Ingresa a www.passpoint.global
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Haz click en Log In.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              En desktop Escanea el código QR con tu APP, en móvil autoriza directamente.
            </div>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="pa-0">
             <!-- <VideoBrandComponent2 :video="require('@/assets/videos/login.mp4')" />-->
              <iframe width="370" height="657" src="https://www.youtube.com/embed/96Q9EZ1LT6Q" title="Login PassPoint" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </v-col>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Cargar Puntos a tu Membresía Passpoint
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Abre el sitio web www.passpoint.global.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Dirígete a la sección Comprar Puntos.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Ingresa la cantidad de Puntos a comprar.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Selecciona tu medio de pago.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Disfruta tus Puntos.
            </div>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="pa-0">
              <!--<VideoBrandComponent2 :video="require('@/assets/videos/comprapuntos.mp4')" />-->
              <iframe width="370" height="657" src="https://www.youtube.com/embed/CRbd9ecQmJQ" title="Compra Puntos PassPoint" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </v-col>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Canjear Tickets
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Abre tu APP.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Dirígete a la sección Eventos.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Selecciona el Evento (puedes usar la opción Buscar Evento).
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Haz click en Canjear.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Acepta el canje en tu APP.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Tu ticket ha sido agregado a la sección canjes/tickets.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Disfruta del Evento.
            </div>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="pa-0">
              <iframe width="370" height="657" src="https://www.youtube.com/embed/DTp0up3S170" title="Canje Ticket" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>            </v-col>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
              Crear Eventos (Productores)
            </div>
          </v-row>
          <v-row>
            <div class="LegalText mt-5 mt-md-0" >
              Haz Click en Crear Evento.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Rellena los campos de texto, fecha, hora e imagen.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Haz Click en Crear Evento.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Selecciona Crear Tickets.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Rellena los campos de texto, fecha, hora e imagen.
            </div>
          </v-row>
          <v-row>
            <div class="LegalText">
              Haz Click en Crear Tickets.
            </div>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" class="pa-0">
              <iframe width="370" height="657" src="https://www.youtube.com/embed/Mf363FBNhAI" title="Crear Evento" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>            </v-col>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_UsabilityView.scss'
// import VideoBrandComponent2 from '@/components/VideoBrandComponent2/VideoBrandComponent2.vue'

export default {
  name: 'UsabilityView',
  components: {
    // VideoBrandComponent2
  },
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
