<template>
    <div class="LoginComponent">
      <v-container class="fill-height fade-in" fluid style="animation-delay: 0.5s">
        <v-row>
          <v-col align-self="center" align="center">
            <v-card max-width="400px" color="#ffffff88" class="pa-5" elevation="15">
              <!--
              <div v-if="!$route.meta.auth" align="right">
                <v-btn elevation="0" color="customColor" dark outlined x-small fab @click="$store.commit('closeComponent')"><v-icon>mdi-close</v-icon></v-btn>
              </div>
              <div v-if="$route.meta.auth" align="right">
                <v-btn elevation="0" color="customColor" dark outlined x-small fab @click="closeBack"><v-icon>mdi-close</v-icon></v-btn>
              </div>
              -->
              <div v-if="qr && ($route.meta.showQR || !$device.mobile)">
                <div class="my-2 caption">Escanea con tu tarjeta <strong>PassPoint</strong></div>
              </div>
              <v-img :src="qr" v-if="qr && ($route.meta.showQR || !$device.mobile)" width="250px" height="250px" contain style="border-radius: 16px" />
              <v-img :src="require('@/assets/logos/logo.icon.svg')" contain width="100px" height="250px" v-if="!qr" />
              <v-btn v-if="refresh" @click="getRefresh" outlined rounded>Refrescar</v-btn>
              <div v-if="($device.mobile || true)" class="icon-mobile mt-5 cursor-pointer" @click="openApp">
                <!--v-img v-if="$device.ios" :src="require('@/assets/icon-ios.png')" contain width="32px" height="32px"></v-img>
                <v-img v-if="$device.android" :src="require('@/assets/icon-android.png')" contain width="32px" height="32px"></v-img-->
                  <v-btn v-if="(qr && $device.mobile)" elevation="0" color="customColor" @click="openApp" dark rounded>Login</v-btn>
                <!--div class="caption mt-1" v-if="$device.mobile">
                  Has click para abrir <strong>PassPoint App</strong>
                </div-->
              </div>
              <div class="mt-5">
                <span class="caption">Descarga la aplicación <strong @click="openApp">PassPoint</strong> desde</span>
                <div class="d-inline-flex" style="vertical-align: middle">
                  <a class="elevation-0 my-1 rounded-lg mx-1" href="https://play.google.com/store/apps/details?id=com.PassPoint.passpoint" target="_blank"><v-img contain :src="require('@/assets/logos/google.play.svg')" alt="" max-width="120px" /></a>
                  <a class="elevation-0 my-1 rounded-lg mx-1" href="https://apps.apple.com/cl/app/passpoint/id6465455230" target="_blank"><v-img contain :src="require('@/assets/logos/apple.store.svg')" alt="" max-width="120px" /></a>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_LoginComponent.scss'
import qr from '@/mixins/qr'
export default {
  name: 'LoginComponent',
  data () {
    return {
      qr: false,
      challenge: false,
      success: false,
      refresh: false,
      links: {}
    }
  },
  mounted () {
    setTimeout(() => {
      if (this.$store.state.useriD) {
        this.$store.commit('closeComponent')
      }
    }, 100)
    this.getChallenge()
  },
  beforeDestroy () {
    this.$store.commit('closeLoading')
  },
  mixins: [qr],
  methods: {
    getRefresh () {
      this.refresh = false
      this.getChallenge()
    },
    closeBack () {
      history.back(-1)
      setTimeout(() => { this.$store.commit('closeComponent') }, 1000)
    },
    openApp () {
      if (this.$device.ios || window.navigator.userAgent.indexOf('Mac') > 0 || window.navigator.userAgent.indexOf('OS X') > 0) {
        window.location.href = this.links.ios
        return
      }
      if (this.$device.android || window.navigator.userAgent.indexOf('Android') > 0) {
        window.location.href = this.links.android
        return
      }
      window.location.href = this.links.android
    },
    async getChallenge () {
      if (this.$store.state.component !== 'login') {
        return
      }
      this.$store.commit('activeLoading')
      const preLogin = this.$store.state.preLogin
      const result = preLogin ? await this.$axios.get('/identification/' + preLogin) : await this.$axios.post('/identification', { lite: true })
      const challenge = result.data
      this.challenge = challenge
      if (challenge) {
        console.log('Mobile:', this.$device.mobile)
        console.log('ShowQR:', this.$route.meta.showQR)

        if (!preLogin) {
          this.qr = await this.generateQuickResponseCode(challenge.code)
          this.links = challenge.links
          console.log(this.links)
          this.links.android = this.links.android.replace('com.PassPoint.id4good', 'com.PassPoint.passpoint')
          this.links.ios = this.links.ios.replace('scan', 'passpoint/scan')
          this.links.ios = this.links.ios.replace('id4good.com', 'app.passpoint.global') + '&app_links_app_id=6465455230'
          console.log(this.links)
          this.$store.commit('closeLoading')
        }
        try {
          let wait = await this.$axios.get('/identification/' + challenge.hash + '/wait')
          if (this.$store.state.component !== 'login') {
            return
          }
          if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
            this.qr = null
            this.$store.commit('activeLoading')
            wait = await this.$axios.get('/identification/' + challenge.hash + '/wait')
            if (this.$store.state.component !== 'login') {
              return
            }
          }
          if (wait.data.state === 'success') {
            this.success = true
            this.$store.state.iD = wait.data.data.iD
            this.$store.state.useriD = (await this.$axios.get('/thirdparty/passpoint/' + this.$store.state.iD + '?version=' + Math.random())).data
            setTimeout(async () => {
              this.$store.state.session = (await this.$axios.post('/identification/' + challenge.hash + '/session')).data.session
              this.$store.commit('saveSession')
            }, 1200)
            setTimeout(() => {
              this.$store.commit('closeComponent')
            }, 2200)
            this.$store.commit('updateBalance')
            return
          }
          this.qr = null
          this.refresh = true
        } catch (e) {
          this.$store.state.preLogin = false
          if (this.$store.state.iD) {
            return
          }
          this.qr = null
          this.refresh = true
        }
      }
    }
  }
}
</script>
