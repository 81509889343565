<template>
    <div class="PermissionsCreateView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Comercio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Crear Permiso.</div>
          </v-col>
        </v-row>
        <v-row v-if="!send">
          <v-col class="mx-5 mx-sm-15">
            <div class="form ml-1">
              <v-text-field class="my-3" label="iD" v-model="iD" outlined rounded hide-details />
              <v-autocomplete multiple label="Permisos" v-model="permissions" :items="permissionsList" item-value="value" item-text="name" outlined rounded />
              <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="create">Crear</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="send">
          <v-col align-self="center" align="center">
            <div v-if="failed">
              <v-icon size="128" color="red">mdi-stop-circle-outline</v-icon>
              <div class="display-1 text-uppercase my-3">
                Fallo en la creación de Permisos
              </div>
              <v-btn elevation="0" class="my-5 text-capitalize" rounded x-large color="customColor" dark @click="back">Volver</v-btn>
            </div>
            <div v-if="success">
              <v-icon size="128" color="success">mdi-check-circle-outline</v-icon>
              <div class="display-1 text-uppercase my-3">
                Permisos Creado
              </div>
              <v-btn elevation="0" class="my-5 text-capitalize" rounded x-large color="customColor" dark to="/c/permissions/list">Permisos</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_PermissionsCreateView.scss'
import { updateDoc, doc, getDoc } from 'firebase/firestore'
import { firestore } from '@/plugins/firebase'
export default {
  name: 'PermissionsCreateView',
  data () {
    return {
      permissionsList: [
        { name: 'Dashboard', value: 'dashboard' },
        { name: 'Recarga', value: 'recharge' },
        { name: 'Control de Acceso', value: 'access' },
        { name: 'Pagos', value: 'payment' }
      ],
      iD: '',
      permissions: '',
      send: false,
      success: false,
      failed: false
    }
  },
  methods: {
    back () {
      this.failed = false
      this.send = false
    },
    async create () {
      this.loading = true
      const merchant = this.$store.state.merchant.code

      const ref = await doc(firestore, 'Accesses', this.iD)
      const item = await getDoc(ref)
      const original = item.data()
      const data = {
      }
      data['apps.' + merchant] = this.permissions
      data.merchants = original ? [merchant] : original.merchants.push(merchant)

      try {
        const docRef = doc(firestore, 'Accesses', this.iD)
        updateDoc(docRef, data).then(() => {
          this.send = true
          this.success = true
        })
      } catch (e) {
        this.send = true
        this.failed = true
      }
      this.loading = false
    }
  }
}
</script>
