<template>
    <div class="BeneficiosUsuarioView">
      <v-container class="fill-height" fluid>
        <v-btn
            v-if="showScrollButton"
            fab
            bottom
            right
            fixed
            color="white"
            @click="scrollToTop"
          >
          <v-icon color="primary">
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              BENEFICIOS
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">Beneficios Usuario</div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Beneficios
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Accede a un mundo de beneficios al ser parte de PassPoint, no dejes de ser un miembro selecto de tus locales favoritos.
                PassPoint te abre las puertas a obtener descuentos, promociones, gift card, membresías y eventos, todo en un solo lugar, dando más valor a tu mismo dinero.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Compra de Puntos
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint ofrece flexibilidad a sus usuarios al permitirles comprar puntos utilizando diversos medios de pago, como tarjetas de crédito, débito, transferencias bancarias, entre otros. Una vez que los puntos han sido adquiridos, los usuarios tienen la opción de mantenerlos en un código QR o agregarlos a su tarjeta de puntos PassPoint. Esto facilita la administración y seguimiento de los puntos acumulados.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Canje de Puntos PassPoint
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Los Puntos PassPoint pueden ser canjeados por una amplia gama de productos y beneficios en los comercios asociados. Algunos ejemplos de recompensas incluyen descuentos en productos o servicios, tarjetas de regalo para usar en tiendas específicas, acceso a eventos exclusivos, promociones especiales y más.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Oportunidades PassPoint
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Al unirse a PassPoint, los usuarios tienen la oportunidad de maximizar el valor de sus compras y disfrutar de experiencias y recompensas únicas en los comercios asociados. Esto les permite ahorrar dinero y acceder a beneficios exclusivos que pueden mejorar su estilo de vida y bienestar en general.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
             </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_BeneficiosUsuarioView.scss'

export default {
  name: 'BeneficiosUsuarioView',
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
