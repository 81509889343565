import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import LandingView from '@/views/LandingView/LandingView.vue'
import HomeChileView from '@/views/LandingView/HomeChileView/HomeChileView.vue'
import LoadingView from '@/views/LoadingView/LoadingView.vue'
import HomePeruView from '@/views/LandingView/HomePeruView/HomePeruView.vue'
import CreateEventView from '@/views/PanelView/CreateEventView/CreateEventView.vue'
import SectionsView from '@/views/SectionsView/SectionsView.vue'
import buyPointsView from '@/views/SectionsView/BuyPoints/BuyPointsView/BuyPointsView.vue'
import eventsView from '@/views/SectionsView/EventsView/EventsView.vue'
import eventView from '@/views/SectionsView/EventView/EventView.vue'
import EmailConfirmationView from '@/views/SectionsView/EmailConfirmationView/EmailConfirmationView.vue'
import BerlinClubView from '@/views/SectionsView/Merchants/BerlinClubView/BerlinClubView.vue'
import MetronomoView from '@/views/SectionsView/Merchants/MertronomoView/MertronomoView.vue'
import DomingaView from '@/views/SectionsView/Merchants/DomingaView/DomingaView.vue'
import UsabilityView from '@/views/SectionsView/UsabilityView/UsabilityView.vue'
import ServicesView from '@/views/SectionsView/ServicesView/ServicesView.vue'
import MerchantControlView from '@/views/MerchantControlView/MerchantControlView.vue'
import EditProfileView from '@/views/MerchantControlView/EditProfileView/EditProfileView.vue'
import promotionsView from '@/views/SectionsView/PromotionsView/PromotionsView.vue'
import ShopsView from '@/views/SectionsView/ShopsView/ShopsView.vue'
import SportsView from '@/views/SectionsView/SportsView/SportsView.vue'
import TravelView from '@/views/SectionsView/TravelView/TravelView.vue'
import membershipsView from '@/views/SectionsView/MembershipsView/MembershipsView.vue'
import giftCardsView from '@/views/SectionsView/GiftCardsView/GiftCardsView.vue'
import appsView from '@/views/AppsView/AppsView.vue'
import accessControlView from '@/views/AppsView/AccessControlView/AccessControlView.vue'
import paymentView from '@/views/AppsView/PaymentView/PaymentView.vue'
import rechargeView from '@/views/AppsView/RechargeView/RechargeView.vue'
import homeControlView from '@/views/MerchantControlView/HomeControlView/HomeControlView.vue'
import membershipsListView from '@/views/MerchantControlView/MembershipsView/MembershipsView.vue'
import memberships2ListView from '@/views/MerchantControlView/Memberships2View/Memberships2View.vue'
import membershipInviteView
  from '@/views/MerchantControlView/MembershipsView/MembershipInviteView/MembershipInviteView.vue'
import membershipSalesView
  from '@/views/MerchantControlView/MembershipsView/MembershipSalesView/MembershipSalesView.vue'
import salesView from '@/views/MerchantControlView/SalesView/SalesView.vue'
import incomeView from '@/views/MerchantControlView/IncomeView/IncomeView.vue'
import transactionsView from '@/views/MerchantControlView/TransactionsView/TransactionsView.vue'
import WelcomeView from '@/views/WelcomeView/WelcomeView.vue'
import PanelView from '@/views/PanelView/PanelView.vue'
import EditClientProfileView from '@/views/PanelView/EditProfileView/EditProfileView.vue'
import MembershipsView from '@/views/PanelView/MembershipsView/MembershipsView.vue'
import EditEventView from '@/views/PanelView/EditEventView/EditEventView.vue'
import EventListView from '@/views/PanelView/EventListView/EventListView.vue'
import TicketsEventView from '@/views/PanelView/TicketsEventView/TicketsEventView.vue'
import ReportEventView from '@/views/PanelView/ReportEventView/ReportEventView.vue'
import createTicketEventView from '@/views/PanelView/CreateTicketEventView/CreateTicketEventView.vue'
import editTicketEventView from '@/views/PanelView/EditTicketEventView/EditTicketEventView.vue'
import registerProductorEventView from '@/views/PanelView/RegisterProductorEventView/RegisterProductorEventView.vue'
import ClientsListView from '@/views/PanelView/ClientsListView/ClientsListView.vue'
import CreateClientView from '@/views/PanelView/ClientsListView/CreateClientView/CreateClientView.vue'
import SetCountryView from '@/views/AppsView/SetCountryView/SetCountryView.vue'
import FAQView from '@/views/SectionsView/FAQView/FAQView.vue'
import clientsListView from '@/views/MerchantControlView/ClientsListView/ClientsListView.vue'
import LegalView from '@/views/SectionsView/LegalView/LegalView.vue'
import PrivacyView from '@/views/SectionsView/PrivacyView/PrivacyView.vue'
import DeleteView from '@/views/SectionsView/DeleteView/DeleteView.vue'
import createClientView from '@/views/MerchantControlView/ClientsListView/CreateClientView/CreateClientView.vue'
import NeroliView from '@/views/SectionsView/Merchants/NeroliView/NeroliView.vue'
import PatioView from '@/views/SectionsView/Merchants/PatioView/PatioView.vue'
import RedbullView from '@/views/SectionsView/Merchants/RedbullView/RedbullView.vue'
import SoporteView from '@/views/SectionsView/SoporteView/SoporteView.vue'
import SuccessView from '@/views/SectionsView/BuyPoints/SuccessView/SuccessView.vue'
import FailedView from '@/views/SectionsView/BuyPoints/FailedView/FailedView.vue'
import PasspointView from '@/views/SectionsView/PasspointView/PasspointView.vue'
import BeneficiosUsuarioView from '@/views/SectionsView/BeneficiosUsuarioView/BeneficiosUsuarioView.vue'
import BeneficiosEmpresaView from '@/views/SectionsView/BeneficiosEmpresaView/BeneficiosEmpresaView.vue'
import BeneficiosComercioView from '@/views/SectionsView/BeneficiosComercioView/BeneficiosComercioView.vue'
import NeroliPromoOneView from '@/views/SectionsView/MerchantPromotions/NeroliPromosView/NeroliPromoOneView.vue'
import permissionsView from '@/views/MerchantControlView/PermissionsView/PermissionsView.vue'
import permissionsCreateView
  from '@/views/MerchantControlView/PermissionsView/PermissionsCreateView/PermissionsCreateView.vue'
import TicketsView from '@/views/PanelView/TicketsView/TicketsView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: LoadingView
  },
  {
    path: '/acceso',
    beforeEnter () {
      window.location.href = '/apps/access-control/NcxpmUeqky3z0vmSbZux'
    }
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: WelcomeView
  },
  {
    path: '/verifyMail/:hash',
    name: 'emailConfirmation',
    component: EmailConfirmationView
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: SectionsView,
    children: [
      {
        path: '',
        name: 'Privacy',
        component: PrivacyView
      }
    ]
  },
  {
    path: '/delete',
    name: 'Delete',
    component: SectionsView,
    children: [
      {
        path: '',
        name: 'Delete',
        component: DeleteView
      }
    ]
  },
  {
    path: '/setCountry',
    name: 'setCountry',
    component: SetCountryView
  },
  {
    path: '/totem',
    name: 'totem',
    component: appsView,
    meta: {
      auth: true,
      showQR: true
    }
  },
  {
    path: '/default',
    name: 'landing',
    component: LandingView,
    children: [
      {
        path: '/chile',
        name: 'homeChile',
        component: HomeChileView,
        meta: {
          country: 'CL'
        }
      },
      {
        path: '/peru',
        name: 'homePeru',
        component: HomePeruView,
        meta: {
          country: 'PE'
        }
      }
    ]
  },
  {
    path: '/sections',
    name: 'Sections',
    component: SectionsView,
    children: [
      {
        path: '/:country/buy-points',
        name: 'buyPoints',
        component: buyPointsView,
        meta: {
          auth: true
        }
      },
      {
        path: '/:country/buy-points/:suggestedAmount',
        name: 'buyPoints',
        component: buyPointsView,
        meta: {
          auth: true
        }
      },
      {
        path: '/:country/success-buy-points',
        name: 'SuccessBuyPoints',
        component: SuccessView
      },
      {
        path: '/:country/failed-buy-points',
        name: 'FailedBuyPoints',
        component: FailedView
      },
      {
        path: '/:country/events',
        name: 'events',
        component: eventsView
      },
      {
        path: '/:country/passpoint',
        name: 'passpoint',
        component: PasspointView
      },
      {
        path: '/:country/usuario',
        name: 'usuario',
        component: BeneficiosUsuarioView
      },
      {
        path: '/:country/empresa',
        name: 'empresa',
        component: BeneficiosEmpresaView
      },
      {
        path: '/:country/comercio',
        name: 'comercio',
        component: BeneficiosComercioView
      },
      {
        path: '/:country/promotions',
        name: 'promotions',
        component: promotionsView
      },
      {
        path: '/:country/shops',
        name: 'shops',
        component: ShopsView
      },
      {
        path: '/:country/sports',
        name: 'sports',
        component: SportsView
      },
      {
        path: '/:country/travel',
        name: 'travel',
        component: TravelView
      },
      {
        path: '/:country/memberships',
        name: 'memberships',
        component: membershipsView
      },
      {
        path: '/:country/giftcards',
        name: 'giftcards',
        component: giftCardsView
      },
      {
        path: '/:country/event/:code',
        name: 'event',
        component: eventView
      },
      {
        path: '/:country/usability',
        name: 'usability',
        component: UsabilityView
      },
      {
        path: '/:country/services',
        name: 'services',
        component: ServicesView
      },
      {
        path: '/:country/m/berlin-club',
        name: 'merchantBerlinClub',
        component: BerlinClubView
      },
      {
        path: '/:country/m/redbull',
        name: 'merchantRedbull',
        component: RedbullView
      },
      {
        path: '/:country/m/metronomo',
        name: 'merchantMetronomo',
        component: MetronomoView
      },
      {
        path: '/:country/m/dominga',
        name: 'merchantDominga',
        component: DomingaView
      },
      {
        path: '/:country/m/neroli',
        name: 'merchantNeroli',
        component: NeroliView
      },
      {
        path: '/:country/m/patiobar',
        name: 'merchantPatioBar',
        component: PatioView
      },
      {
        path: '/:country/faq',
        name: 'FAQView',
        component: FAQView
      },
      {
        path: '/:country/soporte',
        name: 'SoporteView',
        component: SoporteView
      },
      {
        path: '/:country/legal',
        name: 'LegalView',
        component: LegalView
      },
      {
        path: '/:country/mp/nerolione',
        name: 'NeroliPromoOneView',
        component: NeroliPromoOneView
      }
    ]
  },
  {
    path: '/p',
    name: 'panel',
    component: PanelView,
    meta: {
      auth: true,
      showQR: true
    },
    children: [
      {
        path: '/p/memberships/list',
        name: 'panelMemberships',
        component: MembershipsView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/p/tickets/list',
        name: 'panelTickets',
        component: TicketsView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/p/profile/edit',
        name: 'panelProfile',
        component: EditClientProfileView,
        meta: {
          auth: true
        }
      },
      {
        path: '/p/events/activation',
        name: 'panelActivationEvent',
        component: registerProductorEventView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/p/events/create',
        name: 'panelCreateEvent',
        component: CreateEventView,
        meta: {
          auth: true
        }
      },
      {
        path: '/p/events/list',
        name: 'panelListEvent',
        component: EventListView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/p/clients/list',
        name: 'panelListClients',
        component: ClientsListView,
        meta: {
          auth: true
        }
      },
      {
        path: '/p/clients/create',
        name: 'panelCreateClient',
        component: CreateClientView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/p/events/:hash/edit',
        name: 'panelEditEvent',
        component: EditEventView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/p/events/:hash/tickets/create',
        name: 'panelCreateTicketEvent',
        component: createTicketEventView,
        meta: {
          auth: true
        }
      },
      {
        path: '/p/events/:hash/tickets/:id/edit',
        name: 'panelEditTicketEvent',
        component: editTicketEventView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/p/events/:hash/tickets/list',
        name: 'panelListTicketsEvent',
        component: TicketsEventView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/apps/report/events/:event',
        name: 'reportEvent',
        component: ReportEventView,
        meta: {
          auth: true,
          showQR: true
        }
      }
    ]
  },
  {
    path: '/apps',
    name: 'apps',
    component: appsView,
    meta: {
      auth: true,
      showQR: true
    }
  },
  {
    path: '/apps/access-control',
    name: 'accessControl',
    component: accessControlView,
    meta: {
      auth: true,
      showQR: true
    }
  },
  {
    path: '/apps/access-control/:event',
    name: 'accessControlEvent',
    component: accessControlView,
    meta: {
      auth: true,
      showQR: true
    }
  },
  {
    path: '/apps/payment',
    name: 'payment',
    component: paymentView,
    meta: {
      auth: true
    }
  },
  {
    path: '/apps/recharge',
    name: 'recharge',
    component: rechargeView,
    meta: {
      auth: true
    }
  },
  {
    path: '/c',
    name: 'merchantControl',
    component: MerchantControlView,
    meta: {
      auth: true,
      showQR: true
    },
    children: [
      {
        path: '/c/home',
        name: 'homeControl',
        component: homeControlView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/c/business/sales',
        name: 'sales',
        component: salesView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/c/business/income',
        name: 'income',
        component: incomeView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/c/business/transactions',
        name: 'transactions',
        component: transactionsView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/c/clients/list',
        name: 'clientsList',
        component: clientsListView,
        meta: {
          auth: true
        }
      },
      {
        path: '/c/clients/create',
        name: 'createClient',
        component: createClientView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/c/memberships/list',
        name: 'membershipsList',
        component: membershipsListView,
        meta: {
          auth: true
        }
      },
      {
        path: '/c/memberships2/list',
        name: 'memberships2List',
        component: memberships2ListView,
        meta: {
          auth: true
        }
      },
      {
        path: '/c/permissions/list',
        name: 'permissionsList',
        component: permissionsView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/c/permissions/create',
        name: 'permissionsCreate',
        component: permissionsCreateView,
        meta: {
          auth: true
        }
      },
      {
        path: '/c/memberships/invite',
        name: 'membershipInvite',
        component: membershipInviteView,
        meta: {
          auth: true,
          showQR: true
        }
      },
      {
        path: '/c/memberships/:iD/sales',
        name: 'membershipSales',
        component: membershipSalesView,
        meta: {
          auth: true
        }
      },
      {
        path: '/c/profile/edit',
        name: 'editMerchantProfile',
        component: EditProfileView,
        meta: {
          auth: true,
          showQR: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.params.country) {
    to.meta.country = Object.keys(store.state.countries).find(key => store.state.countries[key].toLowerCase() === to.params.country.toLowerCase())
  }
  /*   if (to.params.country) {
    if (to.params.country === 'chile') {
      to.meta.country = 'CL'
    }
    if (to.params.country === 'peru') {
      to.meta.country = 'PE'
    }
  }
  if (!to.meta.country) {
    to.meta.country = 'CL'
    next('chile')
  } */
  if (to.meta.auth && !store.state.useriD) {
    store.state.component = 'login'
  }
  next()
})

export default router
