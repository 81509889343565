<template>
    <div class="AppsView">
      <v-container class="fill-height">
        <v-row>
          <v-col align-self="center" align="center">
            <router-link to="/">
              <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.normal.svg')" max-width="200px" width="auto" max-height="100px" contain/>
              <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.dark.svg')" max-width="200px" width="auto" max-height="100px" contain/>
            </router-link>
          </v-col>
        </v-row>
        <v-row v-if="!iD || !currentMerchant">
          <v-col align-self="center" align="center">
            <v-autocomplete :error-messages="error" v-model="merchant" label="Seleccione Comercio" style="max-width: 300px" :items="merchants" item-value="code" item-text="name" />
            <v-btn :disabled="!merchant" elevation="0" color="customColor" dark class="px-5 py-4 my-10" large @click="setMerchant"><v-icon x-small>mdi-login</v-icon><span> Seleccionar</span></v-btn>
          </v-col>
        </v-row>
        <v-row v-if="iD && currentMerchant">
          <v-col>
            <div class="titleSection">
              Seleccionar una aplicación
            </div>
          </v-col>
        </v-row>
        <v-row v-if="iD && currentMerchant">
          <v-col align-self="center" align="center">
            <v-btn :disabled="!currentMerchant.granted.access.find(item => item.includes(this.iD))" dark color="customColor" outlined class="pa-10 appButton" to="/apps/access-control">
              <div>
              <v-icon>mdi-ticket-confirmation</v-icon>
                <div class="my-2">
                  Control de Acceso
                </div>
              </div>
            </v-btn>
          </v-col>
          <v-col align-self="center" align="center">
            <v-btn :disabled="!currentMerchant.granted.payment.find(item => item.includes(this.iD))" dark color="customColor" outlined class="pa-10 appButton" to="/apps/payment">
              <div>
                <v-icon>mdi-account-credit-card</v-icon>
                <div class="my-2">
                  Punto de Pago
                </div>
              </div>
            </v-btn>
          </v-col>
          <v-col align-self="center" align="center">
            <v-btn :disabled="!currentMerchant.granted.recharge.find(item => item.includes(this.iD))" dark color="customColor" outlined class="pa-10 appButton" to="/apps/recharge">
              <div>
                <v-icon>mdi-credit-card-plus-outline</v-icon>
                <div class="my-2">
                  Recargas
                </div>
              </div>
            </v-btn>
          </v-col>
          <v-col align-self="center" align="center">
            <v-btn :disabled="!currentMerchant.granted.dashboard.find(item => item.includes(this.iD))" dark color="customColor" outlined class="pa-10 appButton" to="/c/home">
              <div>
                <v-icon>mdi-monitor-dashboard</v-icon>
                <div class="my-2">
                  Dashboard
                </div>
              </div>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="currentMerchant">
          <v-col align-self="center" align="center">
            <v-btn outlined color="customColor" @click="clearMerchant">Cambiar de Comercio</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-btn fixed bottom right small icon @click="setThemeMode(!$vuetify.theme.dark)"><v-icon>mdi-brightness-6</v-icon></v-btn>
    </div>
</template>

<script>
import './_AppsView.scss'
export default {
  name: 'AppsView',
  data () {
    return {
      merchant: false,
      error: '',
      merchants: [
        {
          code: 'berlin',
          name: 'Berlin Club',
          description: 'Berlin Club',
          logo: require('@/assets/logos/merchants/clubberlin.svg'),
          type: 103,
          category: {
            type: [
              { level: 1, name: 'Classic', layout: require('@/assets/layouts/berlin/berlin.1.png') },
              { level: 2, name: 'Signature', layout: require('@/assets/layouts/berlin/berlin.2.png') },
              { level: 3, name: 'Infinite', layout: require('@/assets/layouts/berlin/berlin.3.png') },
              { level: 4, name: 'University', layout: require('@/assets/layouts/berlin/berlin.4.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'PassPoint',
          name: 'PassPoint',
          description: 'PassPoint',
          logo: require('@/assets/logos/passpoint.svg'),
          type: 99,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/passpoint/passpoint.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'passpoint',
          name: 'PassPoint',
          description: 'passpoint',
          logo: require('@/assets/logos/logo.normal.svg'),
          type: 101,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/passpoint/passpoint.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'dominga',
          name: 'Dominga Bistró',
          description: 'Dominga Bistró',
          logo: require('@/assets/logos/merchants/dominga.png'),
          type: 104,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/dominga/dominga.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'neroli',
          name: 'Neroli Studio 21',
          description: 'Neroli Studio 21',
          logo: require('@/assets/logos/merchants/neroli.png'),
          type: 105,
          category: {
            type: [
              { level: 1, name: 'Normal', layout: require('@/assets/layouts/neroli/neroli.1.png') },
              { level: 2, name: 'Premium', layout: require('@/assets/layouts/neroli/neroli.2.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'patio',
          name: 'Patio Bar',
          description: 'Patio Bar',
          logo: require('@/assets/logos/merchants/patio.png'),
          type: 106,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/patio/patio.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'phoenix',
          name: 'Phoenix',
          description: 'Phoenix',
          logo: require('@/assets/logos/logo.normal.svg'),
          type: 107,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/phoenix/phoenix.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'espaciopadel',
          name: 'Espacio Padel',
          description: 'Espacio Padel',
          logo: require('@/assets/logos/logo.normal.svg'),
          type: 108,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/espaciopadel/espaciopadel.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'benefit',
          name: 'BeneFit',
          description: 'BeneFit',
          logo: require('@/assets/logos/logo.normal.svg'),
          type: 109,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/benefit/benefit.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'passpointuniversity',
          name: 'Passpoint University',
          description: 'Passpoint University',
          logo: require('@/assets/logos/merchants/passpointuniversity.png'),
          type: 111,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/passpointuniversity/passpointuniversity.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'redbull',
          name: 'RedBull',
          description: 'RedBull',
          logo: require('@/assets/logos/merchants/redbull.png'),
          type: 112,
          category: {
            type: [
              { level: 0, name: 'Normal', layout: require('@/assets/layouts/redbull/redbull.png') }
            ],
            expire: 0
          }
        },
        {
          code: 'republica',
          name: 'Republica by BOIRE',
          description: 'Republica by BOIRE',
          logo: require('@/assets/logos/merchants/republica.png'),
          type: 102,
          category: {
            type: [
              { level: 1, name: 'Platinum' },
              { level: 2, name: 'Signature' },
              { level: 3, name: 'Infinite' }
            ],
            expire: 0
          }
        }
      ]
    }
  },
  mounted () {
    this.merchants.sort(function (a, b) {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })
  },
  computed: {
    iD () {
      return this.$store.state.useriD
    },
    currentMerchant () {
      return this.$store.state.merchant
    }
  },
  methods: {
    setThemeMode (style) {
      this.$vuetify.theme.dark = style
    },
    clearMerchant () {
      this.$store.state.merchant = false
    },
    hasAccess (user, moduleName) {
      if (user.apps.includes(moduleName)) return [this.iD]
      return []
    },
    async setMerchant () {
      this.error = ''
      this.$store.commit('activeLoading')
      const merchant = this.merchants.find(item => item.code === this.merchant)
      try {
        const access = (await this.axios.post('/thirdparty/passpoint/' + this.$store.state.useriD + '/access', {
          session: this.$store.state.session,
          merchant: {
            code: merchant.code,
            type: merchant.type === 101 ? 101 : merchant.type.toString()
          }
        })).data
        console.log(access)
        if (access.user.apps.includes('dashboard')) {
          this.$store.state.token = access.token
        }
        merchant.granted = {
          access: this.hasAccess(access.user, 'access'),
          payment: this.hasAccess(access.user, 'payment'),
          recharge: this.hasAccess(access.user, 'recharge'),
          dashboard: this.hasAccess(access.user, 'dashboard')
        }

        this.$store.state.merchant = merchant
      } catch (e) {
        this.error = 'Acceso Denegado'
        console.log(e)
        // Error
      } finally {
        this.$store.commit('closeLoading')
      }
    }
  }
}
</script>
