<template>
    <div class="EventsListComponent">
      <div class="searchContainer">
        <div align="right" class="searchButtonContainer">
        <v-btn elevation="0" dark class="searchButton" color="customColor" rounded large @click="runSearch">Buscar</v-btn>
        </div>
        <v-text-field solo rounded class="searchInput px-10" v-model="search" />
      </div>
      <v-container>
        <v-row>
          <v-col v-for="(event, key) in listEvents.slice(0, max)" :key="key" :cols="cols">
            <EventBoxComponent :event="event" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_EventsListComponent.scss'
import EventBoxComponent from '@/components/EventBoxComponent/EventBoxComponent.vue'
export default {
  name: 'EventsListComponent',
  components: { EventBoxComponent },
  props: {
    events: Array,
    max: Number
  },
  data () {
    return {
      listEvents: [],
      search: ''
    }
  },
  mounted () {
    this.listEvents = this.events
  },
  updated () {
  },
  computed: {
    cols () {
      const width = document.documentElement.clientWidth
      const widthVuetify = this.$vuetify.breakpoint.width
      if (width < 600 || widthVuetify < 600) {
        return 6
      }
      if (width < 960 || widthVuetify < 960) {
        return 4
      }
      if (width < 1280 || widthVuetify < 1280) {
        return 4
      }
      if (width < 1920 || widthVuetify < 1920) {
        return 3
      }
      return 2
    }
  },
  methods: {
    runSearch () {
      const data = this.search.split(' ')
      console.log(data)
      this.listEvents = []
      for (const event of this.events) {
        if (data.some(v => event.name.toLowerCase().includes(v.toLowerCase()))) {
          this.listEvents.push(event)
          continue
        }
        if (data.some(v => event.place.toLowerCase().includes(v.toLowerCase()))) {
          this.listEvents.push(event)
          continue
        }
        if (data.some(v => event.category.includes(v))) {
          this.listEvents.push(event)
          continue
        }
        if (data.some(v => event.tag.includes(v))) {
          this.listEvents.push(event)
          continue
        }
        if (data.some(v => event.city.includes(v))) {
          this.listEvents.push(event)
          continue
        }
      }
    }
  }
}
</script>
