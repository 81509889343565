<template>
    <div class="ServicesView">
      <v-container class="fill-height" fluid>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              Beneficios Passpoint
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">Beneficios Passpoint</div>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="titleSection py-15 title1">
              Beneficios Exclusivos
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-container>
              <v-row>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="01" title="Puntos" description="Canjea tus Puntos por una amplia gama de productos y beneficios." to="/chile/buy-points" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="02" title="Membresías" description="Trato preferencial, con multiples beneficios de acceso restringido para ti." to="/chile/memberships" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="03" title="Gift Cards" description="Déjate encantar y disfruta de los beneficios exclusivos del Club." to="/chile/giftcards" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="04" title="Promociones" description="Una gran variedad de promociones para darle exclusividad a tus puntos." to="/chile/promotions" />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="titleSection py-15 title1">
              Eventos
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-container>
              <v-row>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="01" title="Smart Tickets Online" description="Ofrece sTickets únicos via portal web, e-stores, RRSS. No hay límite." to="/" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="02" title="Ingresos Anticipados" description="Crea tu evento, realiza tu preventa y dispón de tus ingresos previo al show." to="/" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="03" title="Dashboards y Analítica" description="Control en tiempo real de la performance comercial de tu evento." to="/" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="04" title="Acceso Acreditado Ágil" description="Acredita público en forma expedita en múltiples puntos de ingreso." to="/" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="05" title="Ticketera a tu Medida" description="Marca exclusividad con una ticketera distintiva solo para tus eventos." to="/" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="06" title="Adverstising y Notificaciones" description="Crea expectación y más clientes con nuestro sistema de advertising." to="/" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="07" title="Plataforma Optimizada" description="Gestiona tus eventos, publicítalo, gestiona y envía eTickets." to="/" />
                </v-col>
                <v-col align-self="center" align="center" class="col-12 col-sm-6 col-lg-3">
                  <DescriptionCardComponent number="08" title="Servicios de Streaming" description="Tu evento en vivo y diferido hacia el mundo. Multiplica los ingresos." to="/" />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_ServicesView.scss'
import DescriptionCardComponent from '@/components/DescriptionCardComponent/DescriptionCardComponent.vue'
export default {
  name: 'ServicesView',
  components: { DescriptionCardComponent },
  data () {
    return {
      breadcrumbs: [
        {
          text: 'Inicio',
          to: '/'
        },
        {
          text: 'Servicios',
          to: '/services',
          disabled: true
        }
      ]
    }
  }
}
</script>
