<template>
    <div class="MertronomoView">
      <v-container fluid>
        <v-row>
          <v-col class="pa-0">
            <BannerTextComponent :image="require('@/assets/banners/merchants/metronomo-mockup01.png')" height="60vh" message="" />
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              <v-img :src="require('@/assets/logos/merchants/metronomo.svg')" max-width="200px" contain />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-3 pa-0" cols="12" md="4">
            <v-img :src="require('@/assets/sections/metromeno/fotos-01.png')" max-width="100%" contain />
          </v-col>
          <v-col class="pt-3 pa-0"  cols="12" md="4">
            <v-img :src="require('@/assets/sections/metromeno/fotos-02.png')" max-width="100%" contain />
          </v-col>
          <v-col class="pt-3 pa-0"  cols="12" md="4">
            <v-img :src="require('@/assets/sections/metromeno/fotos-03.png')" max-width="100%" contain />
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" class="pa-0" cols="12">
            <VideoBrandComponent title2="Metronomo" message1="The best underground venue" :video="require('@/assets/sections/berlin/ram.mp4')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="localName">Metronomo</div>
          </v-col>
        </v-row>
        <v-row v-if="false">
          <v-col class="pa-0" align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Events
            </div>
            <EventsListMerchantComponent :events="$store.state.events.filter(item => item.country === 'CL' && item.state === 'active')" :max="10" :key="$store.state.events.length" />
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Membresías
            </div>
            <v-container>
              <v-row>
                <v-col align-self="center" align="center">
                  <CardComponent :image="require('@/assets/sections/metromeno/metromeno_cards-03.png')" name="Signature Card" amount="500" revenue="10"  bgColor="#E9B746"/>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
        <v-row v-if="False">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Solicita Tu Membresía
            </div>
            <v-form v-model="valid">
              <v-container>
                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      v-model="customerName"
                      :rules="[rules.required]"
                      label="Nombre"
                      single-line
                      required
                      class="textBox px-3 py-0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" align-content="center"  class="py-0">
                    <v-text-field
                      v-model="customerPhone"
                      :rules="[rules.required]"
                      :counter="15"
                      label="Teléfono"
                      single-line
                      v-mask="'+## # #### ####'"
                      class="textBox  px-3 py-0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field
                      v-model="customerRut"
                      :rules="[rules.required, rules.rut]"
                      label="RUT"
                      single-line
                      class="textBox px-3 py-0"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="16" md="6"  class="py-0">
                    <v-text-field type="date"
                      v-model="customerBirthday"
                      :rules="[rules.required]"
                      label="Fecha Nacimiento"
                      single-line
                      class="textBox px-3 py-0"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <v-text-field type="email"
                      v-model="customerEmail"
                      :rules="[rules.required, rules.email]"
                      label="E-mail"
                      single-line
                      class="textBox px-3 py-0"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" align-self="left" class="checkTerminos">
                    <v-checkbox color="white" v-model="acepta"  px-10>
                      <template v-slot:label>
                        <div :style="{color: acepta?colorMsgOn:colorMsgOff}">
                        Acepto los términos y condiciones y entiendo que mis datos se conservarán de forma segura de acuerdo con la política de privacidad.
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="10" md="5" offset="1" align-self="left">
                    <v-btn elevation="0" class="btnSolicitar" @click="invite">Solicitar</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Como llegar
            </div>
            <MapComponent class="mb-16">
              <template v-slot:embed>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3064.606846416188!2d-73.24814232313825!3d-39.815809871542676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9615ef6b0534b021%3A0x4363ace5a30a71c3!2sClub%20Berl%C3%ADn!5e0!3m2!1ses-419!2scl!4v1689800933895!5m2!1ses-419!2scl" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </template>
            </MapComponent>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" class="py-20">
            <div><v-icon :color="colorIconFoot" large>mdi-map-marker</v-icon></div>
            <div class="my-4 white--text text-uppercase font-weight-bold">Ubicación</div>
            <div class="my-4 white--text font-weight-light">Ernesto Pinto Lagarrigue 179, 8420434 Recoleta, Región Metropolitana</div>
          </v-col>
          <v-col align-self="center" align="center" class="py-20">
            <div><v-icon :color="colorIconFoot" large>mdi-phone</v-icon></div>
            <div class="my-4 white--text text-uppercase font-weight-bold">Teléfono</div>
            <div class="my-4 white--text font-weight-light">+56 9 0000 0000</div>
          </v-col>
          <v-col align-self="center" align="center" class="py-20">
            <div><v-icon :color="colorIconFoot" large>mdi-email</v-icon></div>
            <div class="my-4 white--text text-uppercase font-weight-bold">Email</div>
            <div class="my-4 white--text font-weight-light">contacto@salametronomo.com</div>
          </v-col>
        </v-row>
        <v-row>
      </v-row>
      </v-container>
    </div>
</template>

<script>
import './_MertronomoView.scss'
import BannerTextComponent from '@/components/BannerTextComponent/BannerTextComponent.vue'
import VideoBrandComponent from '@/components/VideoBrandComponent/VideoBrandComponent.vue'
import EventsListMerchantComponent from '@/components/EventsListMerchantComponent/EventsListMerchantComponent.vue'
import MapComponent from '@/components/MapComponent/MapComponent.vue'
import CardComponent from '@/components/CardComponent/CardComponent.vue'

export default {
  name: 'MertronomoView',
  components: {
    CardComponent,
    MapComponent,
    VideoBrandComponent,
    EventsListMerchantComponent,
    BannerTextComponent
  },
  data () {
    return {
      colorIconFoot: '#E9B746',
      colorMsgOn: '#ffffff',
      colorMsgOff: '#a5a5a5',
      acepta: false,
      customerBirthday: '',
      customerEmail: '',
      customerName: '',
      customerPhone: '',
      customerRut: '',
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tus puntos'
        },
        {
          title: 'Paso 2',
          message: 'Compra tus puntos'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta tus puntos'
        }
      ],
      rules: {
        required: value => !!value || 'Campo obligatorio.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-Mail inválido.'
        },
        rut: value => {
          const pattern = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/
          console.log(pattern.test(value))
          return pattern.test(value) || 'Rut inválido. Ingresa puntos y guión (12.345.678-9)'
        },
        telefono: value => {

        }
      }
    }
  },
  computed: {
  },
  methods: {
    back () {
      this.failed = false
      this.send = false
    },
    async invite () {
      this.$store.commit('activeLoading')
      this.send = true
      // const type = this.$store.state.merchant.type
      const type = 103
      try {
        this.success = (await this.axios.post('/thirdparty/passpoint/cards/type/' + type, {
          name: this.customerName,
          email: this.customerEmail,
          clientData: {
            phone: this.customerPhone,
            birthdate: this.birthdate,
            dni: this.customerRut
          }
        })).data
      } catch (e) {
        this.failed = true
      } finally {
        this.$store.commit('closeLoading')
      }
    }
  }
}
</script>
