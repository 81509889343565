<template>
    <div class="ClientsListView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Negocio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Lista de Clientes.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <v-data-table
              v-model="selected"
              :loading="loading"
              :items-per-page="limit"
              :headers="clients.headers"
              :items="clients.data"
              :search="search"
              item-key="hash"
              :options.sync="clients.options"
              :single-expand="true"
              :expanded.sync="clients.expanded"
              show-select
              return-object
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-btn width="150" color="customColor" rounded elevation="0" class="mx-2" to="/c/clients/create">Crear Cliente</v-btn>
                  <v-btn width="150" color="customColor" rounded elevation="0" class="mx-2" @click="masive">Carga Masiva</v-btn>
                  <v-file-input v-show="false" ref="inputMasive" accept=".csv" @change="processMasive" />
                  <v-menu
                    bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn width="150" color="customColor" rounded elevation="0" class="mx-2" :disabled="selected.length === 0" v-on="on">Invitar</v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group
                      >
                        <v-list-item
                          v-for="category in $store.state.merchant.category.type"
                          :key="category.level"
                          @click="invite(category.level)"
                        >
                          {{ category.name }}
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>

                  <v-spacer></v-spacer>
                  <v-text-field hide-details v-model="search" dense label="Buscar" outlined rounded :append-icon="search.length > 0 ? 'mdi-close' : ''" @click:append="clearSearch" style="max-width: 400px" />
                </v-toolbar>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-10" style="background-color: #fafaff">
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-text-field label="iD" v-model="item.iD" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.hash)" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field label="Nombre" v-model="item.name" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field label="Correo" v-model="item.email" outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <strong>{{ item.name }}</strong>
              </template>
              <template v-slot:[`item.member`]="{ item }">
                {{ item.member }}
              </template>
              <template v-slot:[`item.created`]="{ item }">
                {{ getDateFormat(item.created) }}
              </template>
              <template v-slot:[`item.email`]="{ item }">
                {{ item.email }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="sendSnackbar" top centered>
        Enviado
      </v-snackbar>
      <v-snackbar v-model="saveSnackbar" top centered>
        Guardado
      </v-snackbar>
    </div>
</template>

<script>
import './_ClientsListView.scss'
import moment from 'moment'
import Papa from 'papaparse'

export default {
  name: 'ClientsListView',
  data () {
    return {
      sendSnackbar: false,
      saveSnackbar: false,
      selected: [],
      search: '',
      limit: 5,
      loading: false,
      producer: false,
      clients: {
        expanded: [],
        data: [],
        headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Miembro', value: 'member' },
          { text: 'Miembro desde', value: 'created' },
          { text: 'Email', value: 'email' },
          { text: '', value: 'actions' }
        ]
      }
    }
  },
  mounted () {
    this.getClients()
  },
  methods: {
    masive () {
      this.$refs.inputMasive.$refs.input.click()
    },
    processMasive (file) {
      this.$store.commit('activeLoading')
      Papa.parse(file, {
        complete: (response) => {
          const data = response.data
          data.shift()
          data.forEach(item => {
            if (item.length < 5) return
            this.axios.post('/thirdparty/passpoint/producers/' + this.$store.state.useriD + '/clients', {
              iD: this.$store.state.iD,
              session: this.$store.state.session,
              client: {
                name: item[0],
                email: item[1],
                country: item[2],
                dni: item[3],
                phone: item[4],
                birthdate: item[5]
              }
            }).then(() => {
              this.saveSnackbar = true
            })
          })
          this.getClients()
          this.$store.commit('closeLoading')
        }
      })
    },
    invite (level) {
      this.$store.commit('activeLoading')
      const type = this.$store.state.merchant.type
      const category = this.$store.state.merchant.category.type.find(cat => cat.level === level)
      this.selected.forEach(item => {
        this.axios.post('/thirdparty/passpoint/cards/type/' + type, {
          name: item.name,
          email: item.email,
          clientData: {
            phone: item.phone,
            birthdate: item.birthdate,
            dni: item.dni
          },
          iD: this.$store.state.iD,
          session: this.$store.state.session,
          category: category ? { name: category.name, level: category.level, expire: moment().add(1, 'month').unix() } : { expire: moment().unix() },
          merchant: {
            code: this.$store.state.merchant.code,
            type: this.$store.state.merchant.type.toString()
          }
        })
      })
      this.sendSnackbar = true
      this.selected = []
      this.$store.commit('closeLoading')
    },
    copy (iD) {
      this.copySnackbar = true
      navigator.clipboard.writeText(iD)
    },
    clearSearch () {
      this.search = ''
    },
    async searchName () {
      this.loading = true
    },
    getStringDateFormat (date) {
      if (date === undefined) {
        return
      }
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    },
    getDiffDay (date) {
      const days = moment(date, 'YYYY-MM-DDTHH:mm').diff(moment(), 'days')
      if (days === 0) {
        return 'Expira Hoy'
      }
      if (days > 0) {
        return 'Expira en ' + days.toString() + ' días'
      }
      if (days < 0) {
        return 'Expiró hace ' + Math.abs(days).toString() + ' días'
      }
    },
    async getBalance (items) {
      if (items.length === 0) {
        return
      }
      const iD = items[0].iD
      console.log(iD)
    },
    getClients () {
      this.$store.commit('activeLoading')
      this.$axios.get('/thirdparty/passpoint/producers/' + this.$store.state.useriD, {
        params: {
          iD: this.$store.state.iD,
          session: this.$store.state.session
        }
      }).then(response => {
        console.log(response.data)
        this.producer = response.data
        this.clients.data = response.data.clients
        this.$store.commit('closeLoading')
      }).catch((error) => {
        console.log(error)
        this.clients.data = []
        this.$store.commit('closeLoading')
      })
    }
  }
}
</script>
