<template>
    <div class="MapComponent">
        <slot name="embed"></slot>
    </div>
</template>

<script>
import './_MapComponent.scss'
export default {
  name: 'MapComponent'
}
</script>
