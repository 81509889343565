<template>
    <div class="BannerTextComponent">
        <v-img :src="image" width="100%" :height="height" content-class="message">
          {{ message }}
        </v-img>
    </div>
</template>

<script>
import './_BannerTextComponent.scss'
export default {
  name: 'BannerTextComponent',
  props: {
    image: String,
    height: String,
    message: String
  }
}
</script>
