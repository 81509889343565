<template>
  <v-app>
    <v-main>
        <router-view/>
    </v-main>
    <LoginComponent v-if="component === 'login'" />
    <AccessControlRecordsComponent v-if="component === 'accessControlRecords'" />
    <PaymentRecordsComponent v-if="component === 'paymentRecords'" />
    <RechargeRecordsComponent v-if="component === 'rechargeRecords'" />
    <transition name="fade">
      <div class="loading" v-if="loading">
        <v-progress-linear indeterminate color="customColor" height="15px" />
      </div>
    </transition>
    <v-snackbar v-model="$store.state.saveSnackbar" top centered :timeout="3000">
      Guardado
    </v-snackbar>
  </v-app>
</template>

<script>
import '@/scss/effects.scss'
import '@/scss/default.scss'
import '@/scss/animations.scss'
import LoginComponent from '@/components/LoginComponent/LoginComponent.vue'
import AccessControlRecordsComponent from '@/components/AccessControlRecordsComponent/AccessControlRecordsComponent.vue'
import PaymentRecordsComponent from '@/components/PaymentRecordsComponent/PaymentRecordsComponent.vue'
import RechargeRecordsComponent from '@/components/RechargeRecordsComponent/RechargeRecordsComponent.vue'

export default {
  name: 'App',
  components: { RechargeRecordsComponent, PaymentRecordsComponent, AccessControlRecordsComponent, LoginComponent },

  data: () => ({
    //
  }),
  mounted () {
    this.$store.commit('loadSession')
    this.$store.commit('setCountry', { country: 'CL' })
    /*    this.$axios.get('https://api.country.is').then(response => {
      const data = response.data
      this.$store.commit('setCountry', { country: data.country })
    }) */
  },
  computed: {
    component () {
      return this.$store.state.component
    },
    loading () {
      return this.$store.state.loading
    }
  }
}
</script>
