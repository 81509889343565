<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col align-self="center" align="center">
        <div class="VideoBrandComponent">
          <div align="left" class="boxTitle py-20">
            <div class="locationTitle mb-5">{{ title }}</div>
            <div class="locationTitle2 mb-5">{{ title2 }}</div>
            <div class="sloganTitle">
              <div>{{ message1 }}</div>
              <div>{{ message2 }}</div>
            </div>
          </div>
          <div class="pb-16 video">
            <video-player :options="playerOptions" :playsinline="true" />
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import './_VideoBrandComponent.scss'
export default {
  name: 'VideoBrandComponent',
  props: {
    title: String,
    title2: String,
    message1: String,
    message2: String,
    video: String
  },
  data () {
    return {
      playerOptions: {
        muted: true,
        width: document.documentElement.clientWidth * 0.6,
        height: 600,
        sources: [{
          type: 'video/mp4',
          src: this.video
        }]
      }
    }
  }
}
</script>
