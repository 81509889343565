<template>
    <div class="AccessControlRecordsComponent">
        <v-container class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="titleSection">Accesos</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div class="recordsContainer">
                <div v-for="(record, index) in $store.state.logs.access" :key="index" align="left" class="my-2">
                  <div class="d-flex justify-space-between align-end">
                    <div class="caption">{{ getDateFormat(record.date) }}</div>
                    <div>
                      <v-icon v-if="record.state" color="success">mdi-circle</v-icon>
                      <v-icon v-if="!record.state" color="error">mdi-circle</v-icon>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                      <div>{{ record.name }}</div>
                      <div>{{ record.member }}</div>
                  </div>
                  <hr style="opacity: 0.5" />
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div>
                <div class="font-weight-bold">N°</div>
                <div class="display-2">{{ $store.state.logs.access.length }} <span class="caption">Accesos</span></div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <v-btn elevation="0" color="customColor" dark outlined @click="$store.commit('closeComponent')">Cerrar</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_AccessControlRecordsComponent.scss'
import moment from 'moment'
export default {
  name: 'AccessControlRecordsComponent',
  methods: {
    getDateFormat (timestamp) {
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    }
  }
}
</script>
