<template>
    <div class="BrandingSliderComponent">
      <VueSlickCarousel v-bind="settings">
        <div v-for="(image, key) in images" :key="key" class="px-5">
          <v-img :src="image" max-height="150px" contain />
        </div>
      </VueSlickCarousel>
    </div>
</template>

<script>
import './_BrandingSliderComponent.scss'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'BrandingSliderComponent',
  components: { VueSlickCarousel },
  props: {
    images: Array
  },
  data () {
    return {
      settings: {
        dots: false,
        infinite: true,
        slidesToShow: this.$vuetify.breakpoint.lgAndUp ? 4 : this.$vuetify.breakpoint.mdAndUp ? 4 : this.$vuetify.breakpoint.smAndUp ? 3 : this.$vuetify.breakpoint.xs ? 1 : 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        pauseOnDotsHover: true,
        pauseOnFocus: true,
        pauseOnHover: true
      }
    }
  },
  updated () {
    this.settings.slidesToShow = this.$vuetify.breakpoint.lgAndUp ? 3 : this.$vuetify.breakpoint.mdAndUp ? 4 : this.$vuetify.breakpoint.smAndUp ? 3 : this.$vuetify.breakpoint.xs ? 1 : 1
  }
}
</script>
