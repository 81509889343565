<template>
    <div class="RechargeView">
      <QrcodeStream @decode="onDecode" v-if="scanning" />
      <div class="scanning" v-if="scanning">
        <div class="box"></div>
        <div class="close">
          <v-btn elevation="0" dark @click="scanning = false" class="mx-2 my-5" color="customColor">Volver</v-btn>
        </div>
      </div>
      <v-app-bar app v-if="!summary && !scanning">
        <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.normal.svg')" max-width="200px" width="auto" max-height="100px" contain/>
        <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.dark.svg')" max-width="200px" width="auto" max-height="100px" contain/>
        <v-spacer />
        <v-btn color="customColor" elevation="0" to="/apps" icon plain tile small><v-icon small>mdi-apps</v-icon></v-btn>
        <v-btn dark outlined color="customColor" @click="$store.commit('setComponent', { component: 'rechargeRecords' })">Recargas</v-btn>
      </v-app-bar>
      <v-container class="fill-height">
        <v-row>
          <v-col align-self="center" align="center">
            <v-img :src="$store.state.merchant.logo" max-width="200px" width="auto" max-height="100px" contain />
          </v-col>
        </v-row>
        <v-row v-if="!summary">
          <v-col align-self="center" align="center">
            <div class="titleSection">iD</div>
            <v-text-field class="boxField" outlined flat color="customColor" v-model="iD" style="max-width: 300px" append-icon="mdi-qrcode" @click:append="scan" />
            <div class="titleSection">Monto</div>
            <v-text-field type="number" class="boxField" outlined flat color="customColor" v-model="amount" suffix="Puntos" style="max-width: 300px" />
          </v-col>
        </v-row>
        <v-row v-if="summary && success">
          <v-col align-self="center" align="center">
            <div>
              <v-icon size="128" color="success">mdi-check-circle-outline</v-icon>
              <div class="display-1 text-uppercase my-3">
                Transferido
              </div>
              <div class="scale-8">
                <div class="font-weight-bold">Código de Transacción</div>
                <div class="display-2">{{ receipt.transactionHash.slice(-16) }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="summary && failed">
          <v-col align-self="center" align="center" class="py-15">
            <div>
              <v-icon size="128" color="red">mdi-stop-circle-outline</v-icon>
              <div class="display-1 text-uppercase my-3">
                Rechazado
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center">
            <div class="font-weight-bold">Recarga</div>
            <div class="display-2">{{ amount | numFormat('0,000.[00]') }} <span class="caption">Puntos</span></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-btn v-if="!summary" @click="generateRecharge" dark x-large class="mx-2" elevation="0" color="customColor">Generar Recarga</v-btn>
            <div v-if="failed">
              <v-btn @click="retry" dark class="mx-2 my-10" elevation="0" large color="customColor">Reintentar</v-btn>
            </div>
            <v-btn v-if="summary" @click="close" dark class="mx-2 my-10" elevation="0" color="customColor">Volver</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.normal.alt.svg')" max-width="200px" width="auto" max-height="40px" contain/>
            <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/logos/logo.enterprices.dark.alt.svg')" max-width="200px" width="auto" max-height="40px" contain/>
          </v-col>
        </v-row>
      </v-container>
      <v-btn fixed bottom right small icon @click="setThemeMode(!$vuetify.theme.dark)"><v-icon>mdi-brightness-6</v-icon></v-btn>
    </div>
</template>

<script>
import './_RechargeView.scss'
import { QrcodeStream } from 'vue-qrcode-reader'
import moment from 'moment'
export default {
  name: 'RechargeView',
  components: { QrcodeStream },
  data () {
    return {
      iD: '',
      amount: null,
      type: 0,
      summary: false,
      success: false,
      failed: false,
      receipt: false,
      scanning: false
    }
  },
  mounted () {
    this.type = this.$store.state.merchant.type
  },
  methods: {
    retry () {
      this.summary = false
      this.failed = false
    },
    scan () {
      this.scanning = true
    },
    onDecode (data) {
      this.iD = data
      this.scanning = false
    },
    close () {
      this.iD = ''
      this.amount = null
      this.summary = false
      this.success = false
      this.failed = false
    },
    async generateRecharge () {
      this.summary = true
      this.$store.commit('activeLoading')
      try {
        this.receipt = (await this.axios.put('/thirdparty/passpoint/send/' + this.iD + '/type/' + this.type, {
          amount: this.amount,
          session: this.$store.state.session,
          iD: this.$store.state.useriD
        })).data
        this.success = true
        this.$store.commit('addRechargeRecord',
          {
            date: moment().unix(),
            amount: this.amount,
            shortTx: this.receipt.transactionHash.slice(-16),
            iD: this.iD
          })
      } catch (e) {
        this.failed = true
      } finally {
        this.$store.commit('closeLoading')
      }
    },
    setThemeMode (style) {
      this.$vuetify.theme.dark = style
    }
  }
}
</script>
