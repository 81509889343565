<template>
    <div class="EmailConfirmationView">
      <v-container fluid>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="py-20">
              <v-img :src="require('@/assets/logos/logo.normal.svg')" max-width="250px" width="auto" contain>

            </v-img>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="mainMessage py-5">
              Su correo ha sido verificado con éxito
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="bottomMessage py-5">
              Gracias por registrarse en el club de beneficios <B>Passpoint</B>, muy pronto tendremos un mundo de novedades para ti
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-btn x-large to="/" color="customColor" outlined rounded class="pa-5">Ir al Sitio</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_EmailConfirmationView.scss'

export default {
  name: 'EmailConfirmationView',
  components: { },
  async mounted () {
    const hash = this.$route.params.hash
    try {
      await this.$axios.put('/thirdparty/passpoint/email/' + hash + '/verify').then()
    } catch (e) {
      this.$router.push('/')
    }
  },
  data () {
    return {
    }
  }
}
</script>
