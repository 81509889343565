<template>
    <div class="RegisterProductorEventView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Eventos</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Activación de Cuenta.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15">
            <div class="form ml-1">
              <v-form class="pa-10" v-if="account">
                <v-text-field readonly v-model="account.name" class="my-3" outlined rounded label="Nombre de la Productora" />
                <v-text-field readonly v-model="account.description" class="my-3" outlined rounded label="Descripción de la Productora" />
                <v-text-field readonly v-model="account.dni" class="my-3" outlined rounded label="DNI de la Productora" />
                <v-text-field readonly v-model="account.address" class="my-3" outlined rounded label="DNI de la Productora" />
                <v-text-field readonly v-model="account.legalRepresentativeDni" class="my-3" outlined rounded label="DNI del Representante Legal" />
                <v-text-field readonly v-model="account.legalRepresentativeName" class="my-3" outlined rounded label="Nombre del Representante Legal" />
                <div class="d-flex">
                  <v-text-field readonly v-model="account.email" type="email" class="mx-1 my-3" outlined rounded label="Email" />
                  <v-text-field readonly v-model="account.phone" type="phone" class="mx-1 my-3" outlined rounded label="Teléfono de Contacto" />
                </div>
                <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="processRequest">Solicitar Activación</v-btn>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
export default {
  name: 'RegisterProductorEventView',
  data () {
    return {
      account: {
        name: '',
        description: '',
        dni: '',
        email: '',
        address: '',
        phone: '',
        legalRepresentativeName: '',
        legalRepresentativeDni: '',
        legalActivity: null
      }
    }
  }
}
</script>
