<template>
    <div class="Memberships2View SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Negocio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Membresías..</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <v-data-table
              v-if="cards.total > 0"
                          v-model="selected"
                          :loading="loading"
                          :items-per-page="limit"
                          :headers="cards.headers"
                          :items="cards.data.concat(Array(cards.total - cards.data.length).fill({}))"
                          item-key="iD"
                          :options.sync="cards.options"
                          @update:items-per-page="getUpdateLimit"
                          @update:expanded="getBalance"
                          @update:page="updatePage"
                          disable-sort
                          :single-expand="true"
                          :expanded.sync="cards.expanded"
                          show-expand
                          show-select
                          return-object
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-btn v-if="$store.state.merchant.type !== 101" width="150" color="customColor" rounded elevation="0" class="mx-2" to="/c/memberships/invite">Crear</v-btn>
                  <v-btn v-if="$store.state.merchant.type !== 101" :disabled="!selected.length" width="150" color="customColor" rounded elevation="0" class="mx-2" @click="showCategory">Categoria</v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field @keydown.enter.prevent="searchName" hide-details v-model="search" dense label="Buscar Correo" outlined rounded :append-icon="search.length > 0 ? 'mdi-close' : ''" @click:append="clearSearch" style="max-width: 400px" />
                  <v-btn color="customColor" rounded elevation="0" class="mx-2" @click="searchName">Buscar</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-spacer />
                <v-btn v-if="false" :disabled="cards.total === cards.data.length" x-small color="customColor" rounded elevation="0" class="mx-2" @click="loadMore">Cargar Más</v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-10" style="background-color: #fafaff">
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-text-field label="iD" v-model="item.iD" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.iD)" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-if="item.client" label="Nombre" v-model="item.client.name" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field :readonly="$store.state.merchant.type === 101" v-if="item.client" label="Correo" v-model="item.client.email" outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-if="item.client" label="DNI" v-mask="'##.###.###-N'" v-model="item.client.dni" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field v-if="item.client" label="Cumpleaños" type="date" v-model="item.client.birthdate" outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-if="item.client" type="phone" v-mask="'+## # #### ####'" label="Teléfono" v-model="item.client.phone" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field label="Miembro N°" v-model="item.member" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field label="Saldo" v-model="cards.balance" readonly suffix="Puntos" outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row v-if="$store.state.merchant.type !== 101">
                      <v-col>
                          <v-select label="Categoría" clearable v-model="item.category.level" :items="$store.state.merchant.category.type" item-text="name" item-value="level" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field type="datetime-local" label="Expiración de Categoría" v-model="item.category.expire" outlined rounded color="customColor" :messages="getDiffDay(item.category.expire)" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="save(item)">Guardar</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <strong>{{ item.name }}</strong>
              </template>
              <template v-slot:[`item.created`]="{ item }">
                {{ getDateFormat(item.created) }}
              </template>
              <template v-if="$store.state.merchant.type !== 101" v-slot:[`item.actions`]="{ item }">
                <v-btn icon :to="{ name: 'membershipSales', params: { iD: item.iD } }"><v-icon>mdi-sale</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="copySnackbar" top centered>
        Copiado
      </v-snackbar>
      <v-snackbar v-model="saveSnackbar" top centered>
        Guardado
      </v-snackbar>
      <v-snackbar v-model="notFoundSnackbar" top centered>
        Sin resultados
      </v-snackbar>
      <v-dialog v-model="category" v-if="category">
        <v-card>
          <v-card-title>
          Cambio de Categoria Masivo
          </v-card-title>
          <v-card-actions>
            <v-container>
              <v-row>
              <v-col></v-col>
              </v-row>
                <v-row>
                  <v-col>
                      <v-select label="Categoría" clearable v-model="category.level" :items="$store.state.merchant.category.type" item-text="name" item-value="level" outlined rounded color="customColor" hide-details />
                  </v-col>
                  <v-col>
                    <v-text-field type="datetime-local" label="Expiración de Categoría" v-model="category.expire" outlined rounded color="customColor" :messages="getDiffDay(category.expire)" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="caption">
                  Se procesaran {{ selected.length }} membresías
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                  <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="processCategory">Guardar</v-btn>
                  <v-btn elevation="0" class="my-5 text-capitalize" rounded block large color="black" dark @click="category = false">Cerrar</v-btn>
                  </v-col>
                </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import './_Memberships2View.scss'
import { firestore } from '@/plugins/firebase'
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  getCountFromServer,
  startAfter,
  updateDoc,
  doc,
  orderBy
} from 'firebase/firestore'
import moment from 'moment/moment'
import { utils } from 'ethers'

export default {
  name: 'Memberships2View',
  data () {
    return {
      category: false,
      copySnackbar: false,
      saveSnackbar: false,
      notFoundSnackbar: false,
      limit: 5,
      selected: [],
      loading: false,
      search: '',
      cards: {
        balance: '',
        expanded: [],
        data: [],
        last: null,
        total: 0,
        options: {},
        headers: [
          { text: 'Nombre', value: 'client.name' },
          { text: 'Miembro', value: 'member' },
          { text: 'Creado', value: 'created' },
          { text: 'Correo', value: 'client.email' },
          { text: '', value: 'actions' }
        ]
      }
    }
  },
  async mounted () {
    await this.getCount()
    this.getCards()
  },
  methods: {
    processCategory () {
      this.$store.commit('activeLoading')
      this.selected.forEach(item => {
        item.category.level = this.category.level
        item.category.expire = this.category.expire
        this.save(item)
      })
      this.$store.commit('closeLoading')
      this.category = false
    },
    showCategory () {
      this.category = { level: 0, expire: 0 }
    },
    save (item) {
      const category = this.$store.state.merchant.category.type.find(cat => cat.level === item.category.level)
      const data = {
        updated: moment().unix(),
        client: item.client,
        'data.clientName': item.client.name,
        'data.clientEmail': item.client.email,
        'data.category': { level: category ? category.level : 0, name: category ? category.name : '', expire: moment(item.category.expire, 'YYYY-MM-DDTHH:mm').unix() }
      }
      const docRef = doc(firestore, 'Cards', item.key)
      updateDoc(docRef, data).then(() => {
        this.saveSnackbar = true
      })
    },
    copy (iD) {
      this.copySnackbar = true
      navigator.clipboard.writeText(iD)
    },
    async getCount () {
      const type = this.$store.state.merchant.type
      const querySnapshot = await getCountFromServer(query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString())))
      this.cards.total = querySnapshot.data().count
    },
    async getBalance (items) {
      this.cards.balance = ''
      if (items.length === 0) {
        return
      }
      const iD = items[0].iD
      const contracts = this.$store.state.contracts
      const type = this.$store.state.merchant.type
      const contract = contracts.find(item => item.type.toString() === type.toString())

      if (!contract) {
        this.cards.balance = '0'
      }
      this.$axios.get('/network/addresses/' + iD + '/balance/' + contract.iD).then(response => {
        const data = response.data
        this.cards.balance = utils.formatUnits(data, contract.decimals)
      })
    },
    getUpdateLimit (limit) {
      this.limit = limit
      if (limit === -1) {
        this.limit = 1000
      }
      this.cards.data = []
      this.getCards()
    },
    async clearSearch () {
      this.search = ''
      this.cards.data = []
      await this.getCount()
      this.getCards()
    },
    async searchName () {
      this.loading = true
      const type = this.$store.state.merchant.type
      // .collection("Cards").where("client.email", "==", "vasquezcecilia33@gmail.com")
      const ref = await query(
        collection(firestore, 'Cards'),
        where('type', '==', type === 101 ? 101 : type.toString()),
        where('client.email', '==', this.search),
        limit(this.limit))
      const querySnapshot = await getDocs(ref)
      if (querySnapshot.docs.length === 0) {
        this.notFoundSnackbar = true
        this.loading = false
        return
      }
      this.cards.data = []
      this.cards.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      this.cards.total = querySnapshot.docs.length
      querySnapshot.forEach(doc => {
        const data = doc.data()
        console.log(data)
        this.cards.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          member: data.data.member,
          client: data.client ? data.client : { name: data.data.clientName, email: data.data.clientEmail },
          category: data.data.category ? { name: data.data.category.name, level: data.data.category.level, expire: moment.unix(data.data.category.expire).format('YYYY-MM-DDTHH:mm') } : { name: '', level: 0, expire: moment().format('YYYY-MM-DDTHH:mm') }
        })
      })
      this.loading = false
    },
    async updatePage (page) {
      if (this.search.length > 0) {
        return
      }
      this.loading = true
      if (this.cards.data.length < page * this.limit) {
        const type = this.$store.state.merchant.type
        const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), orderBy('created', 'desc'), startAfter(this.cards.last), limit(this.limit))
        const querySnapshot = await getDocs(ref)
        this.cards.last = querySnapshot.docs[querySnapshot.docs.length - 1]
        querySnapshot.forEach(doc => {
          const data = doc.data()
          this.cards.data.push({
            key: doc.id,
            iD: data.iD,
            created: data.created,
            member: data.data.member,
            client: data.client ? data.client : { name: data.data.clientName, email: data.data.clientEmail },
            category: data.data.category ? { name: data.data.category.name, level: data.data.category.level, expire: moment.unix(data.data.category.expire).format('YYYY-MM-DDTHH:mm') } : { name: '', level: 0, expire: moment().format('YYYY-MM-DDTHH:mm') }
          })
        })
      }
      this.loading = false
    },
    async loadMore () {
      if (this.search.length > 0) {
        return
      }
      if (this.cards.data.length > this.cards.total) {
        return
      }
      this.loading = true
      const type = this.$store.state.merchant.type
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), orderBy('created', 'desc'), startAfter(this.cards.last), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      this.cards.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.cards.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          member: data.data.member,
          client: data.client ? data.client : { name: data.data.clientName, email: data.data.clientEmail },
          category: data.data.category ? { name: data.data.category.name, level: data.data.category.level, expire: moment.unix(data.data.category.expire).format('YYYY-MM-DDTHH:mm') } : { name: '', level: 0, expire: moment().format('YYYY-MM-DDTHH:mm') }
        })
      })
      this.cards.options.page++
      this.loading = false
    },
    toCapital (s) {
      if (typeof s !== 'string' || s.length === 0) {
        return ''
      }
      const sf = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
      return sf
    },
    async getCards () {
      this.loading = true
      const type = this.$store.state.merchant.type
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), orderBy('created', 'desc'), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      console.log(querySnapshot.docs)
      this.cards.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.cards.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          member: data.data.member,
          client: data.client ? data.client : { name: data.data.clientName, email: data.data.clientEmail },
          category: data.data.category ? { name: data.data.category.name, level: data.data.category.level, expire: moment.unix(data.data.category.expire).format('YYYY-MM-DDTHH:mm') } : { name: '', level: 0, expire: moment().format('YYYY-MM-DDTHH:mm') }
        })
      })
      this.loading = false
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    },
    getDiffDay (date) {
      const days = moment(date, 'YYYY-MM-DDTHH:mm').diff(moment(), 'days')
      if (days === 0) {
        return 'Expira Hoy'
      }
      if (days > 0) {
        return 'Expira en ' + days.toString() + ' días'
      }
      if (days < 0) {
        return 'Expiró hace ' + Math.abs(days).toString() + ' días'
      }
    }
  }
}
</script>
