<template>
    <div class="TicketsEventView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Evento - <template v-if="event.event">{{ event.event.name }}</template></div>
            <div class="mx-5 mx-sm-15 titleControlSection">Tickets</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <v-data-table
              :loading="loading"
              :items-per-page="limit"
              :headers="tickets.headers"
              :items="tickets.data"
              :search="search "
              item-key="hash"
              :sort-by="['price']"
              :options.sync="tickets.options"
              @update:expanded="getBalance"
              :single-expand="true"
              :expanded.sync="tickets.expanded"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-btn width="150" color="customColor" rounded elevation="0" class="mx-2" :to="{name: 'panelCreateTicketEvent', params: { hash: hash }}">Crear Tickets</v-btn>
                  <v-spacer></v-spacer>
                  <v-text-field hide-details v-model="search" dense label="Buscar" outlined rounded :append-icon="search.length > 0 ? 'mdi-close' : ''" @click:append="clearSearch" style="max-width: 400px" />
                </v-toolbar>
              </template>
              <template v-slot:[`item.primaryImage`]="{ item }">
                <v-img :src="item.primaryImage" class="my-2" width="20vw" max-width="100px" contain style="border-radius: 4px"></v-img>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <strong>{{ item.name }}</strong>
              </template>
              <template v-slot:[`item.start`]="{ item }">
                {{ getStringDateFormat(item.start) }}
              </template>
              <template v-slot:[`item.price`]="{ item }">
                <strong>{{ item.price }} puntos</strong>
              </template>
              <template v-slot:[`item.quantity`]="{ item }">
                {{ item.sold }} de {{ item.quantity }} Tickets
              </template>
              <template v-slot:[`item.state`]="{ item }">
                <v-chip v-if="item.state === 'Cortesías'" color="yellow">Creado</v-chip>
                <v-chip v-if="item.state === 'Agotados'" color="orange">En revisión</v-chip>
                <v-chip v-if="item.state === 'Activo'" color="green">Activo</v-chip>
                <v-chip v-if="item.state === 'Inactivo'" color="grey">Inactivo</v-chip>
                <v-chip v-if="item.state === 'Cerrado'" color="cyan">Finalizado</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon :to="{ name: 'panelEditTicketEvent', params: { hash: hash, id: item.hash } }"><v-icon>mdi-note-edit-outline</v-icon></v-btn>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="sendTicket(hash, item.hash)" v-bind="attrs" v-on="on" icon><v-icon>mdi-receipt-text-send-outline</v-icon></v-btn>
                  </template>
                  <span>Enviar Ticket</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="massive(hash, item.hash)" v-bind="attrs" v-on="on" icon><v-icon>mdi-receipt-text-send</v-icon></v-btn>
                  </template>
                  <span>Envío Masivo de Ticket</span>
                </v-tooltip>
                <v-file-input v-show="false" ref="inputMasive" accept=".csv" @change="processingMassiveSendTicket" />
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="ticketMasivoSnackbar" top centered>
        Tickest Enviados
      </v-snackbar>
      <v-snackbar v-model="ticketSnackbar" top centered>
        Ticket Enviado
      </v-snackbar>
      <v-snackbar v-model="copySnackbar" top centered>
        Copiado
      </v-snackbar>
      <v-snackbar v-model="saveSnackbar" top centered>
        Guardado
      </v-snackbar>
      <v-dialog v-model="dialogSendTicket" max-width="800px">
        <v-card class="py-10">
          <v-card-title class="py-3">
            <div align="center" style="width: 100%">
              Enviar Ticket
            </div>
          </v-card-title>
          <v-card-text>
            <div align="center">
            <v-text-field :error-messages="errors.email" class="ma-2" outlined rounded label="Email" v-model="sendTicketData.email" />
            <v-btn @click="searchEmail" width="150" color="customColor" rounded elevation="0" class="mx-2">Buscar</v-btn>
            <v-text-field class="ma-2" outlined rounded label="iD" v-model="sendTicketData.to" hide-details />
            <template>
              <v-container>{{ msgTicketsSent }}</v-container>
            </template>
            </div>
          </v-card-text>
          <v-card-actions>
            <div align="center" style="width: 100%" class="mb-5">
              <v-btn :disabled="!sendTicketData.to" width="150" color="customColor" rounded elevation="0" class="mx-2 my-1" @click="processingSendTicket">Enviar Ticket</v-btn>
              <v-btn :disabled="!validEmailFormat" width="150" color="customColor" rounded elevation="0" class="mx-2 my-1" @click="processingSendTicketCode">Enviar código</v-btn>
              <v-btn width="150" outlined color="gray" rounded elevation="0" class="mx-2" @click="dialogSendTicket = false">Cerrar</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import './_TicketsEventView.scss'
import moment from 'moment/moment'
import Papa from 'papaparse'

export default {
  name: 'TicketsEventView',
  data () {
    return {
      dialogSendTicket: false,
      sendMasiveTicketData: {
        ticket: false,
        event: false,
        email: false
      },
      sendTicketData: {
        email: false,
        to: false,
        ticket: false
      },
      errors: {
        email: ''
      },
      hash: false,
      event: false,
      copySnackbar: false,
      saveSnackbar: false,
      ticketSnackbar: false,
      ticketMasivoSnackbar: false,
      search: '',
      limit: 5,
      loading: false,
      msgTicketsSent: '',
      emailListAux: {},
      validEmailFormat: false,
      tickets: {
        expanded: [],
        data: [],
        headers: [
          { text: '', value: 'primaryImage' },
          { text: 'Nombre', value: 'name' },
          { text: 'Fecha de Venta', value: 'start' },
          { text: 'Precio', value: 'price' },
          { text: 'Cantidad', value: 'quantity' },
          { text: 'Estado', value: 'state' },
          { text: '', value: 'actions' }
        ]
      }
    }
  },
  mounted () {
    this.hash = this.$route.params.hash
    this.getTickets()
  },
  watch: {

  },
  methods: {
    checkMailFormat (email) {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      const lowerCaseEmail = String(email).trim().toLowerCase()
      return regex.test(lowerCaseEmail) ? lowerCaseEmail : null
    },
    async getIdFromEmail (email) {
      const response = await this.$axios.get('/thirdparty/passpoint/' + email + '/iD')
      return response
    },
    searchEmail () {
      this.errors.email = ''
      this.validEmailFormat = this.checkMailFormat(this.sendTicketData.email)
      if (this.validEmailFormat) {
        this.getIdFromEmail(this.sendTicketData.email)
          .then((response) => {
            this.sendTicketData.to = response.data
            this.errors.email = ''
            this.getCountTickets()
            this.$forceUpdate()
          })
          .catch((error) => {
            if (error.response.status === 404) {
              this.errors.email = 'No existe PassPoint iD asociado'
              this.msgTicketsSent = ''
              this.sendTicketData.to = null
              this.$forceUpdate()
            }
          })
      } else {
        this.errors.email = 'Formato no válido'
        this.msgTicketsSent = ''
        this.sendTicketData.to = null
      }
    },
    sendTicket (event, ticket) {
      this.sendTicketData = {
        event: event,
        ticket: ticket
      }
      this.dialogSendTicket = true
      this.msgTicketsSent = ''
    },
    getCountTickets () {
      this.$axios.post('/thirdparty/passpoint/tickets/' + this.sendTicketData.event + '/' + this.sendTicketData.ticket + '/usercount', {
        iD: this.$store.state.iD,
        userId: this.sendTicketData.to,
        session: this.$store.state.session
      }).then(response => {
        this.msgTicketsSent = 'El ciente tiene ' + response.data + ' tickets para este evento'
      })
    },
    downloadReport (report) {
      const blob = new Blob([report], { type: 'text/plain' })

      const downloadLink = document.createElement('a')
      downloadLink.href = URL.createObjectURL(blob)
      downloadLink.download = 'reporteEnvio.csv'

      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      URL.revokeObjectURL(downloadLink.href)
    },
    massive (event, ticket) {
      this.sendMasiveTicketData.ticket = ticket
      this.sendMasiveTicketData.event = event

      this.$refs.inputMasive.$refs.input.click()
    },
    async processEmailList (emailList) {
      let report = 'nombre, correo, status\n'
      this.sendTicketData.ticket = this.sendMasiveTicketData.ticket
      this.sendTicketData.event = this.sendMasiveTicketData.event

      for (const i in emailList) {
        const reg = emailList[i]
        if (reg.status === 'not_exist') {
          this.sendTicketData.email = reg.checkedEmail
          this.processingSendTicketCode()
          report += reg.name + ',' + reg.checkedEmail + ',' + 'Envio_Codigo\n'
        } else if (reg.status === 'valid') {
          this.sendTicketData.to = reg.id
          this.processingSendTicket()
          report += reg.name + ',' + reg.checkedEmail + ',' + 'Envio_Directo\n'
        } else if (reg.status === 'repeated') {
          report += reg.name + ',' + reg.email + ',' + 'Repetido\n'
        } else {
          report += reg.name + ',' + reg.email + ',' + 'No_valido\n'
        }
      }
      this.downloadReport(report)
    },
    emailExist (email) {
      if (this.emailListAux[email]) return true
      this.emailListAux[email] = true
      return false
    },
    async processingMassiveSendTicket (file) {
      this.$store.commit('activeLoading')

      Papa.parse(file, {
        complete: async (response) => {
          const data = response.data
          data.shift()
          const emailList = []
          for (const item in data) {
            if (data[item][1] === undefined) continue
            // if (item.length < 5) return
            const reg = {
              checkedEmail: this.checkMailFormat(data[item][1]),
              email: data[item][1],
              name: data[item][0],
              status: false,
              id: false
            }

            try {
              console.log('::', reg.checkedEmail)
              if (this.emailExist(reg.checkedEmail)) {
                reg.status = 'repeated'
              } else if (reg.checkedEmail) {
                const iD = await this.getIdFromEmail(reg.checkedEmail)
                reg.id = iD.data
                reg.status = 'valid'
              } else {
                reg.status = 'invalid'
              }
            } catch (error) {
              reg.status = 'not_exist'
            }
            emailList.push(reg)
          }

          this.processEmailList(emailList)
            .then(() => {
              this.$store.commit('closeLoading')
            })
            .catch(error => {
              console.error('Error al procesar el objeto:', error)
            })
          this.$store.commit('closeLoading')
        }
      })
    },
    processingSendTicket () {
      this.$store.commit('activeLoading')
      this.$axios.post('/thirdparty/passpoint/tickets/' + this.sendTicketData.event + '/' + this.sendTicketData.ticket + '/direct', {
        iD: this.$store.state.iD,
        to: this.sendTicketData.to,
        session: this.$store.state.session
      }).then(response => {
        this.ticketSnackbar = true
        this.$store.commit('closeLoading')
      })
      this.dialogSendTicket = false
    },
    processingSendTicketCode () {
      this.$store.commit('activeLoading')
      this.$axios.post('/thirdparty/passpoint/tickets/' + this.sendTicketData.event + '/' + this.sendTicketData.ticket + '/redeem/create', {
        iD: this.$store.state.iD,
        email: this.sendTicketData.email,
        session: this.$store.state.session
      }).then(response => {
        this.ticketSnackbar = true
        this.$store.commit('closeLoading')
      })
      this.dialogSendTicket = false
    },
    copy (iD) {
      this.copySnackbar = true
      navigator.clipboard.writeText(iD)
    },
    clearSearch () {
      this.search = ''
    },
    async searchName () {
      this.loading = true
    },
    getStringDateFormat (date) {
      if (date === undefined) {
        return
      }
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    },
    getDiffDay (date) {
      const days = moment(date, 'YYYY-MM-DDTHH:mm').diff(moment(), 'days')
      if (days === 0) {
        return 'Expira Hoy'
      }
      if (days > 0) {
        return 'Expira en ' + days.toString() + ' días'
      }
      if (days < 0) {
        return 'Expiró hace ' + Math.abs(days).toString() + ' días'
      }
    },
    async getBalance (items) {
      if (items.length === 0) {
        return
      }
      const iD = items[0].iD
      console.log(iD)
    },
    getTickets () {
      this.$store.commit('activeLoading')
      this.$axios.get('/thirdparty/passpoint/events/' + this.hash, {
        params: {
          iD: this.$store.state.useriD,
          session: this.$store.state.session
        }
      }).then(response => {
        this.event = response.data
        this.tickets.data = response.data.tickets
        console.log(this.tickets)
        this.$store.commit('closeLoading')
      })
    }
  }
}
</script>
