<template>
  <div class="WelcomeView">
    <v-container fluid>
      <v-row>
        <v-col class="pa-0">
          <SliderBannerComponent :images="bannerImages" height="40vh" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_WelcomeView.scss'
import SliderBannerComponent from '@/components/SliderBannerComponent/SliderBannerComponent.vue'

export default {
  name: 'WelcomeView',
  components: { SliderBannerComponent },
  data () {
    return {
      bannerImages: [
        require('@/assets/banners/banner00.png')
      ]
    }
  }
}
</script>
