<template>
    <div class="BuyPointsView">
      <v-container fluid>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              Compra de Puntos
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">Compra de Puntos</div>
            </v-row>
          </v-col>
        </v-row>
        <v-col align-self="center" align="center">
          <v-stepper v-model="step" elevation="0" class="py-15" vertical>
            <v-stepper-header step="1" class="elevation-0">
              <div align="center" class="subtitleSection">
                Selecciona el Monto
              </div>
            </v-stepper-header>
            <v-stepper-content step="1" class="mx-0 px-0">
              <v-container>
                <v-row>
                  <v-col v-for="(amt, index) in amounts" :key="index" align-self="center" align="center" :cols="cols">
                    <v-btn @click="amount = amt" color="customColor" dark class="px-5 py-5 boxButton" elevation="0" block>
                      {{ amt }} <span class="pointsLabel">Puntos</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field class="boxField mt-10" outlined solo-inverted color="customColor" v-model="amount" suffix="Puntos" style="max-width: 200px" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn elevation="0" color="customColor" class="px-5 py-5" small @click="step++" :disabled="amount <= 0">Continuar</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-header step="2" class="elevation-0">
              <div align="center" class="subtitleSection">
                Monto a Pagar
              </div>
            </v-stepper-header>
            <v-stepper-content step="2" class="mx-0 px-0">
              <v-container>
                <v-row>
                  <v-col>
                    <strong>Monto a Pagar</strong>
                    <h1>$ {{ amount }} <span class="pointsLabel">{{ currency }}</span></h1>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align-self="center" align="center" :cols="cols" v-for="(method, index) in methods.filter(item => enabledMethods.includes(item.id))" :key="index">
                    <v-btn @click="currentMethod = method.id" outlined color="customColor" exact-active-class="" dark :class="{ 'v-btn--active': currentMethod === method.id,  'px-5': true, 'py-5': true, methodButton: true}" x-small>
                      {{ method.name }} <v-icon large class="ml-2">{{ method.icon }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn elevation="0" color="black" outlined dark class="px-5 py-5 mx-2" small @click="step--">Volver</v-btn>
                    <v-btn elevation="0" color="customColor" dark class="px-5 py-5 mx-2" small @click="pay">Pagar</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3" class="mx-0 px-0">
              <v-container>
                <v-row>
                  <v-col>
                    <div class="my-5">
                    <strong>Procesando Pago ... </strong>
                    </div>
                    <v-btn elevation="0" color="customColor" dark class="px-5 py-5 mx-2" small @click="step = 1">Cancelar</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
          </v-stepper>
          <v-img :src="require('@/assets/logos/p4g_empowered.svg')" contain max-height="20px" />
        </v-col>
      </v-container>
    </div>
</template>

<script>
import './_BuyPointsView.scss'
import { kebabCase } from 'eslint-plugin-vue/lib/utils/casing'

export default {
  name: 'BuyPointsView',
  data () {
    return {
      step: 1,
      amount: 0,
      amounts: [1000, 5000, 10000, 50000, 100000],
      suggestedAmount: 0,
      enabledMethods: [],
      payment: false,
      currency: '',
      methods: [
        {
          id: 'cc',
          name: 'Tarjeta de Debito/Crédito',
          icon: 'mdi-credit-card',
          flow: 1
        },
        {
          id: 'tb',
          name: 'Transferencia Bancaria',
          icon: 'mdi-bank',
          flow: 22
        },
        {
          id: 'ef',
          name: 'Efectivo',
          icon: 'mdi-cash',
          flow: 3
        },
        {
          id: 'one',
          name: 'OnePay',
          icon: '$vuetify.icons.onepay',
          flow: 5
        },
        {
          id: 'mach',
          name: 'MACH',
          icon: '$vuetify.icons.mach',
          flow: 15
        },
        {
          id: 'check',
          name: 'Check',
          icon: '$vuetify.icons.check',
          flow: 25
        },
        {
          id: 'yape',
          name: 'Yape',
          icon: '$vuetify.icons.yape'
        },
        {
          id: 'plin',
          name: 'Plin',
          icon: '$vuetify.icons.plin'
        },
        {
          id: 'btc',
          name: 'Bitcoin',
          icon: 'mdi-bitcoin'
        },
        {
          id: 'eth',
          name: 'Ethereum',
          icon: 'mdi-ethereum'
        }
      ],
      currentMethod: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona un monto'
        },
        {
          title: 'Paso 2',
          message: 'Selecciona tu medio de pago'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta tus puntos'
        }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      console.log(this.$route.params)
      if (this.$route.params.suggestedAmount) {
        this.suggestedAmount = parseFloat(this.$route.params.suggestedAmount)
      }

      this.amount = (this.suggestedAmount >= 1000) ? this.suggestedAmount : 0

      if (this.$store.state.currentPayment) {
        const id = this.$store.state.currentPayment
        this.$store.state.currentPayment = false
        this.$store.commit('saveSession')
        this.step = 3
        this.check(id)
      }
    }, 500)

    if (this.$route.meta.country === 'CL') {
      this.amounts = [1000, 5000, 10000, 50000, 100000]
      this.currency = 'Pesos'
      this.enabledMethods = ['cc', 'one', 'tb', 'ef', 'mach', 'check']
    }
    if (this.$route.meta.country === 'PE') {
      this.amounts = [10, 50, 100, 500, 1000, 5000]
      this.currency = 'Soles'
      this.enabledMethods = ['cc', 'tb', 'ef', 'yape', 'plin', 'btc', 'eth']
    }
  },
  computed: {
    cols () {
      const width = document.documentElement.clientWidth
      const widthVuetify = this.$vuetify.breakpoint.width
      console.log(width)
      console.log(widthVuetify)
      if (width < 700 || widthVuetify < 700) {
        return 12
      }
      if (width < 960 || widthVuetify < 960) {
        return 6
      }
      if (width < 1280 || widthVuetify < 1280) {
        return 4
      }
      if (width < 1920 || widthVuetify < 1920) {
        return 3
      }
      return 2
    }
  },
  methods: {
    kebabCase,
    async check (id) {
      if (this.step !== 3) {
        return
      }
      console.log(id)
      const data = (await this.axios.get('/payments/services/flow/' + id + '/status')).data
      console.log(data)
      if (data.status === 'paid') {
        // Redirect success
        this.$store.commit('closeLoading')
        return this.$router.push({ name: 'SuccessBuyPoints', params: { country: 'chile' } })
      }
      if (data.status === 'pending') {
        setTimeout(() => {
          this.check(id)
        }, 1000)
        return
      }
      this.$store.commit('closeLoading')
      // Redirect to failed
      return this.$router.push({ name: 'FailedBuyPoints', params: { country: 'chile' } })
    },
    async pay () {
      this.$store.commit('activeLoading')
      this.step++
      // const type = this.$store.state.merchant.type
      try {
        this.payment = (await this.axios.post('/payments/services/flow/direct', {
          iD: this.$store.state.useriD,
          amount: this.amount,
          currency: 'CLP',
          country: 'CL',
          method: (this.methods.find(item => item.id === this.currentMethod)).flow,
          description: 'Compra de puntos PassPoint',
          refer: window.location.protocol + '//' + window.location.host + '/chile/buy-points'
        })).data
        if (this.step !== 3) {
          return
        }
        this.$store.state.currentPayment = this.payment.id
        this.$store.commit('saveSession')
        setTimeout(() => {
          window.location.href = this.payment.url
        }, 500)
        this.$store.commit('activeLoading')
        setTimeout(() => {
          this.check(this.payment.id)
        }, 1000)
      } catch (e) {
        console.log(e)
        this.failed = true
        this.$store.commit('closeLoading')
      }
    }
  }
}
</script>
