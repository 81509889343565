<template>
    <div class="MembershipsView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Negocio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Membresías</div>
           <!-- <v-btn elevation="0" class="my-5 text-capitalize" rounded x-large color="customColor" dark to="/c/memberships2/list">..</v-btn> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align="left">
            <v-btn v-if="typeAsString !== '101'" width="150" color="customColor" rounded elevation="0" class="mx-2" to="/c/memberships/invite">Crear</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <v-data-table
              v-if="cards.total >= 0"
              v-model="selected"
              :loading="loading"
              :items-per-page="limit"
              :headers="cards.headers"
              :no-data-text="'No hay resultados para mostrar'"
              :items="cards.data"
              item-key="uniqueKey"
              :options.sync="cards.options"
              @update:items-per-page="getUpdateLimit"
              @update:expanded="getBalance"
              @update:page="updatePage"
              :server-items-length="cards.total"
              :single-expand="true"
              :expanded.sync="cards.expanded"
              show-expand
              show-select
              return-object
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row no-gutters align="center" justify="space-between">
                    <!-- Category Combobox -->
                    <v-col cols="12" md="4" class="align-self-start">
                      <v-combobox
                        v-if="typeAsString !== '101'"
                        v-model="searchCategory"
                        :items="memberCategories"
                        label="Categorías"
                        dense
                        outlined
                        rounded
                        clearable
                        class="my-2"
                        @change="performLocalSearch"
                      ></v-combobox>
                    </v-col>

                    <!-- Search Field -->
                    <v-col cols="12" md="6" class="align-self-start">
                      <v-text-field
                        v-model="search"
                        label="Buscar"
                        single-line
                        hide-details
                        dense
                        clearable
                        outlined
                        rounded
                        prepend-inner-icon="mdi-magnify"
                        @input="debouncedSearch"
                        @click:clear="clearSearch"
                        class="my-2"
                      ></v-text-field>
                    </v-col>

                    <!-- Refresh Button -->
                    <v-col cols="12" md="2" class="d-flex justify-end align-self-start">
                      <v-btn icon @click="refreshData">
                        <v-icon>mdi-refresh</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-spacer />
                <v-btn v-if="false" :disabled="cards.total === cards.data.length" x-small color="customColor" rounded elevation="0" class="mx-2" @click="loadMore">Cargar Más</v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-10" style="background-color: #fafaff">
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-text-field label="iD" v-model="item.iD" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.iD)" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-if="item.client" label="Nombre" v-model="item.client.name" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field :readonly="typeAsString === '101'" v-if="item.client" label="Correo" v-model="item.client.email" outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-if="item.client" label="DNI" v-mask="'##.###.###-N'" v-model="item.client.dni" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field v-if="item.client" label="Cumpleaños" type="date" v-model="item.client.birthdate" outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field v-if="item.client" type="phone" v-mask="'+## # #### ####'" label="Teléfono" v-model="item.client.phone" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field label="Miembro N°" v-model="item.member" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field label="Saldo" v-model="cards.balance" readonly suffix="Puntos" outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row v-if="$store.state.merchant.type !== 101">
                      <v-col>
                          <v-select v-if="item.category" label="Categoría" clearable v-model="item.category.level" :items="$store.state.merchant.category.type" item-text="name" item-value="level" outlined rounded color="customColor" hide-details />
                      </v-col>
                      <v-col>
                        <v-text-field v-if="item.category && item.category.expire" type="datetime-local" label="Expiración de Categoría" v-model="item.category.expire" outlined rounded color="customColor" :messages="getDiffDay(item.category.expire)" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="save(item)">Guardar</v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                <strong>{{ item.name }}</strong>
              </template>
              <template v-slot:[`item.created`]="{ item }">
                {{ getDateFormat(item.created) }}
              </template>
              <template v-if="typeAsString !== '101'" v-slot:[`item.actions`]="{ item }">
                <v-btn icon :to="{ name: 'membershipSales', params: { iD: item.iD } }"><v-icon>mdi-sale</v-icon></v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="copySnackbar" top centered>
        Copiado
      </v-snackbar>
      <v-snackbar v-model="saveSnackbar" top centered>
        Guardado
      </v-snackbar>
      <v-snackbar v-model="notFoundSnackbar" top centered>
        Sin resultados
      </v-snackbar>
      <v-dialog v-model="category" v-if="category">
        <v-card>
          <v-card-title>
          Cambio de Categoria Masivo
          </v-card-title>
          <v-card-actions>
            <v-container>
              <v-row>
              <v-col></v-col>
              </v-row>
                <v-row>
                  <v-col>
                      <v-select label="Categoría" clearable v-model="category.level" :items="typeAsString" item-text="name" item-value="level" outlined rounded color="customColor" hide-details />
                  </v-col>
                  <v-col>
                    <v-text-field type="datetime-local" label="Expiración de Categoría" v-model="category.expire" outlined rounded color="customColor" :messages="getDiffDay(category.expire)" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="caption">
                  Se procesaran {{ selected.length }} membresías
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                  <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="processCategory">Guardar</v-btn>
                  <v-btn elevation="0" class="my-5 text-capitalize" rounded block large color="black" dark @click="category = false">Cerrar</v-btn>
                  </v-col>
                </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import './_MembershipsView.scss'
import debounce from 'lodash.debounce'
import { firestore } from '@/plugins/firebase'
import {
  collection,
  getDocs,
  query,
  where,
  limit,
  // getCountFromServer,
  startAfter,
  updateDoc,
  doc,
  orderBy
} from 'firebase/firestore'
import moment from 'moment/moment'
import { utils } from 'ethers'

export default {
  name: 'MembershipsView',
  data () {
    return {
      category: false,
      copySnackbar: false,
      saveSnackbar: false,
      notFoundSnackbar: false,
      searchField: 'nombre',
      searchOptions: ['nombre', 'miembro', 'correo'],
      limit: 5,
      selected: [],
      sortBy: ['client.name'], // Start sorting by 'name' column by default
      sortDesc: [false], // Start with ascending order by default
      loading: false,
      search: '',
      searchCategory: null,
      memberCategories: [],
      allData: [], // Added to hold all data from Firestore
      cards: {
        balance: '',
        expanded: [],
        data: [],
        last: null,
        total: 0,
        options: {},
        headers: [
          { text: 'Nombre', value: 'client.name', sortable: false },
          { text: 'Miembro', value: 'member', sortable: false },
          { text: 'Creado', value: 'created', sortable: false },
          { text: 'Correo', value: 'client.email', sortable: false },
          { text: '', value: 'actions' }
        ]
      },
      debouncedSearch: null // Initialize as null here
    }
  },
  created () {
    // Initialize the debouncedSearch method in the created lifecycle hook
    this.debouncedSearch = debounce(this.performLocalSearch, 500) // 500 ms delay
  },
  computed: {
    typeAsString () {
      // Always treat type as a string, even if it's numeric
      return this.$store.state.merchant.type.toString()
    }
  },
  async mounted () {
    await this.fetchAllData()
    await this.getCount()
    // this.getCards()
    this.memberCategories = await this.getMemberCategories()
  },
  watch: {
    searchCategory (newCategory, oldCategory) {
      if (newCategory !== oldCategory) {
        this.performLocalSearch() // Re-filter the data when the category changes
      }
    }
  },
  methods: {
    async refreshData () {
      await this.fetchAllData()
    },
    async fetchAllData () {
      this.loading = true
      // Fetch all data from Firestore and store it in allData
      const typeCheck = this.typeAsString === '101' ? 101 : this.typeAsString // Handles both '101' as string and 101 as number
      const ref = query(collection(firestore, 'Cards'), where('type', '==', typeCheck))
      const querySnapshot = await getDocs(ref)
      this.allData = querySnapshot.docs.map(doc => {
        const data = doc.data()
        return {
          key: doc.id,
          iD: data.iD,
          created: data.created,
          member: data.data.member,
          uniqueKey: `${data.iD}_${data.data.member}`,
          client: data.client ? data.client : { name: data.data.clientName, email: data.data.clientEmail },
          // eslint-disable-next-line multiline-ternary
          category: data.data.category ? {
            name: data.data.category.name,
            level: data.data.category.level,
            expire: data.data.category.expire ? moment.unix(data.data.category.expire).format('YYYY-MM-DDTHH:mm') : null
          } : {
            // Default values when category is not present
            name: 'PassPoint',
            level: 0,
            expire: moment(new Date('3024-01-01')).format('YYYY-MM-DDTHH:mm')
          }
        }
      })
      // Call performLocalSearch to immediately reflect the data
      this.performLocalSearch()
    },
    handleSort ({ sortBy, sortDesc }) {
      // Only update the sort state if it's different from what we already have
      if (this.sortBy[0] !== sortBy || this.sortDesc[0] !== sortDesc) {
        // Set the current sort state
        this.sortBy = sortBy
        this.sortDesc = sortDesc

        // Call performLocalSearch to reapply filters and sorting
        this.performLocalSearch()
      }
    },
    performLocalSearch () {
      const searchLowerCase = (this.search || '').toLowerCase()
      let filteredData = this.allData

      // Filter by selected category if one is selected
      if (this.searchCategory) {
        filteredData = filteredData.filter(item => item.category && item.category.name === this.searchCategory)
      }

      // Perform search on filtered data
      filteredData = filteredData.filter(item => {
        const clientName = item.client?.name || 'Sin Nombre'
        const clientEmail = item.client?.email || 'Sin Email'
        const memberString = item.member?.toString() || 'Sin Numero'
        const matchName = clientName.toLowerCase().includes(searchLowerCase)
        const matchEmail = clientEmail.toLowerCase().includes(searchLowerCase)
        const matchMember = memberString.includes(searchLowerCase)
        const matchCreated = moment.unix(item.created).format('YYYY/MM/DD HH:mm').includes(searchLowerCase)

        return matchName || matchEmail || matchMember || matchCreated
      })

      // Reset to the first page on every new search
      this.cards.options.page = 1

      // Sort, paginate, and set data
      this.updateSortedAndPaginatedData(filteredData)
    },

    updateSortedAndPaginatedData (filteredData) {
      // Apply sorting if a column has been selected for sorting
      if (this.sortBy.length > 0 && this.sortBy[0]) {
        const sortProperty = this.sortBy[0]
        const sortOrder = this.sortDesc[0] ? -1 : 1
        filteredData.sort((a, b) => {
          let aValue = a
          let bValue = b
          sortProperty.split('.').forEach(prop => {
            aValue = aValue[prop]
            bValue = bValue[prop]
          })
          return (aValue < bValue ? -1 : aValue > bValue ? 1 : 0) * sortOrder
        })
      }

      // Update the total number of items based on the filtered data
      this.cards.total = filteredData.length

      // Now slice the filteredData to only include the items for the current page
      const startIndex = (this.cards.options.page - 1) * this.limit
      const endIndex = startIndex + this.limit
      this.cards.data = filteredData.slice(startIndex, endIndex)

      this.loading = false
    },
    save (item) {
      const category = this.$store.state.merchant.category.type.find(cat => cat.level === item.category.level)
      const data = {
        updated: moment().unix(),
        client: item.client,
        'data.clientName': item.client.name,
        'data.clientEmail': item.client.email,
        'data.category': { level: category ? category.level : 0, name: category ? category.name : '', expire: moment(item.category.expire, 'YYYY-MM-DDTHH:mm').unix() }
      }
      const docRef = doc(firestore, 'Cards', item.key)
      updateDoc(docRef, data).then(() => {
        this.saveSnackbar = true
      })
    },
    copy (iD) {
      this.copySnackbar = true
      navigator.clipboard.writeText(iD)
    },
    async getCount () {
      try {
        const typeCheck = this.typeAsString === '101' ? 101 : this.typeAsString
        const ref = query(collection(firestore, 'Cards'), where('type', '==', typeCheck))
        const querySnapshot = await getDocs(ref)
        this.cards.total = querySnapshot.size // Use size to get the count of documents
      } catch (error) {
        console.error('Error getting document count:', error)
        // Handle your error here, perhaps by setting an error state or message
      }
    },
    async getMemberCategories () {
      const typeCheck = this.typeAsString === '101' ? 101 : this.typeAsString // Handles both '101' as string and 101 as number
      const ref = query(collection(firestore, 'Cards'), where('type', '==', typeCheck))
      const docs = await getDocs(ref)
      const categories = new Set()

      docs.forEach(doc => {
        const data = doc.data()
        // Check for existence of data.data.category and data.data.category.name
        if (data.data && data.data.category && data.data.category.name) {
          categories.add(data.data.category.name)
        }
      })

      return Array.from(categories)
    },
    async getBalance (items) {
      this.cards.balance = ''
      if (items.length === 0) {
        return
      }
      const iD = items[0].iD
      const contracts = this.$store.state.contracts
      const type = this.$store.state.merchant.type
      const contract = contracts.find(item => item.type.toString() === type.toString())

      if (!contract) {
        this.cards.balance = '0'
      }
      this.$axios.get('/network/addresses/' + iD + '/balance/' + contract.iD).then(response => {
        const data = response.data
        this.cards.balance = utils.formatUnits(data, contract.decimals)
      })
    },
    getUpdateLimit (limit) {
      this.limit = limit
      if (limit === -1) {
        this.limit = 1000
      }
      this.cards.data = []
      this.getCards()
    },
    async clearSearch () {
      this.search = ''
      this.performLocalSearch() // This should filter and sort with empty search, showing all results.
    },
    searchName () {
      this.debouncedSearch()
    },
    determineSearchField (input) {
      if (!isNaN(input)) {
        return 'miembro'
      } else if (input.includes('@')) {
        return 'correo'
      } else {
        return 'nombre'
      }
    },

    formatSearchValue (input, field) {
      if (field === 'nombre') {
        return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase()
      }
      return input
    },
    async updatePage (page) {
      if (this.search.length > 0) {
        return
      }
      this.loading = true
      if (this.cards.data.length < page * this.limit) {
        const type = this.$store.state.merchant.type
        const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), orderBy('created', 'desc'), startAfter(this.cards.last), limit(this.limit))
        const querySnapshot = await getDocs(ref)
        this.cards.last = querySnapshot.docs[querySnapshot.docs.length - 1]
        querySnapshot.forEach(doc => {
          const data = doc.data()
          this.cards.data.push({
            key: doc.id,
            iD: data.iD,
            created: data.created,
            member: data.data.member,
            client: data.client ? data.client : { name: data.data.clientName, email: data.data.clientEmail },
            // eslint-disable-next-line multiline-ternary
            category: data.data.category ? {
              name: data.data.category.name,
              level: data.data.category.level,
              expire: data.data.category.expire ? moment.unix(data.data.category.expire).format('YYYY-MM-DDTHH:mm') : null
            } : {
              // Default values when category is not present
              name: 'PassPoint',
              level: 0,
              expire: moment(new Date('3024-01-01')).format('YYYY-MM-DDTHH:mm')
            }
          })
        })
      }
      this.loading = false
      // After fetching new data, apply the sorting again
      this.performLocalSearch()
    },
    async loadMore () {
      if (this.search.length > 0) {
        return
      }
      if (this.cards.data.length > this.cards.total) {
        return
      }
      this.loading = true
      const type = this.$store.state.merchant.type
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), orderBy('created', 'desc'), startAfter(this.cards.last), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      this.cards.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.cards.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          member: data.data.member,
          client: data.client ? data.client : { name: data.data.clientName, email: data.data.clientEmail },
          // eslint-disable-next-line multiline-ternary
          category: data.data.category ? {
            name: data.data.category.name,
            level: data.data.category.level,
            expire: data.data.category.expire ? moment.unix(data.data.category.expire).format('YYYY-MM-DDTHH:mm') : null
          } : {
            // Default values when category is not present
            name: 'PassPoint',
            level: 0,
            expire: moment(new Date('3024-01-01')).format('YYYY-MM-DDTHH:mm')
          }
        })
      })
      this.cards.options.page++
      this.loading = false
    },
    async getCards () {
      this.loading = true
      const type = this.$store.state.merchant.type
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type === 101 ? 101 : type.toString()), orderBy('created', 'desc'), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      console.log(querySnapshot.docs)
      this.cards.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.cards.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          member: data.data.member,
          client: data.client ? data.client : { name: data.data.clientName, email: data.data.clientEmail },
          // eslint-disable-next-line multiline-ternary
          category: data.data.category ? {
            name: data.data.category.name,
            level: data.data.category.level,
            expire: data.data.category.expire ? moment.unix(data.data.category.expire).format('YYYY-MM-DDTHH:mm') : null
          } : {
            // Default values when category is not present
            name: 'PassPoint',
            level: 0,
            expire: moment(new Date('3024-01-01')).format('YYYY-MM-DDTHH:mm')
          }
        })
      })
      this.loading = false
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    },
    getDiffDay (date) {
      const days = moment(date, 'YYYY-MM-DDTHH:mm').diff(moment(), 'days')
      if (days === 0) {
        return 'Expira Hoy'
      }
      if (days > 0) {
        return 'Expira en ' + days.toString() + ' días'
      }
      if (days < 0) {
        return 'Expiró hace ' + Math.abs(days).toString() + ' días'
      }
    }
  }
}
</script>
