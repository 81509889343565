<template>
    <div class="MembershipInviteView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Comercio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Enviar Membresía.</div>
          </v-col>
        </v-row>
        <v-row v-if="!send">
          <v-col class="mx-5 mx-sm-15">
            <div class="form ml-1">
              <v-text-field class="my-3" label="Nombre" v-model="name" outlined rounded hide-details />
              <v-text-field class="my-3" label="Correo" v-model="email" type="email" outlined rounded hide-details />
              <v-text-field class="my-3" label="DNI" v-model="dni" outlined rounded hide-details />
              <v-text-field class="my-3" label="Cumpleaños" v-model="birthdate" type="date" outlined rounded hide-details />
              <v-text-field class="my-3" label="Teléfono" v-model="phone" type="phone" outlined rounded hide-details />
              <div class="d-flex">
              <v-select class="mr-2" label="Categoría" clearable v-model="category.level" :items="$store.state.merchant.category.type" item-text="name" item-value="level" outlined rounded color="customColor" hide-details />
              <v-text-field class="ml-2" type="datetime-local" label="Expiración de Categoría" v-model="category.expire" outlined rounded color="customColor" :messages="getDiffDay(category.expire)" />
              </div>
              <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="invite">Enviar</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="send">
          <v-col align-self="center" align="center">
            <div v-if="failed">
              <v-icon size="128" color="red">mdi-stop-circle-outline</v-icon>
              <div class="display-1 text-uppercase my-3">
                No fue posible enviar la membresía
              </div>
              <v-btn elevation="0" class="my-5 text-capitalize" rounded x-large color="customColor" dark @click="back">Volver</v-btn>
            </div>
            <div v-if="success">
              <v-icon size="128" color="success">mdi-check-circle-outline</v-icon>
              <div class="display-1 text-uppercase my-3">
                Membresía enviada
              </div>
              <div class="scale-8">
                <div class="font-weight-bold">Código de Transacción</div>
                <div class="display-2">{{ success.job }}</div>
              </div>
              <v-btn elevation="0" class="my-5 text-capitalize" rounded x-large color="customColor" dark to="/c/memberships/list">Membresías</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_MembershipInviteView.scss'
import moment from 'moment'

export default {
  name: 'MembershipInviteView',
  data () {
    return {
      name: '',
      email: '',
      dni: '',
      birthdate: '',
      phone: '',
      category: {
        expire: moment().add(1, 'month').format('YYYY-MM-DDTHH:mm')
      },
      send: false,
      success: false,
      failed: false
    }
  },
  methods: {
    back () {
      this.failed = false
      this.send = false
    },
    getDiffDay (date) {
      const days = moment(date, 'YYYY-MM-DDTHH:mm').diff(moment(), 'days')
      if (days === 0) {
        return 'Expira Hoy'
      }
      if (days > 0) {
        return 'Expira en ' + days.toString() + ' días'
      }
      if (days < 0) {
        return 'Expiró hace ' + Math.abs(days).toString() + ' días'
      }
    },
    async invite () {
      this.$store.commit('activeLoading')
      this.send = true
      const type = this.$store.state.merchant.type
      const category = this.$store.state.merchant.category.type.find(cat => cat.level === this.category.level)
      try {
        this.success = (await this.axios.post('/thirdparty/passpoint/cards/type/' + type, {
          name: this.name,
          email: this.email,
          clientData: {
            phone: this.phone,
            birthdate: this.birthdate,
            dni: this.dni
          },
          iD: this.$store.state.iD,
          session: this.$store.state.session,
          category: category ? { name: category.name, level: category.level, expire: moment(this.category.expire, 'YYYY-MM-DDTHH:mm').unix() } : { expire: moment().unix() },
          merchant: {
            code: this.$store.state.merchant.code,
            type: this.$store.state.merchant.type.toString()
          }
        })).data
      } catch (e) {
        this.failed = true
      } finally {
        this.$store.commit('closeLoading')
      }
    }
  }
}
</script>
