<template>
    <div class="SetCountryView">
      <HeaderComponent v-show="header" class="fade-enter-active" />
        <router-view class="slide-in-bottom" style="animation-duration: 1.5s" />
      <SetCountryComponent></SetCountryComponent>
      <FooterComponent />
    </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent.vue'
import './_SetCountryView.scss'
import FooterComponent from '@/components/FooterComponent/FooterComponent.vue'
import SetCountryComponent from '@/components/SetCountryComponent/SetCountryComponent.vue'

export default {
  name: 'SetCountryView',
  components: { FooterComponent, HeaderComponent, SetCountryComponent },
  data () {
    return {
      header: false
    }
  },
  mounted () {
    this.$store.commit('loadData')
    setTimeout(() => { this.header = true }, 1500)
  }
}
</script>
