<template class="fondo">
  <div class="SetCountryComponent fondo">
    <v-row class="d-flex fluid titulo">
      <v-col :cols="4" class="d-flex fluid titulo">
        <div class="d-flex titulo">
          <v-img :src="require('@/assets/logos/logo.dark.svg')"  class="titulo" width="100%">
          </v-img>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex fluid fondo">
      <v-col :cols="2" class="d-flex fluid fondo" align-self="center" align-content="center">
        <div v-for="(pais, index) in paises" :key="index">
          <a :href="pais[1]">
          <img height="40" :src="require('@/assets/flags/' + pais[0] + '.svg')"  class="flag1"/>
          </a>
          <img height="40" :src="require('@/assets/flags/' + pais[0] + '.svg')"  class="flag2 reflection"/>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import './_SetCountryComponent.scss'
export default {
  name: 'SetCountryComponent',
  data () {
    return {
      paises: [['CL', 'chile'], ['PE', 'peru'], ['AR', 'argentina']]
    }
  },
  props: {
    image: String,
    height: String,
    message: String
  }
}
</script>
