<template>
    <div class="BeneficiosComercioView">
      <v-container class="fill-height" fluid>
        <v-btn
            v-if="showScrollButton"
            fab
            bottom
            right
            fixed
            color="white"
            @click="scrollToTop"
          >
          <v-icon color="primary">
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              BENEFICIOS
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">Beneficios Comercio</div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Beneficios
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint tiene disponible multiples programas de fidelización, para que encuentres el que más se ajuste a tu negocio.
                PassPoint te ofrece una forma de difundir tu marca, sin caer en costos excesivos, y permitiendo aumentar tus ventas recompensando a tus clientes.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Puntos
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint fideliza a tus clientes por medio de puntos, lo que te permite anticipar la captación de capital al obtener recursos de los puntos, antes que de tus productos o servicios.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Modelos de Fidelización
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Los Puntos PassPoint pueden utilizados de diferentes maneras, tu cliente puede acumular puntos, o puede recibir bonificaciones al momento de comprar puntos, también es posible generar gift card y promociones basadas en puntos, o aún más, puedes generar un programa de membresía con categorías de beneficios para tus clientes.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Conoce a tu cliente
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint te ofrece todas las herramientas para que puedas ver que pasa con tu negocio en el mundo de la fidelización, accediendo a la información de tu cliente, como a tus ventas e ingresos.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
             </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_BeneficiosComercioView.scss'

export default {
  name: 'BeneficiosComercioView',
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
