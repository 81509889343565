<template>
  <div class="SuccessView">
    <v-container fluid>
      <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
        <v-col align-self="center" align="center">
          <div class="titleBig">TU COMPRA DE PUNTOS HA SIDO EXITOSA</div>
          <div class="titleBig">YA PUEDES USARLOS PARA REALIZAR TUS CANJES</div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<template>
  <div class="PasspointView">
    <v-container class="fill-height" fluid>
      <v-btn
        v-if="showScrollButton"
        fab
        bottom
        right
        fixed
        color="white"
        @click="scrollToTop"
      >
        <v-icon color="primary">
          mdi-arrow-up
        </v-icon>
      </v-btn>
      <v-row>
        <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
          <div class="titleSection py-15 title1">COMPRA DE PUNTOS EXITOSA
          </div>
          <v-row class="titleBig2 py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleBig2">Ya puedes canjear tus tickets</div>
          </v-row>
          <v-row>
            <div class="LegalTitle py-5">
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_SuccessView.scss'
import { kebabCase } from 'eslint-plugin-vue/lib/utils/casing'

export default {
  name: 'SuccessView',
  methods: { kebabCase },
  data () {
    return {
      step: 1,
      amount: 0,
      amounts: [1000, 5000, 10000, 50000, 100000],
      enabledMethods: [],
      currency: '',
      methods: [

      ],
      steps: [

      ]
    }
  },
  mounted () {
    console.log(this.$route)
    if (this.$route.meta.country === 'CL') {
      this.amounts = [1000, 5000, 10000, 50000, 100000]
      this.currency = 'Pesos'
      this.enabledMethods = ['cc', 'tb', 'ef', 'btc', 'eth']
    }
    if (this.$route.meta.country === 'PE') {
      this.amounts = [10, 50, 100, 500, 1000, 5000]
      this.currency = 'Soles'
      this.enabledMethods = ['cc', 'tb', 'ef', 'yape', 'plin', 'btc', 'eth']
    }
  },
  computed: {
    cols () {
      const width = document.documentElement.clientWidth
      const widthVuetify = this.$vuetify.breakpoint.width
      console.log(width)
      console.log(widthVuetify)
      if (width < 700 || widthVuetify < 700) {
        return 12
      }
      if (width < 960 || widthVuetify < 960) {
        return 6
      }
      if (width < 1280 || widthVuetify < 1280) {
        return 4
      }
      if (width < 1920 || widthVuetify < 1920) {
        return 3
      }
      return 2
    }
  }
}
</script>
