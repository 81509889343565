<template>
    <div class="PrivacyView">
      <v-container class="fill-height" fluid>
        <v-btn
            v-if="showScrollButton"
            fab
            bottom
            right
            fixed
            color="white"
            @click="scrollToTop"
          >
          <v-icon color="primary">
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              PassPoint Privacy Policy
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">PassPoint Privacy Policy</div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Last Updated: September 11, 2023
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Thank you for using PassPoint ("We," "Our," or "The App"). At PassPoint, we take the privacy of our users seriously. This Privacy Policy describes how we collect, use, share, and protect personal information and device data collected from users of our mobile application.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                1. Information We Collect
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                1.1. Personal Information:
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                We may collect personal information that you voluntarily provide, such as your name, email address, and other contact information when you register in the app or use our services.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                1.2. Device Data:
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                We automatically collect technical and usage data, such as IP address, device type, operating system, app version, language preference, and user activity within the app.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                2. Use of Information
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                We use the collected information for the following purposes:
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Providing and maintaining our services.
                Personalizing and improving the user experience.
                Sending service-related communications.
                Understanding how users interact with the app.
                Complying with applicable laws and regulations.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                3. Sharing Information with Third Parties
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                We do not sell, rent, or share personal information with third parties without your consent, except in the following situations:
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Service providers who assist us in delivering our services.
                Compliance with legal and regulatory requirements.
                In the event of a merger, acquisition, or sale of assets, where user information may be transferred as part of such a transaction.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                4. Information Security
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                We take reasonable measures to protect the personal information and device data of our users. However, no data transmission over the internet or online storage can be guaranteed as 100% secure.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                5. Changes to this Privacy Policy
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                We may update this Privacy Policy periodically to reflect changes in our privacy practices. The date of the last update will be indicated at the beginning of the policy.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                6. Contact
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                If you have questions or concerns about this Privacy Policy, you can contact us at [contact email].
                By using PassPoint, you agree to the terms and conditions described in this Privacy Policy. We recommend that you regularly review this policy to stay informed of any changes.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint<br />
                Valdivia, Chile<br />
                contact@passpoint.cl
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                September 11, 2023
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-0">
            <StepComponent title="¿Cómo comprar puntos?" :steps="steps" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_PrivacyView.scss'
import StepComponent from '@/components/StepComponent/StepComponent.vue'

export default {
  name: 'PrivacyView',
  components: { StepComponent },
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
