<template>
    <div class="NeroliPromoOneView">
      <v-container fluid>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              <v-img :src="require('@/assets/logos/merchants/neroli.svg')" max-width="200px" contain />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-img :src="require('@/assets/promos/neroli-promo02.png')" max-width="100%" contain />
          </v-col>
        </v-row>
        <div class="py-15 title1">
        </div>
        <div class="titleSection py-15 title1">
          Promoción
        </div>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="PromoName">Micro Needling + Vitamina C</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" offset="3">
            <div class="titleSection py-15 title1">
              Descripción
            </div>
            <v-row>
              <div class="FAQText">
                Microneedling y vitamina c, la combinación perfecta para una piel luminosa y uniforme. 🍊🍊🍊

                Es el complemento perfecto para la rutina habitual del cuidado de pieles apagadas o con manchas.

                #Nerolistudio21 #cosmetologiavaldivia
                #vitaminac
                #microneedling
              </div>
            </v-row>
            <div class="titleSection py-15 title1">
              Validez
            </div>
            <v-row>
              <div class="FAQText">
                Promoción Válida hasta el 31 de diciembre 2023.
              </div>
            </v-row>
            <v-row v-if="true" class="py-20" >
              <v-col align-self="center" :align="$vuetify.breakpoint.smAndUp ? 'right' : 'center'" cols="12" sm="6">
                <v-btn elevation="0" color="customColor" dark class="px-5 py-5" x-small to="">Canjear Promoción</v-btn>
              </v-col>
              <v-col  align-self="center" :align="$vuetify.breakpoint.smAndUp ? 'left' : 'center'" cols="12" sm="6">
                <v-btn elevation="0" color="customColor" outlined dark class="px-5 py-5" x-small to="">Compartir Promoción</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="true">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Promociones Destacadas
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.giftcards.filter(item => item.country === 'CL' && item.tag === 'neroli' && item.category === 'belleza')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-20">
              Como llegar
            </div>
            <MapComponent class="mb-16">
              <template v-slot:embed>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3064.6317234420967!2d-73.24701462341915!3d-39.81525195105849!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9615ee76fc46e6a9%3A0x7b4887023463313c!2sCaupolic%C3%A1n%20597%2C%205110541%20Valdivia%2C%20Los%20R%C3%ADos!5e0!3m2!1ses-419!2scl!4v1703648471722!5m2!1ses-419!2scl" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </template>
            </MapComponent>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-0">
            <StepComponent title="¿Cómo valido mi promoción?" :steps="steps" bgColor="#E9B746"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center" class="py-20">
            <div><v-icon :color="colorIconFoot" large>mdi-map-marker</v-icon></div>
            <div class="my-4 white--text text-uppercase font-weight-bold">Ubicación</div>
            <div class="my-4 white--text font-weight-light">Caupolicán 597, Valdivia, Chile</div>
          </v-col>
          <v-col align-self="center" align="center" class="py-20">
            <div><v-icon :color="colorIconFoot" large>mdi-phone</v-icon></div>
            <div class="my-4 white--text text-uppercase font-weight-bold">Teléfono</div>
            <div class="my-4 white--text font-weight-light">+56 9 8529 9685</div>
          </v-col>
          <v-col align-self="center" align="center" class="py-20">
            <div><v-icon :color="colorIconFoot" large>mdi-email</v-icon></div>
            <div class="my-4 white--text text-uppercase font-weight-bold">Email</div>
            <div class="my-4 white--text font-weight-light">info@nerolistudio21.cl</div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_NeroliPromoOneView.scss'
import StepComponent from '@/components/StepComponent/StepComponent.vue'
import MapComponent from '@/components/MapComponent/MapComponent.vue'
import GiftCardListComponent from '@/components/GiftCardListComponent/GiftCardListComponent.vue'

export default {
  name: 'NeroliPromoOneView',
  components: { MapComponent, StepComponent, GiftCardListComponent },
  data () {
    return {
      colorIconFoot: '#E9B746',
      colorMsgOn: '#ffffff',
      colorMsgOff: '#a5a5a5',
      acepta: false,
      customerBirthday: '',
      customerEmail: '',
      customerName: '',
      customerPhone: '',
      customerRut: '',
      steps: [
        {
          title: 'Paso 1',
          message: 'Abre la APP Passpoint y haz Click en Mis Canjes'
        },
        {
          title: 'Paso 2',
          message: 'Busca tu canje en la pestaña Promociones'
        },
        {
          title: 'Paso 3',
          message: 'Muestra el QR en el comercio asociado'
        }
      ],
      rules: {
        required: value => !!value || 'Campo obligatorio.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-Mail inválido.'
        },
        rut: value => {
          const pattern = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/
          console.log(pattern.test(value))
          return pattern.test(value) || 'Rut inválido. Ingresa puntos y guión (12.345.678-9)'
        },
        telefono: value => {

        }
      }
    }
  },
  computed: {
  },
  methods: {
    back () {
      this.failed = false
      this.send = false
    },
    async invite () {
      this.$store.commit('activeLoading')
      this.send = true
      // const type = this.$store.state.merchant.type
      const type = 103
      try {
        this.success = (await this.axios.post('/thirdparty/passpoint/cards/type/' + type, {
          name: this.customerName,
          email: this.customerEmail,
          clientData: {
            phone: this.customerPhone,
            birthdate: this.birthdate,
            dni: this.customerRut
          }
        })).data
      } catch (e) {
        this.failed = true
      } finally {
        this.$store.commit('closeLoading')
      }
    }
  }
}
</script>
