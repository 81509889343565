<template>
    <div class="CardBalanceComponent">
      <v-card class="pa-5 mb-5 cardBox">
        <v-container class="">
          <v-row>
            <v-col align-self="center" align="center">
              <v-container>
                <v-row>
                  <v-col align="left" align-self="center"><h2>Balance</h2></v-col>
                  <v-col align="right" align-self="center"><span class="balanceText">{{ balance | numFormat('0,000.[00]') }}</span> <span class="pointLabel">Puntos</span></v-col>
                </v-row>
                <v-row>
                  <v-col align="right" align-self="center">
                    <div align="left" class="info-box">
                      <div class="cation font-weight-light">
                        Nombre
                      </div>
                      <div>
                        <h3>{{ type.name }}</h3>
                      </div>
                    </div>
                  </v-col>
                  <v-col align="left" >
                    <div align="left" class="info-box">
                      <div class="font-weight-light">
                        Miembro desde:
                      </div>
                      <div>
                        <h3>{{ getDateFormat(card.created) }}</h3>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row v-if="card.data.category">
                  <v-col align="right" align-self="center">
                    <div align="left" class="info-box">
                      <div class="font-weight-light">
                        Categoría
                      </div>
                      <div>
                        <h3>{{ card.data.category.name }}</h3>
                      </div>
                    </div>
                  </v-col>
                  <v-col align="left" >
                    <div align="left" class="info-box">
                      <div class="font-weight-light">
                       Expira:
                      </div>
                      <div>
                        <h3>{{ getDateFormat(card.data.category.expire) }}</h3>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <div>
        <v-btn elevation="0" color="customColor" dark class="px-5 py-5 mx-2" disabled>
          Recargar
        </v-btn>
        <v-btn elevation="0" color="customColor" dark class="px-5 py-5 mx-2" disabled>
          Transferir
        </v-btn>
        <v-btn elevation="0" color="red" outlined dark class="px-5 py-5 mx-2" :disabled="true || type.id === 'passpoint'">
          Eliminar
        </v-btn>
      </div>
    </div>
</template>

<script>
import './_CardBalanceComponent.scss'
import { utils } from 'ethers'
import moment from 'moment'
export default {
  name: 'CardBalanceComponent',
  props: {
    card: Object,
    type: Object
  },
  data () {
    return {
      balance: false
    }
  },
  mounted () {
    this.getBalance()
  },
  methods: {
    getDateFormat (timestamp) {
      return moment.unix(timestamp).format('DD/MM/YYYY')
    },
    async getBalance () {
      const contracts = this.$store.state.contracts

      if (!this.$store.state.country) {
        this.$store.state.country = (await this.$axios.get('https://api.country.is')).data.country
      }

      const contract = contracts.find(item => item.country === this.$store.state.country && item.type.toString() === this.card.type.toString())

      if (!contract) {
        this.balance = '0'
        return
      }
      this.$axios.get('/network/addresses/' + this.card.iD + '/balance/' + contract.iD).then(response => {
        const data = response.data
        this.balance = utils.formatUnits(data, contract.decimals)
      })
    }
  }
}
</script>
