<template>
    <div class="PromotionsView">
      <v-container fluid>
        <v-row>
          <v-col class="pa-0">
            <BannerTextComponent :image="require('@/assets/banners/banner00.png')" height="40vh" />
          </v-col>
        </v-row>
        <v-row style="background-color: #fafaff;">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15 my-16">
              Belleza
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.shops.filter(item => item.country === 'CL' && item.category === 'belleza')" />
          </v-col>
        </v-row>
        <v-row style="background-color: #fafaff;">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15 my-16">
              Gourmet
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.shops.filter(item => item.country === 'CL' && item.category === 'gourmet')" />
          </v-col>
        </v-row>
        <v-row style="background-color: #fafaff;">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15 my-16">
              Panoramas
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.shops.filter(item => item.country === 'CL' && item.category === 'panoramas')" />
          </v-col>
        </v-row>
        <v-row style="background-color: #fafaff;">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15 my-16">
              Viajes
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.shops.filter(item => item.country === 'CL' && item.category === 'viajes')" />
          </v-col>
        </v-row>
        <v-row style="background-color: #fafaff;">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15 my-16">
              Deporte
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.shops.filter(item => item.country === 'CL' && item.category === 'deporte')" />
          </v-col>
        </v-row>
        <v-row style="background-color: #fafaff;">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15 my-16">
              Salud
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.shops.filter(item => item.country === 'CL' && item.category === 'salud')" />
          </v-col>
        </v-row>
        <v-row style="background-color: #fafaff;">
          <v-col align-self="center" align="center">
            <div align="center" class="titleSection py-15 my-16">
              Otros
            </div>
            <GiftCardListComponent class="px-md-15 px-5" :items="$store.state.shops.filter(item => item.country === 'CL' && item.category === 'otros')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-0">
            <SingleButtonParallaxComponent message="Compra tus puntos Passpoint y canjea tus Beneficios" button="Comprar Puntos" link="/" :background="require('@/assets/backgrounds/dominga.png')" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_PromotionsView.scss'
import BannerTextComponent from '@/components/BannerTextComponent/BannerTextComponent.vue'
import GiftCardListComponent from '@/components/GiftCardListComponent/GiftCardListComponent.vue'
import SingleButtonParallaxComponent from '@/components/SingleButtonParallaxComponent/SingleButtonParallaxComponent.vue'

export default {
  name: 'PromotionsView',
  components: { GiftCardListComponent, SingleButtonParallaxComponent, BannerTextComponent },
  data () {
    return {
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  }
}
</script>
