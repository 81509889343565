<template>
    <div class="PanelView">
      <v-app-bar-nav-icon class="hidden-sm-and-up" @click.stop="panelDrawer = !panelDrawer"></v-app-bar-nav-icon>
      <HeaderComponent />
      <diV>

<!----------------  Inicio Menu Movil    ------------>
<v-navigation-drawer
        v-model="panelDrawer"
        app
        dark
        color="#374162"
      >
        <v-list
          nav
          dense
        >
          <v-list-item-title class="py-5">
            <v-img :src="require('@/assets/logos/logo.dark.svg')" max-width="200px" width="auto" max-height="20px" contain/>
          </v-list-item-title>
          <v-list-item-group
            active-class="white--text text--accent-4"
            class="textDrawer"
          >
            <v-list-item-subtitle>
                Cliente
              </v-list-item-subtitle>
              <v-list-item to="/p/profile/edit">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="textDrawer">
                  Perfil
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/memberships/list">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-card-account-details-star-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="textDrawer">
                  Membresías
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/tickets/list">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-ticket</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="textDrawer">
                  Tickets
                </v-list-item-title>
              </v-list-item>
              <v-list-item-subtitle v-if="producer">
                Eventos
              </v-list-item-subtitle>
              <v-list-item to="/p/events/activation" v-if="!producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-account-reactivate-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Activar
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/events/create"  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-smart-card-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="textDrawer">
                  Crear
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/events/list"  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-party-popper</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="textDrawer">
                  Eventos
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/clients/list"  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="textDrawer">
                  Clientes
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/c/business/income" disabled  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-chart-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="textDrawer">
                  Ingresos
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/c/business/transactions" disabled  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-credit-card-settings-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="textDrawer">
                  Transacciones
                </v-list-item-title>
              </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
<!----------------  Inicio Menu principal  --------------->
        <v-navigation-drawer
          color="#f5f5fa"
          v-if="!$vuetify.breakpoint.smAndDown"
          permanent
          absolute
          clipped
          style="margin-top: 32px; height: calc(100% - 649px - 32px)"
        >
          <v-list
            nav
            class="px-0"
          >
            <v-list-item-group
            >
              <v-list-item-subtitle>
                Cliente
              </v-list-item-subtitle>
              <v-list-item to="/p/profile/edit">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-cog-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Perfil
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/memberships/list">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-card-account-details-star-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Membresías
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/tickets/list">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-ticket</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Tickets
                </v-list-item-title>
              </v-list-item>
              <v-list-item-subtitle v-if="producer">
                Eventos
              </v-list-item-subtitle>
              <v-list-item to="/p/events/activation" v-if="!producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-account-reactivate-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Activar
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/events/create"  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-smart-card-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Crear
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/events/list"  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-party-popper</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Eventos
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/p/clients/list"  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Clientes
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/c/business/income" disabled  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-chart-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Ingresos
                </v-list-item-title>
              </v-list-item>
              <v-list-item to="/c/business/transactions" disabled  v-if="producer">
                <v-list-item-icon class="mx-2">
                  <v-icon>mdi-credit-card-settings-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Transacciones
                </v-list-item-title>
              </v-list-item>
            </v-list-item-group>
            <div class="logout">
              <v-btn class="text-capitalize" color="#1e9dd9" x-large elevation="0" @click="$store.commit('closeSession')" dark plain>Salir</v-btn>
            </div>
          </v-list>
        </v-navigation-drawer>
        <router-view/>
      </diV>
      <FooterComponent />
    </div>
</template>

<script>
import FooterComponent from '@/components/FooterComponent/FooterComponent.vue'
import HeaderComponent from '@/components/HeaderComponent/HeaderComponent.vue'
import './_PanelView.scss'

export default {
  data () {
    return {
      producer: false,
      panelDrawer: false
    }
  },
  updated () {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.panelDrawer = false
    }
  },
  name: 'PanelView',
  mounted () {
    this.$axios.get('/thirdparty/passpoint/producers/' + this.$store.state.useriD, {
      params: {
        iD: this.$store.state.iD,
        session: this.$store.state.session
      }
    }).then((response) => {
      this.producer = response.data
      this.$store.commit('setProducer', { producer: this.producer })
    })
  },
  components: {
    HeaderComponent,
    FooterComponent
  }
}
</script>
