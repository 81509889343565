<template>
    <div class="CreateTicketEventView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Eventos</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Crear Ticket.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15">
            <div class="form ml-1">
              <v-form class="pa-3" ref="form">
              <v-text-field v-model="ticket.name" :rules="[rules.required]" class="my-3" outlined rounded label="Nombre del Ticket" />
              <!-- {{ ticket.color }} -->
              <!--v-text-field v-model="ticket.color" class="my-3" outlined rounded label="Color">
                <template v-slot:append>
                  <v-menu v-model="pickerMenu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                      <div :style="{ background: ticket.color, cursor: 'pointer', height: '20px', width: '20px'}" v-on="on" />
                    </template>
                    <v-card>
                      <v-card-text class="pa-0">
                        <v-color-picker v-model="ticket.color" flat mode="hexa" />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </template>
              </v-text-field-->
              <v-row>
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="ticket.mode" :items="['Publico', 'Privado']" :rules="[rules.required]" class="my-3 mx-1" outlined rounded label="Tipo de Ticket" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete v-model="ticket.state" :rules="[rules.required]" :items="['Activo', 'Inactivo', 'Cerrado', 'Agotados', 'Cortesías']" class="my-3 mx-1" outlined rounded label="Estado del Ticket" />
                </v-col>
              </v-row>
              <v-textarea v-model="ticket.description" :rules="[rules.required]" counter class="my-3" outlined rounded label="Descripción del Ticket" />
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field suffix="puntos" v-model="ticket.price" :rules="[rules.moreThanZero]" class="my-3 mx-1" :messages="'Recibirás $' + ticket.price * 0.9" outlined rounded label="Valor del Ticket" />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field suffix="tickets" v-model="ticket.quantity" :rules="[rules.moreThanZero]" class="my-3 mx-1" outlined rounded label="Cantidad de Tickets" />
                </v-col>
              </v-row>
              <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="ticket.startDate" :rules="[rules.fechaHora]" type="date" class="my-3 mx-1" outlined rounded label="Fecha Inicio de Venta" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="ticket.startTime" :rules="[rules.fechaHora]" type="time" class="my-3 mx-1" outlined rounded label="Hora Inicio de Venta" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="ticket.endDate" :rules="[rules.fechaHora]" type="date" class="my-3 mx-1" outlined rounded label="Fecha Termino de Venta" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="ticket.endTime" :rules="[rules.fechaHora]" type="time" class="my-3 mx-1" outlined rounded label="Hora Termino de Venta" />
                  </v-col>
              </v-row>
              <v-file-input accept="image/*" v-model="primaryImage" class="my-3" outlined rounded label="Imagen del Ticket" messages="Formato soportado de 1064x650 pixeles" @change="(e) => previewImage(e, 'primaryImage')"/>
              <div align="center">
                <v-img :src="ticket.primaryImage" contain width="80%" max-width="500px" class="cardLayout"></v-img>
              </div>
              <v-alert v-if="!validForm" :value="true" type="error">
                  Debes llenar todos los campos solicitados
              </v-alert>
              <v-alert v-if="!validPrimaryImage" :value="true" type="error">
                Debes incluir una imágen para este ticket
              </v-alert>
              <v-alert v-if="startAfterEnd" :value="true" type="error">
                  La fecha de inicio debe ser anterior a la fecha de término de venta
              </v-alert>
              <v-btn elevation="0" class="my-3 text-capitalize" rounded block x-large color="customColor" dark @click="processRequest">Crear Ticket</v-btn>
            </v-form>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="editor" v-if="editPreview">
        <v-container fluid class="fill-height editor-background">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="editor-box">
                <PinturaEditor
                  v-bind="editorDefaults"
                  :src="editImage"
                  :imageCropAspectRatio="1.6369"
                  @pintura:process="finishPreview"
                ></PinturaEditor>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</template>

<script>
import './_CreateTicketEventView.scss'
import { createDefaultImageWriter, getEditorDefaults } from '@pqina/pintura'
import { PinturaEditor } from '@pqina/vue-pintura/vue-2'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { storage } from '@/plugins/firebase'
import moment from 'moment'
import auth from '@/mixins/auth'
export default {
  name: 'CreateTicketEventView',
  components: { PinturaEditor },
  data () {
    return {
      validForm: true,
      validPrimaryImage: true,
      startAfterEnd: false,
      pickerMenu: false,
      hash: false,
      primaryImage: null,
      editItem: false,
      editImage: false,
      editorDefaults: getEditorDefaults(),
      editPreview: false,
      ticket: {
        name: '',
        mode: '',
        color: '#0077b5',
        state: '',
        price: 0,
        quantity: 0,
        start: null,
        startDate: null,
        startTime: null,
        end: null,
        endDate: null,
        endTime: null,
        primaryImage: null
      },
      rules: {
        required: value => !!value || 'Campo obligatorio.',
        fechaHora: value => !!value || 'Presiona el icono para llenar este campo',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'e-Mail inválido.'
        },
        moreThanZero: value => {
          return (value > 0) || 'Debes ingresar un número mayor a cero'
        },
        rut: value => {
          const pattern = /^(\d{1,3}(?:\.\d{1,3}){2}-[\dkK])$/
          console.log(pattern.test(value))
          return pattern.test(value) || 'Rut inválido. Ingresa puntos y guión (12.345.678-9)'
        },
        telefono: value => {

        }
      }
    }
  },
  mounted () {
    this.hash = this.$route.params.hash
    this.editorDefaults.imageWriter = createDefaultImageWriter({
      targetSize: {
        width: 1064,
        height: 650,
        upscale: true
      }
    })
  },
  mixins: [auth],
  methods: {
    async finishPreview (result) {
      console.log(result)
      this.$store.commit('activeLoading')
      const storageRef = ref(storage, '/events/' + result.dest.name)
      uploadBytes(storageRef, result.dest).then(async (snapshot) => {
        this.ticket[this.editItem] = await getDownloadURL(snapshot.ref)
        this.$store.commit('closeLoading')
        this.editPreview = false
        this.editItem = false
        this.editPreview = false
      })
    },
    async previewImage (e, item) {
      console.log(e, item)
      const file = e
      if (file.size > 10 * 1024 * 1024) {
        // Error limit
        return
      }
      this.editImage = URL.createObjectURL(file)
      this.editItem = item
      this.editPreview = true
    },
    async advanceValidate () {
      this.validPrimaryImage = this.ticket.primaryImage !== null
      const inicio = moment(this.ticket.startDate + 'T' + this.ticket.startTime).unix()
      const fin = moment(this.ticket.endDate + 'T' + this.ticket.endTime).unix()

      if ((!isNaN(inicio)) && (!isNaN(fin))) {
        this.startAfterEnd = (inicio > fin)
      }
      return this.validPrimaryImage && !this.startAfterEnd
    },
    async processRequest () {
      this.$store.commit('activeLoading')
      if (!this.verifyAuth()) return

      this.validForm = await this.$refs.form.validate()
      this.validForm = await this.advanceValidate() && this.validForm
      if (!this.validForm) {
        this.$store.commit('closeLoading')
        return
      }

      this.ticket.start = this.ticket.startDate + 'T' + this.ticket.startTime
      this.ticket.end = this.ticket.endDate + 'T' + this.ticket.endTime

      this.$axios.post('/thirdparty/passpoint/events/' + this.hash + '/tickets', {
        iD: this.$store.state.iD,
        ticket: this.ticket,
        session: this.$store.state.session
      }).then(response => {
        console.log(response.data)
        this.$store.commit('closeLoading')
        this.$router.push({ name: 'panelListTicketsEvent', params: { hash: this.hash } })
      })
    }
  }
}
</script>
