<template>
    <div class="DeleteView">
      <v-container class="fill-height" fluid>
        <v-btn
            v-if="showScrollButton"
            fab
            bottom
            right
            fixed
            color="white"
            @click="scrollToTop"
          >
          <v-icon color="primary">
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              Delete App Data
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">Delete App Data</div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Last Updated: September 11, 2023
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                To delete your data from our platform, please follow these steps:
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Open the app store on your device.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Search for our app and identify it.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Select the uninstall or delete option.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Confirm the action when prompted.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Once you have deleted the app from your device, your data associated with the app will be deleted.
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                If you have any questions or need further assistance, please don't hesitate to contact us.
              </div>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="px-0">
            <StepComponent title="¿Cómo comprar puntos?" :steps="steps" />
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_DeleteView.scss'
import StepComponent from '@/components/StepComponent/StepComponent.vue'

export default {
  name: 'DeleteView',
  components: { StepComponent },
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
