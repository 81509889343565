<template>
    <div class="PopImageComponent">
      <v-img :aspect-ratio="square ? 1 : ''" :height="height" :src="image" class="zoom show" content-class="insideBanner">
        <div class="overlay"></div>
        <div class="message">
          {{ message }}
        </div>
      </v-img>
    </div>
</template>

<script>
import './_PopImageComponent.scss'
export default {
  name: 'PopImageComponent',
  props: {
    image: String,
    message: String,
    square: Boolean,
    mode: String
  },
  data () {
    return {
      height: 0
    }
  },
  mounted () {
    console.log(this.mode)
    if (this.mode !== 'auto') {
      this.height = this.mode + 'px'
      return
    }
    setTimeout(() => {
      this.setHeight()
    }, 1500)
  },
  methods: {
    setHeight () {
      this.height = this.$el.parentNode.parentNode.offsetHeight - 24
      console.log(this.height = this.$el.parentNode.parentNode.offsetHeight - 24)
    }
  }
}
</script>
