<template>
    <div class="DescriptionCardComponent">
      <v-card max-width="300" class="shadow" align="left">
        <v-card-text class="pa-10">
          <div>{{ number }}</div>
          <h2 class="font-weight-bold py-5">{{ title }}</h2>
          <div>
            {{ description }}
          </div>
          <router-link :to="to" class="text-decoration-none">
          <div class="d-flex justify-space-between mt-10">
            <div class="font-weight-bold text-uppercase black--text">seleccionar</div>
            <div><v-icon small>mdi-arrow-right</v-icon></div>
          </div>
          </router-link>
        </v-card-text>
      </v-card>
    </div>
</template>

<script>
import './_DescriptionCardComponent.scss'
export default {
  name: 'DescriptionCardComponent',
  props: {
    number: String,
    title: String,
    description: String,
    to: String
  }
}
</script>
