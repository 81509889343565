<template>
    <div class="MembershipSalesView SectionControl">
      <v-container class="fill-height pa-10">
        <v-row>
          <v-col>
            <div class="mx-5 mx-sm-15 subtitleControlSection">Comercio</div>
            <div class="mx-5 mx-sm-15 titleControlSection">Detalle de Canjes.</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="client">
            <h5 class="mx-5 mx-sm-15">Cliente</h5>
            <div class="mx-5 mx-sm-15 font-weight-bold">
              {{ client.name }}
            </div>
            <div class="mx-5 mx-sm-15 font-weight-light">
              {{ iD }}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mx-5 mx-sm-15" align-self="center" align="center">
            <v-data-table
              v-if="sales.total > 0"
              :loading="loading"
              :items-per-page="limit"
              :headers="sales.headers"
              :items="sales.data.filter(item => sales.filter ? item.type === sales.filter : item).concat(Array(sales.total - sales.data.length).fill({}))"
              item-key="key"
              :options.sync="sales.options"
              @update:items-per-page="getUpdateLimit"
              @update:expanded="getEmployee"
              @update:page="updatePage"
              disable-sort
              :single-expand="true"
              :expanded.sync="sales.expanded"
              show-expand
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-select hide-details style="max-width: 200px" :items="filters" item-value="value" item-text="name" label="Filtro por Tipo" v-model="sales.filter" clearable />
                  <v-spacer></v-spacer>
                  <v-text-field @keydown.enter.prevent="searchCode" hide-details v-model="search" dense label="Buscar Transacción" outlined rounded :append-icon="search.length > 0 ? 'mdi-close' : ''" @click:append="clearSearch" style="max-width: 400px" />
                  <v-btn color="customColor" rounded elevation="0" class="mx-2" @click="searchCode">Buscar</v-btn>
                </v-toolbar>
              </template>
              <template v-slot:[`footer.prepend`]>
                <v-spacer />
                <v-btn v-if="false" :disabled="sales.total === sales.data.length" x-small color="customColor" rounded elevation="0" class="mx-2" @click="loadMore">Cargar Más</v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-10" style="background-color: #fafaff">
                  <v-container fluid>
                    <v-row>
                      <v-col style="font-size: 200%" align-self="center" align="left">
                        <div v-if="item.type === 'buy_points'">
                          <v-icon large color="green">mdi-cash</v-icon> <span class="">Compra Puntos</span>
                        </div>
                        <div v-if="item.type === 'buy_service'">
                          <v-icon large color="customColor">mdi-account-credit-card</v-icon> <span class="">Canje</span>
                        </div>
                        <div v-if="item.type === 'buy_giftcard'">
                          <v-icon large color="orange">mdi-swap-horizontal</v-icon> <span class="">Canje de Puntos</span>
                        </div>
                        <div v-if="item.type === 'control_access'">
                          <v-icon large color="cyan">mdi-ticket-confirmation</v-icon> <span class="">Acceso</span>
                        </div>
                        <div v-if="item.type === 'sell_points'">
                          <v-icon large color="success">mdi-credit-card-plus-outline</v-icon> <span class="">Recarga</span>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field label="iD" v-model="item.iD" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.iD)" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-text-field label="Transacción" v-model="item.track" readonly outlined rounded color="customColor" hide-details append-icon="mdi-content-copy" @click:append="copy(item.track)" /></v-col>
                      <v-col>
                        <div v-if="item.state === 'completed'">
                          <v-text-field prepend-inner-icon="mdi-check-circle-outline" label="Estado" value="Completado" readonly outlined rounded color="success" hide-details />
                        </div>
                        <div v-if="item.state === 'paid'">
                          <v-text-field prepend-inner-icon="mdi-check-circle-outline" label="Estado" value="Pagado" readonly outlined rounded color="success" hide-details />
                        </div>
                        <div v-if="item.state === 'processing'">
                          <v-text-field prepend-inner-icon="mdi-dots-horizontal-circle-outline" label="Estado" value="En proceso" readonly outlined rounded color="cyan" hide-details />
                        </div>
                        <div v-if="item.state === 'created'">
                          <v-text-field prepend-inner-icon="mdi-circle-outline" label="Estado" value="Creado" readonly outlined rounded color="cyan" hide-details />
                        </div>
                        <div v-if="item.state === 'failed'">
                          <v-text-field prepend-inner-icon="mdi-stop-circle-outline" label="Estado" value="Fallido" readonly outlined rounded color="red" hide-details />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col><v-text-field label="Creado" :value="getDateFormat(item.created)" readonly outlined rounded color="customColor" hide-details /></v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-row v-if="item.type  === 'buy_service'">
                      <v-col><v-text-field label="Total" :value="parseFloat(item.amount) + parseFloat(item.tips)" suffix="Puntos" readonly outlined rounded color="customColor" hide-details /></v-col>
                      <v-col><v-text-field label="Monto" :value="item.amount" suffix="Puntos" readonly outlined rounded color="customColor" hide-details /></v-col>
                      <v-col><v-text-field label="Propina" :value="item.tips" suffix="Puntos" readonly outlined rounded color="customColor" hide-details /></v-col>
                    </v-row>
                    <v-row v-if="item.type  === 'sell_points'">
                      <v-col><v-text-field label="Monto" :value="item.amount" suffix="Puntos" readonly outlined rounded color="customColor" hide-details /></v-col>
                      <v-col></v-col>
                    </v-row>
                    <v-row v-if="item.employee">
                      <v-col>
                        <v-text-field label="iD Empleado" :value="item.employee" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                    <v-row v-if="item.employee">
                      <v-col>
                        <v-text-field label="Nombre Empleado" :value="sales.employee" readonly outlined rounded color="customColor" hide-details />
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
              <template v-slot:[`item.created`]="{ item }">
                {{ getDateFormat(item.created) }}
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                <div v-if="item.amount > 0">
                {{ item.amount | numFormat('0,000.[00]') }} <span class="caption">Puntos</span>
                </div>
                <div v-if="item.amount === 0">
                  -
                </div>
              </template>
              <template v-slot:[`item.type`]="{ item }">
                <div v-if="item.type === 'buy_points'">
                  <v-icon small color="green">mdi-cash</v-icon> <span class="caption">Compra Puntos</span>
                </div>
                <div v-if="item.type === 'buy_service'">
                  <v-icon small color="customColor">mdi-account-credit-card</v-icon> <span class="caption">Canje</span>
                </div>
                <div v-if="item.type === 'buy_giftcard'">
                  <v-icon small color="orange">mdi-swap-horizontal</v-icon> <span class="caption">Canje de Puntos</span>
                </div>
                <div v-if="item.type === 'control_access'">
                  <v-icon small color="cyan">mdi-ticket-confirmation</v-icon> <span class="caption">Acceso</span>
                </div>
                <div v-if="item.type === 'sell_points'">
                  <v-icon small color="success">mdi-credit-card-plus-outline</v-icon> <span class="caption">Recarga</span>
                </div>
              </template>
              <template v-slot:[`item.state`]="{ item }">
                <div v-if="item.state === 'completed'">
                    <v-icon small color="success">mdi-check-circle-outline</v-icon> <span class="caption">Completado</span>
                </div>
                <div v-if="item.state === 'paid'">
                  <v-icon small color="success">mdi-check-circle-outline</v-icon> <span class="caption">Pagado</span>
                </div>
                <div v-if="item.state === 'processing'">
                  <v-icon small color="cyan">mdi-dots-horizontal-circle-outline</v-icon> <span class="caption">En proceso</span>
                </div>
                <div v-if="item.state === 'created'">
                  <v-icon small color="cyan">mdi-circle-outline</v-icon> <span class="caption">Creado</span>
                </div>
                <div v-if="item.state === 'failed'">
                  <v-icon small color="red">mdi-stop-circle-outline</v-icon> <span class="caption">Fallido</span>
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar v-model="copySnackbar" top centered>
        Copiado
      </v-snackbar>
    </div>
</template>

<script>
import './_MembershipSalesView.scss'
import { collection, getCountFromServer, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore'
import { firestore } from '@/plugins/firebase'
import moment from 'moment'
export default {
  name: 'MembershipSalesView',
  data () {
    return {
      iD: false,
      copySnackbar: false,
      client: false,
      limit: 5,
      loading: false,
      search: '',
      filters: [
        { value: 'buy_points', name: 'Compra Puntos' },
        { value: 'sell_points', name: 'Recarga' },
        { value: 'control_access', name: 'Acceso' },
        { value: 'buy_giftcard', name: 'Canje de Puntos' },
        { value: 'buy_service', name: 'Canje' }
      ],
      sales: {
        filter: false,
        employee: '',
        expanded: [],
        data: [],
        last: null,
        total: 0,
        options: {},
        headers: [
          { text: 'Transacción', value: 'track' },
          { text: 'Creado', value: 'created' },
          { text: 'Tipo', value: 'type' },
          { text: 'Monto', value: 'amount' },
          { text: 'Estado', value: 'state' }
        ]
      }
    }
  },
  mounted () {
    this.iD = this.$route.params.iD
    this.getClient()
    this.getCount()
    this.getSales()
  },
  methods: {
    copy (iD) {
      this.copySnackbar = true
      navigator.clipboard.writeText(iD)
    },
    async getEmployee (items) {
      this.sales.employee = ''
      if (items.length === 0) {
        return
      }
      const iD = items[0].employee
      if (!iD) {
        return
      }
      console.log(iD)
      const type = this.$store.state.merchant.type
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type.toString()), where('owner', '==', iD), orderBy('created', 'desc'), limit(1))
      const querySnapshot = await getDocs(ref)
      if (querySnapshot.docs.length === 0) {
        return
      }
      this.sales.employee = querySnapshot.docs.pop().data().client
    },
    async searchCode () {
      this.loading = true
      this.sales.data = []
      const code = this.$store.state.merchant.code
      const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), where('iD', '==', this.iD), where('track', '>=', this.search), where('track', '<=', this.search + '\uf8ff'), orderBy('created', 'desc'), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.sales.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          amount: data.amount,
          tips: data.tips,
          type: data.type,
          state: data.state,
          track: data.track ? data.track : doc.id,
          employee: data.processor.employee ? data.processor.employee : false
        })
      })
      this.loading = false
    },
    clearSearch () {
      this.search = ''
      this.sales.data = []
      this.getSales()
    },
    getUpdateLimit (limit) {
      this.limit = limit
      if (limit === -1) {
        this.limit = 1000
      }
      this.sales.data = []
      this.getSales()
    },
    async updatePage (page) {
      console.log(this.limit)
      console.log(this.sales.options)
      if (this.search.length > 0) {
        return
      }
      this.loading = true
      if (this.sales.data.length < page * this.limit) {
        const code = this.$store.state.merchant.code
        const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), where('iD', '==', this.iD), orderBy('created', 'desc'), startAfter(this.sales.last), limit(this.limit))
        const querySnapshot = await getDocs(ref)
        this.sales.last = querySnapshot.docs[querySnapshot.docs.length - 1]
        querySnapshot.forEach(doc => {
          const data = doc.data()
          this.sales.data.push({
            key: doc.id,
            iD: data.iD,
            created: data.created,
            amount: data.amount,
            tips: data.tips,
            type: data.type,
            state: data.state,
            track: data.track ? data.track : doc.id,
            employee: data.processor.employee ? data.processor.employee : false
          })
        })
      }
      this.loading = false
    },
    async loadMore () {
      console.log(this.limit)
      console.log(this.sales.options)
      if (this.search.length > 0) {
        return
      }
      if (this.sales.data.length === this.sales.total) {
        return
      }
      this.loading = true
      const code = this.$store.state.merchant.code
      const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), where('iD', '==', this.iD), orderBy('created', 'desc'), startAfter(this.sales.last), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      this.sales.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.sales.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          amount: data.amount,
          tips: data.tips,
          type: data.type,
          state: data.state,
          track: data.track ? data.track : doc.id,
          employee: data.processor.employee ? data.processor.employee : false
        })
      })
      this.sales.options.page++
      this.loading = false
    },
    async getCount () {
      const code = this.$store.state.merchant.code
      console.log(code)
      const querySnapshot = await getCountFromServer(query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), where('iD', '==', this.iD)))
      this.sales.total = querySnapshot.data().count
    },
    async getClient () {
      const type = this.$store.state.merchant.type
      const ref = await query(collection(firestore, 'Cards'), where('type', '==', type.toString()), where('iD', '==', this.iD), limit(1))
      const querySnapshot = await getDocs(ref)
      this.client = querySnapshot.docs.pop().data().client
    },
    async getSales () {
      this.loading = true
      const code = this.$store.state.merchant.code
      const ref = await query(collection(firestore, 'RedeemActivity'), where('merchant', '==', code.toString()), where('iD', '==', this.iD), orderBy('created', 'desc'), limit(this.limit))
      const querySnapshot = await getDocs(ref)
      this.sales.last = querySnapshot.docs[querySnapshot.docs.length - 1]
      querySnapshot.forEach(doc => {
        const data = doc.data()
        this.sales.data.push({
          key: doc.id,
          iD: data.iD,
          created: data.created,
          amount: data.amount,
          tips: data.tips,
          type: data.type,
          state: data.state,
          track: data.track ? data.track : doc.id,
          employee: data.processor.employee ? data.processor.employee : false
        })
      })
      this.loading = false
    },
    getDateFormat (timestamp) {
      if (timestamp === undefined) {
        return
      }
      return moment.unix(timestamp).format('YYYY/MM/DD HH:mm')
    }
  }
}
</script>
