<template>
  <svg _ngcontent-erc-c2="" class="logo-chek" fill="none" viewBox="0 0 54 56" xmlns="http://www.w3.org/2000/svg">
    <g _ngcontent-erc-c2="" fill="currentColor">
      <path _ngcontent-erc-c2="" d="M21 36.7584L36.2169 55.6636H46.7688L27.2766 30.8571L21 36.7584Z" id="v1"></path>
      <path _ngcontent-erc-c2=""
            d="M53.3 0H40.7649L8.58442 30.8221V7.74026L0 15.1403V39.0442V50.8052V50.8156L0.00909095 50.7961L8.28052 42.9117L53.3 0Z"
            id="v2"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'checkIcon'
}
</script>
