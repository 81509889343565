<template>
    <div class="BeneficiosEmpresaView">
      <v-container class="fill-height" fluid>
        <v-btn
            v-if="showScrollButton"
            fab
            bottom
            right
            fixed
            color="white"
            @click="scrollToTop"
          >
          <v-icon color="primary">
            mdi-arrow-up
          </v-icon>
        </v-btn>
        <v-row>
          <v-col cols="6" offset="3"  :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
            <div class="titleSection py-15 title1">
              BENEFICIOS
            </div>
            <v-row class="titleBig py-5" align-self="center" :align="$vuetify.breakpoint.smAndDown ? 'center' : 'center'">
              <div class="titleBig">Beneficios Empresa</div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Beneficios
              </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Ponemos todos nuestros servicios a tu disposición, para que puedas difundir tu marca a una escala mucho mayor.
                ¿Integración con tus sistemas y aplicaciones? Por supuesto, tenemos toda la infraestructura para que puedas hacer la integración como tu desees, y utilizar toda nuestra tecnología para potenciar tu negocio.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Aplicación
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint ofrece la flexibilidad de colocar tu marca utilizando nuestra aplicación, dejando disponible al mundo un ecosistema pensado en tu empresa. Puedes hacer uso de los comercios o eventos de PassPoint, ya que te garantizamos la compatibilidad con la aplicación con tu marca.
              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Integración
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                Disponemos de toda la infraestructura para una integración a cualquier nivel, tanto como solo rebranding, o como integraciones directo a API consumiendo tecnología. Todo está al alcance de tu empresa, solo debes definir el alcance, y nosotros de ayudamos con lo demás.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
                Seguridad y Trasparencia
             </div>
            </v-row>
            <v-row>
              <div class="LegalText">
                PassPoint trabaja con la más alta tecnología y estándares de seguridad, minimizando la posibilidad de fraude y vulneración de negocio. Estamos respaldados por PassPoint, desarrollador de tecnología descentralizada y especialista en soluciones de alta seguridad.              </div>
            </v-row>
            <v-row>
              <div class="LegalTitle py-5">
             </div>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_BeneficiosEmpresaView.scss'

export default {
  name: 'BeneficiosEmpresaView',
  data () {
    return {
      showScrollButton: false,
      steps: [
        {
          title: 'Paso 1',
          message: 'Selecciona tu ticket en tu App'
        },
        {
          title: 'Paso 2',
          message: 'Escanea el punto de control'
        },
        {
          title: 'Paso 3',
          message: 'Disfruta el evento'
        }
      ]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  computed: {
  },
  methods: {
    handleScroll () {
      this.showScrollButton = window.scrollY > 0
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
